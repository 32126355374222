import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogOptions } from 'src/app/models/dialog-options.model';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css'],
})
export class AlertDialogComponent implements OnInit {
  constructor(
    private matDialogRef: MatDialogRef<AlertDialogComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions
  ) {}

  ngOnInit() {
    if (this.data.closeOnEscapeKey) {
      this.dialogService.CloseOnEscapeKey(this.matDialogRef);
    }
    this.dialogService.CloseOnBackdropClick(this.matDialogRef);
  }
}
