import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';

import { AddJobRequest } from 'src/app/models/add-job-request.model';
import { Glossary } from '../../models/glossary.model';
import { FileDetail } from 'src/app/models/file-detail.model';
import { FormFieldError } from 'src/app/models/form-field-error.model';

import { AppList } from 'src/app/core/enums/application-type.enum';
import { DataDogAction } from 'src/app/core/enums/datadog-action.enum';
import { FeatureType } from 'src/app/core/enums/feature-type.enum';
import { FileType } from 'src/app/core/enums/file-type.enum';
import { GlossaryStatus } from '../../core/enums/glossary-status.enum';
import { LanguageType } from 'src/app/core/enums/language-type.enum';

import { AppDataService } from 'src/app/services/app-data.service';
import { CommonService } from 'src/app/services/common.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataDogService } from 'src/app/services/data-dog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GlossaryService } from 'src/app/services/glossary.service';
import { NotificationService } from 'src/app/services/notification.service';

import { UploadGlossaryValidator } from './validators/upload-glossary-validator';

import { FileUploadComponent } from './file-upload/file-upload.component';

@Component({
  selector: 'app-start-new-comparison',
  templateUrl: './start-new-comparison.component.html',
  styleUrls: ['./start-new-comparison.component.css'],
})
export class StartNewComparisonComponent implements OnInit {
  @ViewChild('chineseFileUpload') chineseFileUpload!: FileUploadComponent;
  @ViewChild('glossaryFileUpload') glossaryFileUpload!: FileUploadComponent;

  public readonly appConstants: typeof AppConstants = AppConstants;
  public readonly dataDogAction: typeof DataDogAction = DataDogAction;
  public readonly glossaryFilter: FormControl = new FormControl();
  public readonly filteredGlossaryList: ReplaySubject<Glossary[]> = new ReplaySubject<Glossary[]>();

  public readonly jobNameControl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
  ]);
  public readonly jobTypeControl: FormControl = new FormControl(1);
  public readonly englishFileControl: FormControl = new FormControl(0, [Validators.min(1), Validators.max(1)]);
  public readonly chineseFileControl: FormControl = new FormControl(0, [Validators.min(1), Validators.max(1)]);
  public readonly glossaryFileControl: FormControl = new FormControl(0, [Validators.max(1)]);
  public readonly selectedGlossaryControl: FormControl = new FormControl('');
  public readonly startNewComparisonForm: FormGroup = this.formBuilder.group(
    {
      jobNameControl: this.jobNameControl,
      jobTypeControl: this.jobTypeControl,
      englishFileControl: this.englishFileControl,
      chineseFileControl: this.chineseFileControl,
      glossaryFileControl: this.glossaryFileControl,
      selectedGlossaryControl: this.selectedGlossaryControl,
    },
    { validators: [UploadGlossaryValidator(this.selectedGlossaryControl, this.glossaryFileControl, this.jobTypeControl)] }
  );

  private selectedEnglishFile!: File | null;
  private selectedChineseFile!: File | null;
  private selectedGlossaryFile!: File | null;

  public englishFileInfo: FileDetail = { name: '', type: FileType.pdf, languageType: LanguageType.English };
  public chineseFileInfo: FileDetail = { name: '', type: FileType.pdf, languageType: LanguageType.Chinese };
  public glossaryFileInfo: FileDetail = { name: '', type: FileType.xlsx, languageType: LanguageType.Unknown };

  public readonly languageType: typeof LanguageType = LanguageType;
  public readonly featureType: typeof FeatureType = FeatureType;
  public featureTypeId = FeatureType.Call_Figure;

  public isProgressBarShown = false;
  public isSubmitButtonDisabled = false;
  protected readonly onDestroy = new Subject<void>();

  public glossaryList!: Glossary[];
  public callFigureGlossaries!: Glossary[];
  public callRedactionGlossaries!: Glossary[];
  public glossaryConsistencyGlossaries!: Glossary[];
  public chineseCharactersConversionCheckGlossaries!: Glossary[];
  public reviewGlossaries!: Glossary[];

  public readonly jobNameError: FormFieldError[] = [
    { key: 'required', message: AppConstants.ErrorRequiredValue },
    { key: 'maxlength', message: AppConstants.ErrorRequiredMinCharJobName },
  ];

  constructor(
    private dashboardService: DashboardService,
    private dialogRef: MatDialogRef<StartNewComparisonComponent>,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    public appDataService: AppDataService,
    private formBuilder: FormBuilder,
    private glossaryService: GlossaryService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.dialogService.CloseOnEscapeKey(this.dialogRef);
    this.dialogService.CloseOnBackdropClick(this.dialogRef);
    if (!this.appDataService.user.isExternalUser) {
      this.glossaryService.getGlossary().subscribe({
        next: (data) => {
          const activeGlossaries: Glossary[] = data.filter((g) => g.status === GlossaryStatus.Active);
          this.callFigureGlossaries = activeGlossaries.filter((g) => g.callFigure);
          this.callRedactionGlossaries = activeGlossaries.filter((g) => g.callRedaction);
          this.glossaryConsistencyGlossaries = activeGlossaries.filter((g) => g.glossaryConsistencyCheck);
          this.chineseCharactersConversionCheckGlossaries = activeGlossaries.filter((g) => g.chineseCharactersConversionCheck);
          this.reviewGlossaries = activeGlossaries.filter((g) => g.review);

          this.glossaryList = this.callFigureGlossaries;

          this.filteredGlossaryList.next(this.glossaryList);
        },
        error: (err) => {
          if (err === AppConstants.ErrorSessionTimeout || err?.status === 401) {
            this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
          } else {
            this.notificationService.error('', AppConstants.ErrorFetchingGlossary);
          }
        },
      });
    }
  }

  ngAfterViewInit(): void {
    this.glossaryFilter.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterGlossaryList();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    DataDogService.removeDataDogContextProperties();
  }

  public onSubmit(): void {
    this.commonService.showSpinner.next(true);
    this.isSubmitButtonDisabled = true;
    this.isProgressBarShown = true;
    this.dialogRef.disableClose = true;
    this.featureTypeId = this.jobTypeControl.value;
    const isSingleFileJobType: boolean =
      this.featureTypeId === FeatureType.Chinese_Characters_Conversion_Check ||
      this.featureTypeId === FeatureType.Financial_Table_Check ||
      (this.featureTypeId === FeatureType.Square_Bracket_Check && !this.selectedChineseFile);

    const addJobRequest: AddJobRequest = {
      jobName: this.jobNameControl.value,
      files: isSingleFileJobType ? [this.englishFileInfo] : [this.englishFileInfo, this.chineseFileInfo],
      typeId: this.featureTypeId,
      glossaryId: 0,
      applicationIdentity: AppList.ACS,
    };
    if (this.selectedGlossaryControl.value !== '') {
      const selectedGlossary = this.glossaryList.filter((g) => g.glossaryId === this.selectedGlossaryControl.value)[0];
      addJobRequest.glossaryId = selectedGlossary.glossaryId;
    }
    const formData: FormData = new FormData();
    if (this.selectedGlossaryFile) {
      addJobRequest.files.push(this.glossaryFileInfo);
      formData.append('glossaryFile', this.selectedGlossaryFile, this.selectedGlossaryFile.name);
    }

    formData.append('data', JSON.stringify(addJobRequest));
    formData.append('englishFile', this.selectedEnglishFile!, this.selectedEnglishFile!.name);

    if (!isSingleFileJobType) {
      formData.append('chineseFile', this.selectedChineseFile!, this.selectedChineseFile!.name);
    }
    this.dashboardService.AddNewJob(formData).subscribe({
      next: () => {
        this.isProgressBarShown = false;
        this.showSuccessNotification();
        this.commonService.showSpinner.next(false);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.isProgressBarShown = false;
        if (err === AppConstants.ErrorSessionTimeout || err?.status === 401) {
          this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
        } else {
          this.showErrorNotification(err);
        }
        this.commonService.showSpinner.next(false);
        this.dialogRef.close(false);
      },
    });
  }

  private showSuccessNotification(): void {
    switch (this.featureTypeId) {
      case FeatureType.Call_Figure:
        this.notificationService.success(AppConstants.StartNewComparisonJobDialogTitle, AppConstants.StartNewComparisonSuccessMessage);
        break;
      case FeatureType.Review:
        this.notificationService.success(AppConstants.ReviewTitle, AppConstants.ReviewSuccessMessage);
        break;
      case FeatureType.Call_Redaction:
        this.notificationService.success(AppConstants.StartNewComparisonJobDialogTitle, AppConstants.CallRedactionSuccessMessage);
        break;
      case FeatureType.Chinese_Characters_Conversion_Check:
        this.notificationService.success(
          AppConstants.ChineseCharactersConversionCheck,
          AppConstants.ChineseCharactersConversionCheckSuccessMessage
        );
        break;
      case FeatureType.Financial_Table_Check:
        this.notificationService.success(AppConstants.FinancialTableCheckTitle, AppConstants.JobAddedSuccessMessage);
        break;
      case FeatureType.Square_Bracket_Check:
        this.notificationService.success(AppConstants.SquareBracketCheck, AppConstants.SquareBracketCheckSuccessMessage);
        break;
      default:
        this.notificationService.success(AppConstants.GlossaryConsistencyTitle, AppConstants.JobAddedSuccessMessage);
        break;
    }
  }

  private showErrorNotification(err: any): void {
    switch (this.featureTypeId) {
      case FeatureType.Call_Figure:
        this.notificationService.error(AppConstants.StartNewComparisonJobDialogTitle, err);
        break;
      case FeatureType.Review:
        this.notificationService.error(AppConstants.ReviewTitle, err);
        break;
      case FeatureType.Call_Redaction:
        this.notificationService.error(AppConstants.StartNewComparisonJobDialogTitle, AppConstants.CallRedactionFailureMessage);
        break;
      case FeatureType.Chinese_Characters_Conversion_Check:
        this.notificationService.error(AppConstants.ChineseCharactersConversionCheck, err);
        break;
      case FeatureType.Financial_Table_Check:
        this.notificationService.error(AppConstants.FinancialTableCheckTitle, err);
        break;
      case FeatureType.Square_Bracket_Check:
        this.notificationService.error(AppConstants.SquareBracketCheck, err);
        break;
      default:
        this.notificationService.error(AppConstants.GlossaryConsistencyTitle, err);
        break;
    }
  }

  public getDragAndDropLabel(): string {
    return this.jobTypeControl.value !== FeatureType.Chinese_Characters_Conversion_Check &&
      this.jobTypeControl.value !== FeatureType.Financial_Table_Check
      ? AppConstants.UploadEnglishFileLabel
      : AppConstants.UploadFileLabel;
  }

  public englishFileSelected(event: any): void {
    this.selectedEnglishFile = event;
    this.englishFileInfo.name = event.name;
    this.englishFileInfo.type = event.name.split('.').pop() as FileType;
  }

  public chineseFileSelected(event: any): void {
    this.selectedChineseFile = event;
    this.chineseFileInfo.name = event.name;
    this.chineseFileInfo.type = event.name.split('.').pop() as FileType;
  }

  public glossaryFileSelected(event: any): void {
    this.selectedGlossaryFile = event;
    this.glossaryFileInfo.name = event.name;
    this.glossaryFileInfo.type = event.name.split('.').pop() as FileType;
    this.selectedGlossaryControl.setValue('');
    this.selectedGlossaryControl.disable();
  }

  public englishFileRemoved(): void {
    this.selectedEnglishFile = null;
    this.englishFileInfo = { name: '', type: FileType.pdf, languageType: LanguageType.English };
  }

  public chineseFileRemoved(): void {
    this.selectedChineseFile = null;
    this.chineseFileInfo = { name: '', type: FileType.pdf, languageType: LanguageType.Chinese };
  }

  public glossaryFileRemoved(): void {
    this.selectedGlossaryFile = null;
    this.glossaryFileInfo = { name: '', type: FileType.xlsx, languageType: LanguageType.Unknown };
    this.selectedGlossaryControl.enable();
  }

  public onSelectedGlossaryChanged(): void {
    if (this.selectedGlossaryControl.value !== '') {
      this.glossaryFileUpload.removeFile();
      this.glossaryFileUpload.disabled = true;
      if (this.glossaryFileControl.hasError('min')) {
        this.glossaryFileControl.setErrors(null);
      }
    } else {
      this.glossaryFileUpload.disabled = false;
    }
  }

  public onSelectedJobTypeChanged(): void {
    this.selectedGlossaryControl.setValue('');
    if (this.glossaryFileControl.hasError('min')) {
      this.glossaryFileControl.setErrors(null);
    }
    this.glossaryFileControl.clearValidators();
    this.glossaryFileControl.setValidators(Validators.max(1));
    this.glossaryFileControl.updateValueAndValidity();

    switch (this.jobTypeControl.value) {
      case FeatureType.Call_Figure:
        this.glossaryList = this.callFigureGlossaries;
        this.chineseFileControl.enable();
        this.chineseFileUpload.disabled = false;
        this.glossaryFileControl.enable();
        this.glossaryFileUpload.disabled = false;
        break;
      case FeatureType.Call_Redaction:
        this.glossaryList = this.callRedactionGlossaries;
        this.chineseFileControl.enable();
        this.chineseFileUpload.disabled = false;
        this.glossaryFileControl.enable();
        this.glossaryFileUpload.disabled = false;
        break;
      case FeatureType.Chinese_Characters_Conversion_Check:
        this.glossaryList = this.chineseCharactersConversionCheckGlossaries;
        this.chineseFileControl.disable();
        this.chineseFileUpload.disabled = true;
        this.glossaryFileControl.disable();
        this.glossaryFileUpload.disabled = true;
        break;
      case FeatureType.Financial_Table_Check:
        this.chineseFileControl.disable();
        this.chineseFileUpload.disabled = true;
        this.glossaryFileControl.disable();
        this.glossaryFileUpload.disabled = true;
        this.selectedGlossaryControl.disable();
        break;
      case FeatureType.Review:
        this.glossaryList = this.reviewGlossaries;
        this.chineseFileControl.enable();
        this.chineseFileUpload.disabled = false;
        this.glossaryFileControl.enable();
        this.glossaryFileUpload.disabled = false;
        break;
      case FeatureType.Square_Bracket_Check:
        this.glossaryList = this.glossaryConsistencyGlossaries;
        this.chineseFileControl.enable();
        this.chineseFileControl.clearValidators();
        this.chineseFileControl.updateValueAndValidity();

        this.chineseFileUpload.disabled = false;
        this.chineseFileUpload.control.clearValidators();
        this.chineseFileUpload.control.updateValueAndValidity();

        this.glossaryFileControl.disable();
        this.glossaryFileUpload.disabled = true;
        this.selectedGlossaryControl.disable();

        break;
      default:
        this.glossaryList = this.glossaryConsistencyGlossaries;
        this.chineseFileControl.enable();
        this.chineseFileUpload.disabled = false;
        this.glossaryFileControl.enable();
        this.glossaryFileUpload.disabled = false;
        this.glossaryFileControl.addValidators(Validators.min(1));
        break;
    }
    this.filteredGlossaryList.next(this.glossaryList);
  }

  private filterGlossaryList(): void {
    if (!this.glossaryList) {
      return;
    }
    const search = this.glossaryFilter.value.toLowerCase();
    if (search.length < 3) {
      this.filteredGlossaryList.next(this.glossaryList.slice());
      return;
    }
    this.filteredGlossaryList.next(this.glossaryList.filter((r) => r.name.toLowerCase().indexOf(search) > -1));
  }

  public hasAny(control: AbstractControl): boolean {
    return control.value && control.value.length > 0;
  }

  public clearControl(control: AbstractControl, e: MouseEvent): void {
    control.setValue([]);
    e.stopPropagation();
  }
}
