import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

import { AppConstants } from 'src/app/app.constants';

import { FormFieldError } from 'src/app/models/form-field-error.model';
import { IAppSettings } from 'src/app/models/app-settings.model';
import { ValidUserDetailsResponse } from 'src/app/models/valid-user-details-response.model';

import { UserStatus } from 'src/app/core/enums/user-status-enum';

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private domainName: string = ((<any>window)[AppConstants.AppSettings] as IAppSettings).azure.ad.domain;

  public readonly appConstants: typeof AppConstants = AppConstants;

  public readonly userNameControl: FormControl = new FormControl('', Validators.required);
  public readonly rememberMeControl: FormControl = new FormControl(true);
  public readonly userLoginForm: FormGroup = this.formBuilder.group({
    userNameControl: this.userNameControl,
    rememberMeControl: this.rememberMeControl,
  });

  public isSubmitButtonDisabled = false;

  public readonly userNameError: FormFieldError[] = [
    { key: 'required', message: AppConstants.UserNameRequiredValue },
    { key: 'invalidEmail', message: AppConstants.UserNameInvalid },
  ];

  constructor(
    private authService: MsalService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  ngOnInit(): void {
    const emailId = localStorage.getItem(AppConstants.AcsUserEmailKeyName);
    if (emailId) {
      this.userNameControl.setValue(emailId);
    }
  }

  public onSubmit(): void {
    if (!this.userService.emailLoginNameValidator(this.userNameControl)) {
      this.userNameControl.setErrors({ invalidEmail: true });
      return;
    }
    this.isSubmitButtonDisabled = true;
    const userName = this.userNameControl.value;
    this.userService.IsValidUser(userName).subscribe({
      next: (res: ValidUserDetailsResponse) => {
        if (res.userStatus === UserStatus.Active) {
          localStorage.setItem(AppConstants.User2FAIsClientUser, res.isMfaEnabled.toString());
          localStorage.setItem(AppConstants.ReadAcceptClientUser,res.termsAcceptance.toString());
          sessionStorage.setItem(AppConstants.InactiveSession, "yes");
          if (this.rememberMeControl.value) {
            localStorage.setItem(AppConstants.AcsUserEmailKeyName, userName);
          } else {
            localStorage.removeItem(AppConstants.AcsUserEmailKeyName);
          }
          if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({
              ...this.msalGuardConfig.authRequest,
              loginHint: this.userNameControl.value.includes('@')
                ? this.userNameControl.value
                : `${this.userNameControl.value}@${this.domainName}`,
              // extraQueryParameters: { domain_hint: 'organizations' },
            } as RedirectRequest);
          } else {
            this.authService.loginRedirect();
          }
        } else {
          this.isSubmitButtonDisabled = false;
          this.userNameControl.setErrors({ invalidEmail: true });
          this.userNameControl.markAsTouched();
        }
      },
      error: () => {
        this.isSubmitButtonDisabled = false;
      },
    });
  }
}
