import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '../enums/job-status.enum';

@Pipe({
  name: 'jobStatus',
})
export class JobStatusPipe implements PipeTransform {
  transform(jobStatus: number): string {
    if (jobStatus !== null && jobStatus !== undefined) {
      return JobStatus[jobStatus].replace(/\_/gi, ' ');
    }
    return '';
  }
}
