import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import {
  AnnotationAddEventArgs,
  AnnotationDataFormat,
  AnnotationRemoveEventArgs,
  AnnotationSelectEventArgs,
  IActionElements,
  ITextMarkupAnnotation,
  LoadEventArgs,
  PdfFontModel,
  PdfViewerComponent,
} from '@syncfusion/ej2-angular-pdfviewer';
import { Button, ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { DropDownButtonComponent, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { ToolbarComponent } from '@syncfusion/ej2-angular-navigations';

import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

import { AppConstants } from 'src/app/app.constants';

import { AnnotationDetailsDto } from 'src/app/models/annotation-details-dto.model';
import { AnnotationDetailsResponse } from 'src/app/models/annotation-details-response.model';
import { CalloutFreetextBounds } from 'src/app/models/callout-freetext-bounds.model';
import { PdfPageSizeModel } from 'src/app/models/pdf-page-size-model';
import { SaveAnnotationDetailsRequest } from 'src/app/models/save-annotation-details-request.model';
import { SelectedAnnotationType } from 'src/app/models/selected-annotation-type.model';

import { AnnotationColor } from 'src/app/core/enums/annotation-color.enum';
import { LanguageType } from '../../../core/enums/language-type.enum';

import { PdfViewerService } from 'src/app/services/pdf-viewer.service';

import { AddEditErrorCategoryDialogComponent } from '../add-edit-error-category/add-edit-error-category-dialog.component';
import { ImportPagesComponent } from '../import-pages/import-pages.component';

@Component({
  selector: 'app-pdf-viewer-toolbar',
  templateUrl: './pdf-viewer-toolbar.component.html',
  styleUrls: ['./pdf-viewer-toolbar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PdfViewerToolbarComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges, AfterViewChecked {
  @ViewChild('toolbar') public readonly toolbar!: ToolbarComponent;
  @ViewChild('verticalToolbar') public readonly verticalToolbar!: ToolbarComponent;
  @ViewChild('clearAll') public clearButton!: ButtonComponent;
  @ViewChild('colorPicker') public colorPickerButton!: DropDownButtonComponent;
  @ViewChild('kendoDialogContainer') public kendoDialogContainer!: ViewContainerRef;

  @Input() pdfViewer!: PdfViewerComponent;
  @Input() documentLoaded: boolean = false;
  @Input() existingAnnotationCollection?: string;
  @Input() isMonolingualSearch: boolean = false;
  @Input() isFinancialCheck: boolean = false;
  @Input() isPageLocked: boolean = false;
  @Input() pdfFileName: string = '';

  private textStyles!: string[];
  private regionsAdded: number = 0;
  @Input()
  public get textStylesSelected() {
    return this.textStyles;
  }

  @Output() closeLeftPdf = new EventEmitter();
  @Output() closeRightPdf = new EventEmitter();
  @Output() lineAnnotation = new EventEmitter<boolean>();
  @Output() showLoader = new EventEmitter<any>();
  @Output() deleteFiniancialCheckAnnot = new EventEmitter();
  @Output() financialRegionUndo = new EventEmitter<any>();

  @Output() eRect = new EventEmitter<any>();

  public readonly constants: typeof AppConstants = AppConstants;

  public selectedAnnotation: SelectedAnnotationType = new SelectedAnnotationType();

  public annotationLqa: AnnotationDetailsDto[] = [];
  private oldAnnotationLqaData: AnnotationDetailsDto[] = [];
  private pageIdentity: number = 0;
  private pageSequence: number = 0;
  private fileIdentity: number = 0;
  private otherFileIdentity: number = 0;
  private jobIdentity!: string;
  public disableColorPicker: boolean = true;
  public selectedAnnotationColor!: any;
  public clickedAnnotation!: string;
  public disableCloseButton: boolean = false;
  public selectedReferencePageIdentity: number[] = [];
  private oldReferencedPages: number[] = [];
  public referencedPagesCount!: number;
  public referencedPagesDeleted: boolean = false;
  private pageSize!: PdfPageSizeModel;
  private wasButtonEnabled = false;
  public wasColorButtonDisabled: boolean = false;

  public set textStylesSelected(value) {
    this.textStyles = value;
    if (this.italicButton || this.boldButton || this.underlineButton || this.strikeoutButton) {
      this.turnOffButtonSelection(this.italicButton);
      this.turnOffButtonSelection(this.boldButton);
      this.turnOffButtonSelection(this.underlineButton);
      this.turnOffButtonSelection(this.strikeoutButton);
    }
    if (this.textStyles && this.textStyles.length > 0) {
      setTimeout(() => {
        this.textStyles.forEach((x) => this.setTextStyleSelected(x));
      }, 100);
    }
  }

  public pencilButton!: Button;
  public strikeoutButton!: Button;
  public boldButton!: Button;
  public underlineButton!: Button;
  public italicButton!: Button;
  public positiveRegionButton!: Button;
  public negativeRegionButton!: Button;
  private isInkAnnotationSelected: boolean = false;

  private destroy = new Subject<void>();

  constructor(private pdfViewerService: PdfViewerService, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.pdfViewerService.exportPDFCompleted.pipe(takeUntil(this.destroy)).subscribe((pdfViewer) => {
      if (pdfViewer == 'leftPdfViewer') {
        this.closeLeftPdf.emit();
      }
      if (pdfViewer == 'rightPdfViewer') {
        this.closeRightPdf.emit();
      }
    });

    this.pdfViewer.annotationAdd.subscribe((e: AnnotationAddEventArgs) => {
      if (e.annotationType === 'Ink') {
        this.isInkAnnotationSelected = true;
      }
      if (e.annotationType === 'Highlight' || e.annotationType === 'Underline' || e.annotationType === 'Strikethrough') {
        this.selectedAnnotationColor = e.annotationSettings.color;
      } else {
        this.selectedAnnotationColor = e.annotationSettings.strokeColor;
      }
      this.disableColorPicker = true;
      if (e.annotationType === 'Line') {
        if (e.annotationSettings.lineHeadStartStyle === 'Open') {
          // if it is callout we should take textbox id for both line and textbox
          const freeTextAnnotation = this.pdfViewer.annotationCollection.filter(
            (y) => y.shapeAnnotationType === 'FreeText' && y.customData && y.customData.dependsOn === e.annotationId
          )[0];
          if (freeTextAnnotation) {
            this.selectedAnnotation.annotationId = freeTextAnnotation.annotationId;
          }
          this.selectedAnnotation.annotationType = 'Callout';
        } else {
          this.selectedAnnotation.annotationId = e.annotationId;
          this.selectedAnnotation.annotationType = 'Straightline';
        }
      } else {
        this.selectedAnnotation.annotationId = e.annotationId;
        this.selectedAnnotation.annotationType = e.annotationType;
      }
      if (e.annotationType === 'FreeText') {
        this.pdfViewer.drawingObject.shapeAnnotationType = 'Path';
      } else if (e.annotationType === 'Rectangle') {
        const annotation = this.pdfViewer.annotationCollection.filter((x) => x.annotationId === e.annotationId)[0];
        if (this.pdfViewerService.isFinancialCheckRegion(annotation.customData?.type)) {
          this.pdfViewer.annotation.setAnnotationMode('Rectangle');
        } else {
          this.pdfViewer.annotation.setAnnotationMode('None');
        }
      } else if (e.annotationType !== 'Ink') {
        this.unsetAnnotationMode();
      }
    });

    this.pdfViewer.annotationSelect.subscribe((x: AnnotationSelectEventArgs) => {
      if (x.annotation.type === 'Ink') {
        this.isInkAnnotationSelected = true;
      }
      if (x.annotation.type === 'TextMarkup') {
        this.selectedAnnotationColor = x.annotation.color;
      } else {
        this.selectedAnnotationColor = x.annotation.strokeColor;
      }
      this.selectedAnnotation.annotationId = x.annotationId;
      if (x.annotation.type === 'TextMarkup' || x.annotation.type === 'Shape') {
        if ((x.annotation.subType === 'Line' || x.annotation.subType === 'Arrow') && x.annotation.lineHeadStartStyle === 'OpenArrow') {
          // if it is callout we should take textbox id for both line and textbox
          const freeTextAnnotation = this.pdfViewer.annotationCollection.filter(
            (y) => y.shapeAnnotationType === 'FreeText' && y.customData && y.customData.dependsOn === x.annotationId
          )[0];
          if (freeTextAnnotation) {
            this.selectedAnnotation.annotationId = freeTextAnnotation.annotationId;
            this.selectedAnnotation.annotationType = 'Callout';
          }
        } else {
          this.selectedAnnotation.annotationType = x.annotation.subType === 'Line' ? 'Straightline' : x.annotation.subType;
        }
      } else if (x.annotation.customData) {
        this.selectedAnnotation.annotationType = 'Callout';
      } else {
        this.selectedAnnotation.annotationType = x.annotation.type;
      }
    });

    this.pdfViewer.pageClick.subscribe(() => {
      this.disableColorPicker = true;
      if (this.isFinancialCheck) {
        this.removePositiveAndNegativeRegionFocus(false);
        const financialRegions = this.pdfViewer.annotationCollection.filter((x) => x.customData?.type);
        if (financialRegions.length > this.regionsAdded) {
          this.addActiveForFinancialRegion();
        }
        this.regionsAdded = financialRegions.length;
      }
    });

    this.pdfViewer.annotationRemove.subscribe((x: AnnotationRemoveEventArgs) => {
      const index = this.annotationLqa.findIndex((y) => y.annotationId === x.annotationId);
      if (index > -1) {
        this.annotationLqa.splice(index, 1);
      }
      if (this.isFinancialCheck) {
        this.removePositiveAndNegativeRegionFocus();
      }
    });

    this.pdfViewer.documentLoad.subscribe((x: LoadEventArgs) => {
      this.pageSize = JSON.parse(x.pageData).pageSizes[0];
      if (this.isFinancialCheck) {
        this.regionsAdded = this.pdfViewer.annotationCollection.length;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getAnnotationDetails();
    this.verticalToolbar?.disable(this.isPageLocked);
  }

  ngAfterViewChecked() {
    if (this.isFinancialCheck && this.clearButton) {
      const isClearButtonEnabled = !this.clearButton.element.disabled;
      const isColorButtonDisabled = this.colorPickerButton.element.disabled;
      if (
        (isClearButtonEnabled || isColorButtonDisabled) &&
        (!this.wasButtonEnabled || !this.wasColorButtonDisabled) &&
        this.pdfViewer.selectedItems.annotations &&
        this.pdfViewer.selectedItems.annotations.length === 0
      ) {
        this.clearButtonEnabled();
        if (isClearButtonEnabled) {
          this.wasButtonEnabled = true;
        }
        if (isColorButtonDisabled) {
          this.wasColorButtonDisabled = true;
        }
      } else if (!isClearButtonEnabled) {
        this.wasButtonEnabled = false;
      } else if (!isColorButtonDisabled) {
        this.wasColorButtonDisabled = false;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pdfFileName']) {
      this.removePositiveAndNegativeRegionFocus();
      this.getAnnotationDetails();
    }
    if (changes['isPageLocked']) {
      this.verticalToolbar?.disable(this.isPageLocked);
    }
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  public isAnnotationSelected(): boolean {
    if (this.pdfViewer.annotationModule?.textMarkupAnnotationModule.currentTextMarkupAnnotation) {
      return true;
    }
    if (this.pdfViewer.selectedItems.annotations) {
      return (
        this.pdfViewer.selectedItems.annotations.length > 0 &&
        this.pdfViewer.selectedItems.annotations &&
        !this.isFinancialRegionSelected(this.pdfViewer.selectedItems.annotations[0])
      );
    }
    return false;
  }

  public enableHighlight(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    if (this.pdfViewer.annotationModule.textMarkupAnnotationModule.highlightColor) {
      this.selectedAnnotationColor = this.pdfViewer.annotationModule.textMarkupAnnotationModule.highlightColor;
    } else {
      this.selectedAnnotationColor = this.pdfViewer.highlightSettings.color;
    }
    this.pdfViewer.annotation.setAnnotationMode('Highlight');
  }

  public enableUnderline(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    if (this.pdfViewer.annotationModule.textMarkupAnnotationModule.underlineColor) {
      this.selectedAnnotationColor = this.pdfViewer.annotationModule.textMarkupAnnotationModule.underlineColor;
    } else {
      this.selectedAnnotationColor = this.pdfViewer.underlineSettings.color;
    }
    this.pdfViewer.annotation.setAnnotationMode('Underline');
  }

  public enableCallout(): void {
    //setting true to disable color picker
    this.disablePencil(true);
    this.removePositiveAndNegativeRegionFocus();

    this.lineAnnotation.emit(false);
    this.pdfViewer.annotation.setAnnotationMode('Line');
  }

  public enableArrow(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    if (this.pdfViewer.annotationModule.shapeAnnotationModule.arrowStrokeColor) {
      this.pdfViewer.arrowSettings.strokeColor = this.pdfViewer.annotationModule.shapeAnnotationModule.arrowStrokeColor;
    }
    this.pdfViewer.annotation.setAnnotationMode('Arrow');
    this.selectedAnnotationColor = this.pdfViewer.annotationModule.shapeAnnotationModule.arrowStrokeColor;
  }

  public enableRectangle(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    this.pdfViewer.rectangleSettings.strokeColor = AnnotationColor['PdfViewer Red'];
    this.pdfViewer.rectangleSettings.thickness = 1;
    this.pdfViewer.rectangleSettings.fillColor = undefined;
    this.pdfViewer.rectangleSettings.customData = undefined;
    if (
      this.pdfViewer.annotationModule.shapeAnnotationModule.rectangleStrokeColor &&
      this.pdfViewer.annotationModule.shapeAnnotationModule.rectangleStrokeColor !== 'green' &&
      !this.pdfViewer.rectangleSettings.customData
    ) {
      this.pdfViewer.rectangleSettings.strokeColor = this.pdfViewer.annotationModule.shapeAnnotationModule.rectangleStrokeColor;
    }
    this.pdfViewer.annotation.setAnnotationMode('Rectangle');
    this.selectedAnnotationColor = this.pdfViewer.annotationModule.shapeAnnotationModule.rectangleStrokeColor;
  }

  public deleteFinancialCheckAnnot() {
    this.removePositiveAndNegativeRegionFocus();
    this.unsetAnnotationMode();
    const annotationIds = this.pdfViewer.annotationCollection.filter((x) => x.customData?.type).map((x) => x.annotationId);
    annotationIds.forEach((x) => {
      this.pdfViewer.annotation.deleteAnnotationById(x);
    });
    this.deleteFiniancialCheckAnnot.emit();
  }

  public enableCircle(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    if (this.pdfViewer.annotationModule.shapeAnnotationModule.circleStrokeColor) {
      this.pdfViewer.circleSettings.strokeColor = this.pdfViewer.annotationModule.shapeAnnotationModule.circleStrokeColor;
    }
    this.pdfViewer.annotation.setAnnotationMode('Circle');
    this.selectedAnnotationColor = this.pdfViewer.annotationModule.shapeAnnotationModule.circleStrokeColor;
  }

  public enableFreeText(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    this.selectedAnnotationColor = this.pdfViewer.freeTextSettings.borderColor;
    this.pdfViewer.annotation.setAnnotationMode('FreeText');
  }

  public enableLine(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    this.lineAnnotation.emit(true);
    if (this.pdfViewer.annotationModule.shapeAnnotationModule.lineStrokeColor) {
      this.pdfViewer.lineSettings.strokeColor = this.pdfViewer.annotationModule.shapeAnnotationModule.lineStrokeColor;
    }
    this.pdfViewer.annotation.setAnnotationMode('Line');
    this.selectedAnnotationColor = this.pdfViewer.annotationModule.shapeAnnotationModule.lineStrokeColor;
  }

  public enableStrikeThrough(): void {
    this.disablePencil();
    this.removePositiveAndNegativeRegionFocus();
    if (this.pdfViewer.annotationModule.textMarkupAnnotationModule.strikethroughColor) {
      this.selectedAnnotationColor = this.pdfViewer.annotationModule.textMarkupAnnotationModule.strikethroughColor;
    } else {
      this.selectedAnnotationColor = this.pdfViewer.strikethroughSettings.color;
    }
    this.pdfViewer.annotation.setAnnotationMode('Strikethrough');
  }

  public deleteSelectedAnnotation(): void {
    this.pdfViewer.annotation.deleteAnnotation();
  }

  public toggleCommentPanel(): void {
    if (!this.pdfViewer.isCommandPanelOpen) {
      this.pdfViewerService.showCommentsPanel(this.pdfViewer);
    } else {
      this.pdfViewerService.hideCommentsPanel(this.pdfViewer);
    }
  }

  public CloseViewer(): void {
    this.disableCloseButton = true;
    this.showLoader.emit({ show: true, showFull: false });
    this.disablePencil();
    this.saveAnnotationDetails().then(() => {
      this.exportAnnotation();
    });
  }

  public saveAnnotationDetails(): Promise<boolean> {
    return new Promise((resolve) => {
      const updatedAnnotations = this.annotationLqa.filter(
        (x) => !this.oldAnnotationLqaData.some((y) => JSON.stringify(x) === JSON.stringify(y))
      );
      const referencedPages =
        JSON.stringify(this.oldReferencedPages) !== JSON.stringify(this.selectedReferencePageIdentity)
          ? this.selectedReferencePageIdentity
          : [];
      if (updatedAnnotations.length > 0 || JSON.stringify(this.oldReferencedPages) !== JSON.stringify(this.selectedReferencePageIdentity)) {
        const saveAnnotationDetailsRequest: SaveAnnotationDetailsRequest = {
          annotations: updatedAnnotations.length > 0 ? updatedAnnotations : [],
          pageIdentity: this.pageIdentity,
          pageSequence: this.pageSequence,
          fileIdentity: this.fileIdentity,
          referencedPagesDetail: {
            referencedPages: referencedPages,
          },
          isReferencedPagesDeleted: !this.oldReferencedPages.every((x) => this.selectedReferencePageIdentity.some((y) => y === x)),
          languageId: this.pdfViewer.element.id === 'leftPdfViewer' ? LanguageType.English : LanguageType.Chinese,
        };

        this.pdfViewerService.saveAnnotationDetails(saveAnnotationDetailsRequest).subscribe(() => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  public exportAnnotation(): Promise<boolean> {
    return new Promise((resolve) => {
      if (
        this.existingAnnotationCollection === JSON.stringify(this.pdfViewer.annotationCollection) ||
        this.pdfViewer.annotation.actionCollection.length === 0
      ) {
        this.pdfViewer.fireExportSuccess(null, '');
      } else {
        if (this.pdfViewer.annotationCollection.length === 0) {
          let request = {
            document: this.pdfViewer.documentPath.split('/').at(-1),
            isAnnotationsExist: 'false',
            hasRealAnnotations: 'false',
            elementId: this.pdfViewer.element.id,
          };

          this.pdfViewerService.exportAnnotationPages(request, this.pageIdentity, this.jobIdentity).subscribe(() => {
            if (this.pdfViewer.element.id === 'leftPdfViewer') {
              this.closeLeftPdf.emit();
            }
            if (this.pdfViewer.element.id === 'rightPdfViewer') {
              this.closeRightPdf.emit();
            }
          });
        } else {
          this.pdfViewer.exportAnnotationsAsObject(AnnotationDataFormat.Xfdf);
          setTimeout(() => {
            this.pdfViewer.download();
          }, 100);
        }
      }

      this.existingAnnotationCollection = '[]';
      resolve(true);
    });
  }

  public enablePencil(): void {
    this.pencilButton = new Button({ cssClass: `e-flat`, iconCss: 'e-icons e-edit', isToggle: true });
    this.pencilButton.appendTo(`#${this.pdfViewer.element.id}_pencil_btn`);
    if (this.pencilButton.element) {
      this.pencilButton.element.onclick = () => {
        this.unsetAnnotationMode();
        if (this.isButtonSelected(this.pencilButton)) {
          this.pdfViewer.annotation.setAnnotationMode('Ink');
          this.disableColorPicker = false;
          this.isInkAnnotationSelected = true;
          this.selectedAnnotationColor = this.pdfViewer.inkAnnotationSettings.strokeColor;
        } else {
          this.unsetAnnotationMode();
          this.isInkAnnotationSelected = false;
          this.disableColorPicker = true;
        }
      };
    }
  }

  public disablePencil(disableColorPicker: boolean = false): void {
    this.unsetAnnotationMode();
    this.disableColorPicker = disableColorPicker;
    this.isInkAnnotationSelected = false;
    this.turnOffButtonSelection(this.pencilButton);
  }

  public enableTextStyle(): void {
    this.strikeoutButton = new Button({ cssClass: `e-flat`, iconCss: 'e-pv-strikeout-icon e-pv-icon', isToggle: true });
    this.strikeoutButton.appendTo(`#${this.pdfViewer.element.id}_strikeout_btn`);

    this.underlineButton = new Button({ cssClass: `e-flat`, iconCss: 'e-pv-underlinetext-icon e-pv-icon', isToggle: true });
    this.underlineButton.appendTo(`#${this.pdfViewer.element.id}_underline_btn`);

    this.boldButton = new Button({ cssClass: `e-flat`, iconCss: 'e-pv-bold-icon e-pv-icon', isToggle: true });
    this.boldButton.appendTo(`#${this.pdfViewer.element.id}_bold_btn`);

    this.italicButton = new Button({ cssClass: `e-flat`, iconCss: 'e-pv-italic-icon e-pv-icon', isToggle: true });
    this.italicButton.appendTo(`#${this.pdfViewer.element.id}_italic_btn`);

    this.positiveRegionButton = new Button({
      cssClass: `e-flat`,
      iconCss: 'e-pv-shape-rectangle-icon e-pv-icon',
      isToggle: true,
      content: 'Positive Region',
    });
    this.positiveRegionButton.appendTo('#positive-region');

    this.negativeRegionButton = new Button({
      cssClass: `e-flat`,
      iconCss: 'e-pv-shape-rectangle-icon e-pv-icon',
      isToggle: true,
      content: 'Negative Region',
    });
    this.negativeRegionButton.appendTo('#negative-region');

    if (this.strikeoutButton.element && this.underlineButton.element && this.boldButton.element && this.italicButton.element) {
      this.strikeoutButton.element.onclick = () => {
        let currentValue!: string;
        let fontInfo!: PdfFontModel;
        this.turnOffButtonSelection(this.underlineButton);
        if (this.isButtonSelected(this.strikeoutButton)) {
          currentValue = 'strikeout';
          fontInfo = {
            isStrikeout: true,
          };
        } else {
          currentValue = 'strikeout';
          fontInfo = {
            isStrikeout: false,
          };
        }
        this.pdfViewer.annotation.modifyTextProperties(fontInfo, currentValue);
      };

      this.underlineButton.element.onclick = () => {
        let currentValue!: string;
        let fontInfo!: PdfFontModel;
        this.turnOffButtonSelection(this.strikeoutButton);
        if (this.isButtonSelected(this.underlineButton)) {
          currentValue = 'underLine';
          fontInfo = {
            isUnderline: true,
          };
        } else {
          currentValue = 'underLine';
          fontInfo = {
            isUnderline: false,
          };
        }
        this.pdfViewer.annotation.modifyTextProperties(fontInfo, currentValue);
      };

      this.boldButton.element.onclick = () => {
        let currentValue!: string;
        let fontInfo!: PdfFontModel;
        if (this.isButtonSelected(this.boldButton)) {
          currentValue = 'bold';
          fontInfo = {
            isBold: true,
          };
        } else {
          currentValue = 'bold';
          fontInfo = {
            isBold: false,
          };
        }
        this.pdfViewer.annotation.modifyTextProperties(fontInfo, currentValue);
      };

      this.italicButton.element.onclick = () => {
        let currentValue!: string;
        let fontInfo!: PdfFontModel;
        if (this.isButtonSelected(this.italicButton)) {
          currentValue = 'italic';
          fontInfo = {
            isItalic: true,
          };
        } else {
          currentValue = 'italic';
          fontInfo = {
            isItalic: false,
          };
        }
        this.pdfViewer.annotation.modifyTextProperties(fontInfo, currentValue);
      };
    }

    if (this.positiveRegionButton.element && this.negativeRegionButton.element) {
      this.positiveRegionButton.element.onclick = () => {
        this.disableColorPicker = true;
        this.turnOffButtonSelection(this.negativeRegionButton);
        if (this.isButtonSelected(this.positiveRegionButton)) {
          this.setRectangleAnnotationMode('green', '#08FF0805', 'positive');
        } else {
          this.unsetAnnotationMode();
        }
      };
      this.negativeRegionButton.element.onclick = () => {
        this.disableColorPicker = true;
        this.turnOffButtonSelection(this.positiveRegionButton);
        if (this.isButtonSelected(this.negativeRegionButton)) {
          this.setRectangleAnnotationMode('red', '#FF080805', 'negative');
        } else {
          this.unsetAnnotationMode();
        }
      };
    }
  }

  private isButtonSelected(btn: Button) {
    return btn.element.classList.contains('e-active');
  }

  private unsetAnnotationMode() {
    this.pdfViewer.annotation.setAnnotationMode('None');
  }

  private turnOffButtonSelection(btn: Button) {
    if (btn && this.isButtonSelected(btn)) {
      btn.element.classList.remove('e-active');
    }
  }

  private setRectangleAnnotationMode(strokeColor: string, fillColor: string, type: string) {
    this.disablePencil(true);
    this.pdfViewer.rectangleSettings.strokeColor = strokeColor;
    this.pdfViewer.rectangleSettings.fillColor = fillColor;
    this.pdfViewer.rectangleSettings.thickness = 1.5;
    this.pdfViewer.rectangleSettings.customData = { type: type };
    this.pdfViewer.annotation.setAnnotationMode('Rectangle');
  }

  public isFreeTextSelected(): boolean | undefined {
    return (
      this.pdfViewer.selectedItems.annotations &&
      this.pdfViewer.selectedItems.annotations.length > 0 &&
      this.pdfViewer.selectedItems.annotations[0]?.shapeAnnotationType === 'FreeText'
    );
  }

  private setTextStyleSelected(textStyle: string): void {
    switch (textStyle) {
      case 'isBold':
        this.boldButton.element.classList.add('e-active');
        break;
      case 'isItalic':
        this.italicButton.element.classList.add('e-active');
        break;
      case 'isStrikeout':
        this.strikeoutButton.element.classList.add('e-active');
        break;
      case 'isUnderline':
        this.underlineButton.element.classList.add('e-active');
        break;
    }
  }

  public openLqaDialog(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: AppConstants.ErrorCategoryAndSeverityLabel,
      content: AddEditErrorCategoryDialogComponent,
      cssClass: this.isMonolingualSearch || this.isFinancialCheck ? '' : `${this.pdfViewer.element.id}-lqa-dialog`,
      appendTo: this.kendoDialogContainer,
      width: '300px',
    });
    dialog.content.instance.selectedAnnotation = this.selectedAnnotation;

    const selectedAnnotationLqa = this.annotationLqa.filter((x) => x.annotationId === this.selectedAnnotation.annotationId)[0];
    if (selectedAnnotationLqa) {
      dialog.content.instance.processLqaEditDialog(selectedAnnotationLqa);
    }

    // updating annotationLqa Array
    dialog.result.subscribe((result: any) => {
      if (result.text === AppConstants.SubmitLabel) {
        const index = this.annotationLqa.findIndex((x) => x.annotationId === result.data.annotationId);
        if (index > -1) {
          this.annotationLqa[index] = result.data;
        } else {
          this.annotationLqa.push(result.data);
        }
      }
    });
  }

  public onSelectColors(event: MenuEventArgs): void {
    this.clickedAnnotation = '';
    if (this.pdfViewerService.isFinancialCheckRegion((this.pdfViewer.rectangleSettings.customData as any)?.type)) {
      this.unsetAnnotationMode();
    }
    if (this.pdfViewer.annotation.shapeAnnotationModule.currentAnnotationMode) {
      this.clickedAnnotation = this.pdfViewer.annotation.shapeAnnotationModule.currentAnnotationMode;
      this.unsetAnnotationMode();
    } else if (this.pdfViewer.drawingObject && this.pdfViewer.drawingObject.shapeAnnotationType === 'FreeText') {
      this.clickedAnnotation = 'FreeText';
      this.unsetAnnotationMode();
    }
    switch (event.item.text) {
      case 'Light purple':
        this.annotationColorChange('#eda5de');
        break;
      case 'Tan':
        this.annotationColorChange('#d2b48c');
        break;
      case 'Mauve':
        this.annotationColorChange('#b784a7');
        break;
      case 'Peach':
        this.annotationColorChange('#ffdaba');
        break;
      case 'Brown':
        this.annotationColorChange('#88401b');
        break;
      case 'Light yellow':
        this.annotationColorChange('#ffff80');
        break;
      case 'Red':
        this.annotationColorChange('#eb4760');
        break;
    }
  }

  private annotationColorChange(color: string): void {
    this.selectedAnnotationColor = color;
    if (this.clickedAnnotation) {
      this.setShapeAnnotationColor(this.clickedAnnotation, color, true);
    }
    if (this.pdfViewer.annotationModule.textMarkupAnnotationModule.currentTextMarkupAnnotation) {
      this.pdfViewer.annotationModule.textMarkupAnnotationModule.modifyColorProperty(color);
      this.setTextMarkupColor(
        color,
        this.pdfViewer.annotationModule.textMarkupAnnotationModule.currentTextMarkupAnnotation.textMarkupAnnotationType
      );
    } else if (this.pdfViewer.annotationModule.textMarkupAnnotationModule.currentTextMarkupAddMode) {
      this.setTextMarkupColor(color, this.pdfViewer.annotationModule.textMarkupAnnotationModule.currentTextMarkupAddMode);
    }
    if (this.pdfViewer.selectedItems.annotations && this.pdfViewer.selectedItems.annotations.length > 0) {
      this.pdfViewer.annotation.modifyStrokeColor(color);
      this.setShapeAnnotationColor(this.pdfViewer.selectedItems.annotations[0].shapeAnnotationType!, color, false);
    }
    if (this.isInkAnnotationSelected) {
      this.pdfViewer.inkAnnotationSettings.strokeColor = color;
    }
  }

  private setShapeAnnotationColor(selectedAnnotation: string, color: string, setAnnotationMode: boolean): void {
    switch (selectedAnnotation) {
      case 'Line':
        if (setAnnotationMode) {
          this.pdfViewer.lineSettings.strokeColor = color;
          this.pdfViewer.annotation.setAnnotationMode('Line');
        } else {
          this.pdfViewer.annotationModule.shapeAnnotationModule.lineStrokeColor = color;
        }
        break;
      case 'Arrow':
      case 'LineWidthArrowHead':
        if (setAnnotationMode) {
          this.pdfViewer.arrowSettings.strokeColor = color;
          this.pdfViewer.annotation.setAnnotationMode('Arrow');
        } else {
          this.pdfViewer.annotationModule.shapeAnnotationModule.arrowStrokeColor = color;
        }
        break;
      case 'Rectangle':
        if (setAnnotationMode) {
          this.pdfViewer.rectangleSettings.strokeColor = color;
          this.pdfViewer.annotation.setAnnotationMode('Rectangle');
        } else {
          this.pdfViewer.annotationModule.shapeAnnotationModule.rectangleStrokeColor = color;
        }
        break;
      case 'Circle':
      case 'Ellipse':
        if (setAnnotationMode) {
          this.pdfViewer.circleSettings.strokeColor = color;
          this.pdfViewer.annotation.setAnnotationMode('Circle');
        } else {
          this.pdfViewer.annotationModule.shapeAnnotationModule.circleStrokeColor = color;
        }
        break;
      case 'FreeText':
        this.pdfViewer.freeTextSettings.borderColor = color;
        if (setAnnotationMode) {
          this.pdfViewer.annotation.setAnnotationMode('FreeText');
        }
        break;
    }
  }

  private setTextMarkupColor(color: string, selectedTextMarkup: string): void {
    switch (selectedTextMarkup) {
      case 'Highlight':
        this.pdfViewer.annotationModule.textMarkupAnnotationModule.highlightColor = color;
        break;
      case 'Underline':
        this.pdfViewer.annotationModule.textMarkupAnnotationModule.underlineColor = color;
        break;
      case 'Strikethrough':
        this.pdfViewer.annotationModule.textMarkupAnnotationModule.strikethroughColor = color;
        break;
    }
  }

  public itemBeforeEvent(event: MenuEventArgs): void {
    let selectedAnnotationColor;
    if (this.selectedAnnotationColor) {
      selectedAnnotationColor = this.selectedAnnotationColor.startsWith('#')
        ? this.selectedAnnotationColor?.toLowerCase()
        : this.rgbaToHex(this.selectedAnnotationColor);
    }
    switch (event.item.text) {
      case 'Light purple':
        event.element.innerHTML = `<span class='color-picker light-purple'></span><div>Light purple</div>`;
        if (selectedAnnotationColor === AnnotationColor['Light purple']) {
          event.element.setAttribute('class', 'color-active e-item');
        }
        break;
      case 'Tan':
        event.element.innerHTML = `<span class='color-picker tan'></span><div>Tan</div>`;
        if (selectedAnnotationColor === AnnotationColor.Tan) {
          event.element.setAttribute('class', 'color-active e-item');
        }
        break;
      case 'Mauve':
        event.element.innerHTML = `<span class='color-picker mauve'></span><div>Mauve</div>`;
        if (selectedAnnotationColor === AnnotationColor.Mauve) {
          event.element.setAttribute('class', 'color-active e-item');
        }
        break;
      case 'Peach':
        event.element.innerHTML = `<span class='color-picker peach'></span><div>Peach</div>`;
        if (selectedAnnotationColor === AnnotationColor.Peach) {
          event.element.setAttribute('class', 'color-active e-item');
        }
        break;
      case 'Brown':
        event.element.innerHTML = `<span class='color-picker brown'></span><div>Brown</div>`;
        if (selectedAnnotationColor === AnnotationColor.Brown) {
          event.element.setAttribute('class', 'color-active e-item');
        }
        break;
      case 'Light yellow':
        event.element.innerHTML = `<span class='color-picker light-yellow'></span><div>Light yellow</div>`;
        if (selectedAnnotationColor === AnnotationColor['Light yellow']) {
          event.element.setAttribute('class', 'color-active e-item');
        }
        break;
      case 'Red':
        event.element.innerHTML = `<span class='color-picker red'></span><div>Red</div>`;
        if (selectedAnnotationColor === AnnotationColor.Red || selectedAnnotationColor === AnnotationColor['PdfViewer Red']) {
          event.element.setAttribute('class', 'color-active e-item');
        }
        break;
    }
  }

  public onColorChangeClick() {
    if (this.isInkAnnotationSelected && this.pdfViewer.selectedItems.annotations?.length === 0) {
      this.unsetAnnotationMode();
      this.disableColorPicker = false;
      this.pdfViewer.annotation.setAnnotationMode('Ink');
    }
  }

  public isAnnotationSelectedForColor(): boolean {
    return (
      (this.pdfViewer.annotationModule?.textMarkupAnnotationModule.currentTextMarkupAnnotation ||
        (this.pdfViewer.selectedItems.annotations &&
          this.pdfViewer.selectedItems.annotations.length > 0 &&
          !(this.pdfViewer.selectedItems.annotations[0].customData as any)?.dependsOn &&
          this.pdfViewer.selectedItems.annotations[0].sourceDecoraterShapes !== 'OpenArrow' &&
          this.pdfViewer.selectedItems.annotations &&
          !this.isFinancialRegionSelected(this.pdfViewer.selectedItems.annotations[0]))) != null
    );
  }

  private rgbaToHex(rgba: string): string {
    let sep = rgba.indexOf(',') > -1 ? ',' : ' ';
    let number = rgba.substring(5).split(')')[0].split(sep);
    const outParts = [(+number[0]).toString(16), (+number[1]).toString(16), (+number[2]).toString(16)];

    // Pad single-digit output values
    outParts.forEach((part, i) => {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    });

    return '#' + outParts.join('');
  }

  public getImportPages() {
    this.showLoader.emit({ show: true, showFull: true });
    this.pdfViewerService
      .getImportPages(this.jobIdentity, this.isMonolingualSearch ? this.fileIdentity : this.otherFileIdentity, this.pageIdentity)
      .subscribe((data) => {
        const dialog: DialogRef = this.dialogService.open({
          content: ImportPagesComponent,
          width: '636px',
        });
        this.oldReferencedPages = [...data.referencedPagesDetail.referencedPages];
        dialog.content.instance.referencedPages = data.referencePages;
        if (this.selectedReferencePageIdentity.length === 0 && !this.referencedPagesDeleted) {
          this.selectedReferencePageIdentity = data.referencedPagesDetail.referencedPages;
        }
        dialog.content.instance.selectedPages = this.selectedReferencePageIdentity;
        this.showLoader.emit({ show: false, showFull: false });

        dialog.result.subscribe((result: any) => {
          if (result.text === 'Close') {
            this.selectedReferencePageIdentity = [];
            this.selectedReferencePageIdentity = result.data;
            this.referencedPagesDeleted = !this.oldReferencedPages.every((x) => this.selectedReferencePageIdentity.some((y) => y === x));
            this.referencedPagesCount = this.selectedReferencePageIdentity.length;
          }
        });
      });
  }

  public addRect() {
    this.eRect.emit();
  }

  public undoButton() {
    this.removePositiveAndNegativeRegionFocus();
    this.unsetAnnotationMode();
    const oldActionCollection = [...this.pdfViewer.annotationModule.actionCollection];

    this.pdfViewer.undo();

    const finanacialRegions = oldActionCollection.filter(
      (x) => this.getUndoAnnotation(x) && (x.annotation.customData?.type || this.isFinancialRegionSelected(x.annotation))
    );
    if (finanacialRegions.length > 0) {
      finanacialRegions[0].undoElement.annotation = {
        annotationId: finanacialRegions[0].annotation['annotName'],
        customData: finanacialRegions[0].annotation.customData,
      };
      this.financialRegionUndo.emit({ action: finanacialRegions[0].action, annotation: finanacialRegions[0].undoElement });
    }
    // filtering only callout annotation from actioncollection after undo
    const calloutAnnotation = oldActionCollection.filter(
      (x) => this.getUndoAnnotation(x) && x.action !== 'dynamicText Change' && x.annotation.customData?.dependsOn
    )[0];

    const undoCalloutAnnotationId = calloutAnnotation?.annotation.properties.customData?.dependsOn;
    const action = calloutAnnotation?.action;
    const calloutAnnotationId = calloutAnnotation?.annotation.annotName;
    const calloutShapeType = calloutAnnotation?.annotation.shapeAnnotationType;

    // clear lqa if an annotation removed when undo
    const undoAnnotation = oldActionCollection.filter(
      (x) => this.getUndoAnnotation(x) && (x.action === 'Addition' || x.action === 'Text Markup Added')
    );
    let index;
    if (!undoCalloutAnnotationId) {
      index = this.annotationLqa.findIndex((y) => y.annotationId === undoAnnotation[0].annotation.annotName);
    } else {
      index = this.annotationLqa.findIndex((y) => y.annotationId === undoCalloutAnnotationId);
    }

    if (index > -1) {
      this.annotationLqa.splice(index, 1);
    }

    if (undoCalloutAnnotationId) {
      //if we click undo after adding a new callout annotation only line will get undo so we need to remove textbox manually
      if (action === 'Addition') {
        const lineAnnotation = this.pdfViewer.annotationCollection.filter((x) => x.annotationId === calloutAnnotationId)[0];
        if (lineAnnotation) {
          lineAnnotation.customData.isUndo = true;
        }
        this.pdfViewer.annotation.deleteAnnotationById(undoCalloutAnnotationId);
        const index = this.pdfViewer.annotationModule?.actionCollection.findIndex(
          (x) => x.action === action && x.annotation.properties.annotName === undoCalloutAnnotationId
        );
        this.pdfViewer.annotationModule?.actionCollection.splice(index, 1);
      }

      //if we delete a callout annotation and click undo it should bring back the callout annotation
      if (action === 'Delete') {
        this.pdfViewer.undo();
        this.pdfViewerService.changeCommentIcon(calloutShapeType === 'Line' ? undoCalloutAnnotationId : calloutAnnotationId);
        this.pdfViewerService.removeCommentDiv(calloutShapeType === 'Line' ? calloutAnnotationId : undoCalloutAnnotationId);
      }

      const freetextDetails: CalloutFreetextBounds = {
        annotationId: calloutAnnotation.annotation.annotName,
        left: calloutAnnotation.undoElement.bounds.x - calloutAnnotation.undoElement.bounds.width / 2,
        top: calloutAnnotation.undoElement.bounds.y - calloutAnnotation.undoElement.bounds.height / 2,
        width: calloutAnnotation.undoElement.bounds.width,
      };
      if (action === 'Drag') {
        if (calloutAnnotation.annotation.shapeAnnotationType === 'FreeText') {
          this.pdfViewerService.processFreeTextAnnotation(this.pdfViewer, freetextDetails);
        }
        if (calloutAnnotation.annotation.shapeAnnotationType === 'Line') {
          this.pdfViewerService.processLineAnnotation(this.pdfViewer, calloutAnnotation.annotation.annotName, this.pageSize);
        }
      }

      if (action === 'Resize') {
        if (calloutAnnotation.annotation.shapeAnnotationType === 'FreeText') {
          this.pdfViewerService.processFreeTextAnnotation(this.pdfViewer, freetextDetails);
        }
        if (calloutAnnotation.annotation.shapeAnnotationType === 'Line') {
          this.pdfViewerService.processLineAnnotation(this.pdfViewer, calloutAnnotation.annotation.annotName, this.pageSize, true);
        }
      }
    }
  }

  public getUndoAnnotation(actionCollection: IActionElements) {
    return (
      !this.pdfViewer.annotationModule.actionCollection.some(
        (y) => y.action === actionCollection.action && y.annotation.annotName === actionCollection.annotation.annotName
      ) ||
      ((actionCollection.action === 'Drag' || actionCollection.action === 'Resize') &&
        !this.pdfViewer.annotationModule.actionCollection.some((y) => y.undoElement.bounds === actionCollection.undoElement.bounds))
    );
  }

  public enableDisableUndoButton(): boolean {
    return this.pdfViewer.annotationModule?.actionCollection?.filter((x) => x.action !== 'Text Markup Property modified').length === 0;
  }

  private removePositiveAndNegativeRegionFocus(setAnnotationMode: boolean = true) {
    if (this.positiveRegionButton?.element && this.negativeRegionButton?.element) {
      this.turnOffButtonSelection(this.positiveRegionButton);
      this.turnOffButtonSelection(this.negativeRegionButton);
      if (setAnnotationMode) {
        this.pdfViewer.annotation.setAnnotationMode('None');
      }
    }
  }

  public clearButtonEnabled() {
    if (
      this.pdfViewer.annotationModule?.shapeAnnotationModule.currentAnnotationMode === 'Rectangle' &&
      this.positiveRegionButton &&
      this.negativeRegionButton &&
      this.pdfViewer.annotationCollection?.length > 0
    ) {
      this.addActiveForFinancialRegion();
    }
  }

  private addActiveForFinancialRegion() {
    if ((this.pdfViewer.rectangleSettings.customData as any)?.type === 'positive') {
      this.positiveRegionButton.element.classList.add('e-active');
    } else if ((this.pdfViewer.rectangleSettings.customData as any)?.type === 'negative') {
      this.negativeRegionButton.element.classList.add('e-active');
    }
  }

  private isFinancialRegionSelected(annotations: any): boolean {
    return annotations.customData?.type;
  }

  public disableFinancialRegions(): boolean {
    return (
      this.isPageLocked || !(this.pdfViewer.annotationCollection && this.pdfViewer.annotationCollection.some((x) => x.customData?.type))
    );
  }

  public getAnnotationDetails() {
    if (this.pdfViewer.ajaxRequestSettings.ajaxHeaders && this.pdfViewer.ajaxRequestSettings.ajaxHeaders.length > 0) {
      this.annotationLqa = [];
      this.oldAnnotationLqaData = [];
      this.pageIdentity = +this.pdfViewer.ajaxRequestSettings.ajaxHeaders.filter((x) => x.headerName === 'PageIdentity')[0].headerValue;
      this.pageSequence = +this.pdfViewer.ajaxRequestSettings.ajaxHeaders.filter((x) => x.headerName === 'PageSequence')[0].headerValue;
      this.fileIdentity = +this.pdfViewer.ajaxRequestSettings.ajaxHeaders.filter((x) => x.headerName === 'FileIdentity')[0].headerValue;
      this.otherFileIdentity = +this.pdfViewer.ajaxRequestSettings.ajaxHeaders.filter((x) => x.headerName === 'OtherFileIdentity')[0]
        .headerValue;
      this.jobIdentity = this.pdfViewer.ajaxRequestSettings.ajaxHeaders.filter((x) => x.headerName === 'JobIdentity')[0].headerValue!;
      this.pdfViewerService.getAnnotationLqa(this.pageIdentity).subscribe((annotations: AnnotationDetailsResponse) => {
        this.referencedPagesCount = annotations.referencedPagesCount;
        if (annotations.annotationDetails.length > 0) {
          this.annotationLqa = annotations.annotationDetails;
          this.oldAnnotationLqaData = [...this.annotationLqa];
        }
      });
    }
  }
}
