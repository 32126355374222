import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogOptions } from 'src/app/models/dialog-options.model';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<ConfirmDialogComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions
  ) { }

  ngOnInit() {
    this.dialogService.CloseOnEscapeKey(this.matDialogRef);
    this.dialogService.CloseOnBackdropClick(this.matDialogRef);
  }

}
