<div class="read-accept">
    <app-acs-pdf-viewer [loadPdf]="data?.fileContent"></app-acs-pdf-viewer>
    <br />
    <p class="checkbox-label">
      <app-kendo-checkbox-field
        [id]="'read-acceptance-checkbox'"
        [label]="constants.AcceptTermsAndConditionsAndDataPrivacyNotice"
        (valueChange)="onSelectChange($event)">
      </app-kendo-checkbox-field>
    </p>
    <div class="accept-button">
      <app-button
        [label]="constants.AcceptLabel"
        [size]="'large'"
        [themeColor]="'primary'"
        [disabled]="!isAccepted"
        (clicked)="onAcceptClick()">
      </app-button>
    </div>
  </div>
