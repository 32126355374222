<mat-toolbar [class]="getPositionClass()">
  <button
    [attr.data-dd-action-name]="appConstants.UnlockPageConfirmationTitle"
    mat-icon-button
    (click)="onResetPageStatus($event)"
    [disabled]="!isPageLocked || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.UnlockPageConfirmationTitle"
    [matTooltipPosition]="getPositionClass()"
    aria-label="Lock unlock compare page">
    <mat-icon>lock</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="isBookmarked ? appConstants.RemoveBookmarkTooltip : appConstants.SetBookmarkTooltip"
    mat-icon-button
    (click)="onBookmarkPage($event)"
    [disabled]="isJobLocked"
    *ngIf="!hasSamePageCount"
    onclick="this.blur()"
    [matTooltipPosition]="getPositionClass()"
    [matTooltip]="isBookmarked ? appConstants.RemoveBookmarkTooltip : appConstants.SetBookmarkTooltip"
    aria-label="Bookmark page">
    <mat-icon class="bookmark-solid" *ngIf="isBookmarked"> bookmark </mat-icon>
    <mat-icon *ngIf="!isBookmarked"> bookmark_border </mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.AnnotatePdfTooltip"
    mat-icon-button
    (click)="onAnnotateClick($event)"
    [disabled]="isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.AnnotatePdfTooltip"
    [matTooltipPosition]="getPositionClass()"
    aria-label="PDF Viewer">
    <mat-icon svgIcon="pdf-viewer"></mat-icon>
  </button>

  <button
    [attr.data-dd-action-name]="appConstants.AcceptTooltip"
    mat-icon-button
    (click)="onAcceptClick($event)"
    [disabled]="isPageLocked || hasNoFacts || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.AcceptTooltip"
    [matTooltipPosition]="getPositionClass()"
    aria-label="Mark Accept">
    <mat-icon>done</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.ErrorTooltip"
    mat-icon-button
    (click)="onRejectClick($event)"
    [disabled]="isPageLocked || hasNoFacts || isJobLocked"
    onclick="this.blur()"
    class="btnError hover-icon"
    [matTooltip]="appConstants.ErrorTooltip"
    [matTooltipPosition]="getPositionClass()"
    aria-label="Mark as Error">
    <mat-icon>close</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.AcceptAndMoveTooltip"
    mat-icon-button
    (click)="onAcceptAndRejectClick($event)"
    [disabled]="acceptAndRejectDisabled || hasNoFacts || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.AcceptAndMoveTooltip"
    [matTooltipPosition]="getPositionClass()"
    aria-label="Mark Accept and Error">
    <mat-icon>check_circle_outline</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.SwapTooltip"
    mat-icon-button
    (click)="onSwapClick($event)"
    [disabled]="isPageLocked || hasNoFacts || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.SwapTooltip"
    [matTooltipPosition]="getPositionClass()"
    aria-label="Swap with next">
    <mat-icon>swap_horizontal_circle</mat-icon>
  </button>

  <button
    [attr.data-dd-action-name]="appConstants.UndoLastAction"
    mat-icon-button
    (click)="onUndoClick($event)"
    onclick="this.blur()"
    [disabled]="!hasUndoHistory || hasNoFacts || isJobLocked"
    [matTooltip]="appConstants.UndoLastAction"
    [matTooltipPosition]="getPositionClass()"
    aria-label="undo last action">
    <mat-icon>undo</mat-icon>
  </button>
</mat-toolbar>
