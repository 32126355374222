import { Component, QueryList, OnInit, ViewChildren, ElementRef, OnDestroy } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { FormControl, Validators } from '@angular/forms';
import { Subject, Subscription, debounceTime } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';

import { FormFieldError } from 'src/app/models/form-field-error.model';
import { ImportPagesDto } from 'src/app/models/import-pages-dto.model';

@Component({
  selector: 'app-import-pages',
  templateUrl: './import-pages.component.html',
  styleUrls: ['./import-pages.component.css'],
})
export class ImportPagesComponent extends DialogContentBase implements OnInit, OnDestroy {
  @ViewChildren('checkBox') public checkBoxField!: QueryList<ElementRef>;

  public constants: typeof AppConstants = AppConstants;

  private subscription!: Subscription;
  private valueChangeSubject: Subject<any> = new Subject();
  public selectedPagesControl: FormControl = new FormControl();

  public referencedPages: ImportPagesDto[] = [];
  public selectedReferencedPages: ImportPagesDto[] = [];
  public selectedPages: number[] = [];
  public addedReferencedPages: ImportPagesDto[] = [];
  public listViewData: any[] = [];
  public isEditClicked: boolean = false;
  public content: any;

  public selectedPagesErrors: FormFieldError[] = [
    { key: 'max', message: 'Please enter valid page number' },
    { key: 'pattern', message: 'Please enter valid page number' },
    { key: 'range', message: 'Invalid page range' },
  ];

  constructor(public override dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {
    this.referencedPages = [...this.referencedPages].sort((a, b) => a.pageSequence - b.pageSequence);
    this.selectedPages.forEach((y) => {
      this.selectedReferencedPages.push(...this.referencedPages.filter((x) => x.pageIdentity == y));
    });
    if (this.selectedReferencedPages.length === 0) {
      this.isEditClicked = true;
      this.listViewData = this.referencedPages;
    } else {
      this.listViewData = this.selectedReferencedPages;
      this.addedReferencedPages.push(...this.selectedReferencedPages);
      const value = this.selectedReferencedPages.map((x) => x.pageSequence).toString();
      this.selectedPagesControl.setValue(value);
    }

    this.selectedPagesControl.setValidators(Validators.pattern(/^(?:[1-9][0-9]*(?:-[1-9][0-9]*)?(?:,|$))+$/));

    this.subscription = this.valueChangeSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      if (value.length === 0) {
        this.addedReferencedPages = [];
        this.referencedPages.forEach((y) => {
          const element = this.checkBoxField.filter((x) => x.nativeElement.classList.contains('import-page-' + y.pageSequence))[0]
            .nativeElement;
          if (element.checked) {
            element.checked = false;
            element.classList.remove('selected');
          }
        });
        return;
      }
      const val = this.hypenatedString(value.split(','));
      const indexes = val.filter((x) => !x.includes('-'));
      indexes.forEach((index) => {
        const element = this.checkBoxField.filter((x) => x.nativeElement.classList.contains('import-page-' + index))[0].nativeElement;
        if (!element.checked) {
          element.checked = true;
          element.classList.add('selected');
          this.addedReferencedPages.push(this.listViewData.filter((x) => x.pageSequence == index)[0]);
        }
      });
      this.listViewData.forEach((data) => {
        if (!indexes.some((x) => x == data.pageSequence)) {
          const element = this.checkBoxField.filter((x) => x.nativeElement.classList.contains('import-page-' + data.pageSequence))[0]
            .nativeElement;
          if (element.checked) {
            element.checked = false;
            element.classList.remove('selected');
            const removeFileIndex = this.addedReferencedPages.findIndex((x) => x.pageSequence == data.pageSequence);
            this.addedReferencedPages.splice(removeFileIndex, 1);
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public hypenatedString(val: string[]) {
    if (val.length > 0) {
      val.forEach((x) => {
        if (x.includes('-')) {
          const [start, end] = x.split('-').map(Number);
          if (
            !this.selectedPagesControl.hasError('pattern') &&
            (start > this.referencedPages.length || end > this.referencedPages.length || start > end)
          ) {
            this.selectedPagesControl.setErrors({ range: true });
            return;
          }
          const numbersArray = [];
          for (let i = start; i <= end; i++) {
            numbersArray.push(i);
          }
          const array = numbersArray.toString().split(',');
          val.push(...array);
        }
        if (val.some((x) => +x > this.referencedPages.length)) {
          this.selectedPagesControl.setErrors({ max: true });
          return;
        }
      });
    }
    return val;
  }

  public onImageClick(data: ImportPagesDto, event: any, isClickedfromPreview: boolean) {
    this.selectedPagesControl.markAsDirty();
    if (isClickedfromPreview) {
      return;
    }
    const element = this.checkBoxField.filter((x) => x.nativeElement.classList.contains('import-page-' + data.pageSequence))[0]
      .nativeElement;
    if (!event.target.className.includes('image-checkbox')) {
      element.checked = !element.checked;
    }
    if (element.checked) {
      element.classList.add('selected');
      this.addedReferencedPages.push(data);
      const value = this.selectedPagesControl.value ? this.selectedPagesControl.value + ',' + data.pageSequence : data.pageSequence;
      this.selectedPagesControl.setValue(value);
    } else {
      element.classList.remove('selected');
      const pageIndex = this.addedReferencedPages.findIndex((x) => x.pageIdentity === data.pageIdentity);
      this.addedReferencedPages.splice(pageIndex, 1);
      const selectedPagesValue =
        this.selectedPagesControl.value.length > 1
          ? this.selectedPagesControl.value.split(',')
          : [this.selectedPagesControl.value.toString()];
      const value = this.hypenatedString(selectedPagesValue).filter((x) => !x.includes('-'));
      const index = value.findIndex((x: any) => x == data.pageSequence);
      value.splice(index, 1);

      this.selectedPagesControl.setValue(value.toString());
    }
  }

  public onEdit() {
    this.isEditClicked = true;
    this.listViewData = this.referencedPages;
    this.addedReferencedPages = [...this.selectedReferencedPages];
    setTimeout(() => {
      if (this.selectedReferencedPages.length > 0) {
        this.selectedReferencedPages.forEach((x) => {
          const element = this.checkBoxField.filter((z, i) => z.nativeElement.classList.contains('import-page-' + x.pageSequence))[0]
            .nativeElement;
          element.checked = true;
          element.classList.add('selected');
        });
      }
    }, 100);
  }

  public onSubmit() {
    this.isEditClicked = false;
    this.selectedReferencedPages = [...this.addedReferencedPages];
    this.listViewData = this.selectedReferencedPages;
    this.listViewData.sort((a, b) => a.pageSequence - b.pageSequence);
  }

  public onCancel() {
    this.addedReferencedPages = [];
    this.listViewData = this.selectedReferencedPages;
    this.selectedPagesControl.setValue(this.selectedReferencedPages.map((s) => s.pageSequence).toString());
    this.isEditClicked = false;
  }

  public onValueChange(value: any) {
    this.valueChangeSubject.next(value);
  }

  public onClose() {
    this.dialog.close({ text: 'Close', data: this.selectedReferencedPages.map((x) => x.pageIdentity) });
  }
}
