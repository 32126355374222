import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppConstants } from 'src/app/app.constants';

import { FormFieldError } from 'src/app/models/form-field-error.model';

import { DialogService } from 'src/app/services/dialog.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-go-to-page',
  templateUrl: './go-to-page.component.html'
})
export class GoToPageComponent implements OnInit {
  public goToPageForm!: FormGroup;
  public englishPageControl: FormControl;
  public chinesePageControl: FormControl;

  public hasSamePageCount = false;

  public readonly pageControlErrors: FormFieldError[] = [
    { key: 'min', message: AppConstants.ErrorNotValidPageNumber },
    { key: 'max', message: AppConstants.ErrorNotValidPageNumber },
    { key: 'required', message: AppConstants.ErrorRequiredValue },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<GoToPageComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      confirmText: string;
      cancelText: string;
      englishPageNumber: number;
      chinesePageNumber: number;
      englishPageCount: number;
      chinesePageCount: number;
    }
  ) {
    this.hasSamePageCount = data.englishPageCount === data.chinesePageCount;
    this.englishPageControl = new FormControl(this.data.englishPageNumber,
      [Validators.required, Validators.min(1), Validators.max(this.data.englishPageCount)]);
    this.chinesePageControl = new FormControl(this.data.chinesePageNumber,
      [Validators.required, Validators.min(1), Validators.max(this.data.chinesePageCount)]);
  }

  ngOnInit() {
    this.goToPageForm = this.formBuilder.group({
      englishPageControl: this.englishPageControl,
      chinesePageControl: this.chinesePageControl
    });

    this.CloseOnEnterKey(this.matDialogRef);
    this.dialogService.CloseOnEscapeKey(this.matDialogRef);
    this.dialogService.CloseOnBackdropClick(this.matDialogRef);
  }

  private CloseOnEnterKey(dialogRef: MatDialogRef<any>) {
    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Enter') {
        dialogRef.close(this.onSubmit());
      }
    });
  }

  public onSubmit() {
    return { englishPageNumber: +this.englishPageControl.value, chinesePageNumber: +this.chinesePageControl.value };
  }

  public onChange(control: FormControl, pageNumber: number) {
    if (this.hasSamePageCount) {
      control.setValue(pageNumber);
    }
    this.goToPageForm.markAllAsTouched();
  }

  public getErrorMessage(control: FormControl): string {
    if (control.hasError('required')) {
      return AppConstants.ErrorRequiredValue;
    }
    if (control.hasError('min') || control.hasError('max')) {
      return AppConstants.ErrorNotValidPageNumber;
    }

    return '';
  }

}
