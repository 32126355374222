<div class="header-container">
  <!-- #region Header section -->
  <mat-toolbar>
    <div
      [attr.data-dd-action-name]="appConstants.ApplicationTitle"
      class="application-logo"
      [matTooltip]="appConstants.ApplicationTitle"
      onclick="location.href = '/';"></div>
    <div class="company-logo"></div>
    <div class="user-details">
      <span [attr.data-dd-action-name]="'User Account'" [matMenuTriggerFor]="userAccount" class="material-icons user-account"
        >account_circle</span
      >
    </div>
  </mat-toolbar>
  <!-- #endregion -->
</div>

<!-- User Account Menu Start -->
<mat-menu #userAccount="matMenu" overlapTrigger="false">
  <button (click)="$event.stopPropagation()" class="user-act-btn">
    <mat-icon class="menu-li-useraccount">account_circle</mat-icon>
    <div class="user-act-div">
      <span class="user-name">{{ appDataService.user.displayName }}</span>
      <br />
      <span class="location-span">{{ userLocation }}</span>
    </div>
  </button>

  <button *ngIf="!appDataService.user.isExternalUser" mat-menu-item class="user-role" (click)="$event.stopPropagation()">
    <mat-form-field class="user-role-formfield">
      <mat-label>{{ appConstants.UserRoleLabel }}</mat-label>
      <mat-select
        [formControl]="roleCtrl"
        #singleSelect
        disableOptionCentering
        panelClass="user-role-options"
        [(value)]="selectedRole"
        (selectionChange)="changeUserRole($event.value)">
        <mat-option class="user-role-search">
          <ngx-mat-select-search
            [formControl]="roleFilter"
            placeholderLabel="Search"
            [noEntriesFoundLabel]="appConstants.NoDataFound"
            [preventHomeEndKeyPropagation]="true">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option [attr.data-dd-action-name]="role.name" *ngFor="let role of filteredRoles | async" [value]="role.functionalRoleIdentity">
          {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </button>
  <button [attr.data-dd-action-name]="'Logout'" class="btn-logout" (click)="this.commonService.logout()">
    <mat-icon>lock</mat-icon><span class="position-absolute">Logout</span>
  </button>
</mat-menu>
<!-- User Account Menu End -->
