<span kendoTooltip [title]="toolTip">
  <kendo-dropdownbutton
    [attr.data-dd-action-name]="itemName"
    [icon]="icon"
    [data]="data"
    [size]="size"
    [rounded]="rounded"
    [disabled]="disabled"
    [fillMode]="fillMode"
    [themeColor]="themeColor"
    [popupSettings]="popupSettings"
    (itemClick)="onItemClick($event)">
    {{ label }}
  </kendo-dropdownbutton>
</span>
