import { Pipe, PipeTransform } from '@angular/core';
import { ContainerDetail } from 'src/app/models/container-detail.model';

@Pipe({
  name: 'currentIndexValue',
  pure: false,
})
export class CurrentIndexValuePipe implements PipeTransform {
  transform(container: ContainerDetail, index: number = 0): unknown {
    if (!container.factList[index]) {
      return '';
    }
    return container.factList[index].value;
  }
}
