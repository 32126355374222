import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogOptions } from 'src/app/models/dialog-options.model';

import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-about-app',
  templateUrl: './about-app.component.html',
  styleUrls: ['./about-app.component.css']
})
export class AboutAppComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<AboutAppComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions) { }

  ngOnInit() {
    this.dialogService.CloseOnEscapeKey(this.matDialogRef);
    this.dialogService.CloseOnBackdropClick(this.matDialogRef);
  }

}
