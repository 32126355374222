import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '../enums/job-status.enum';
import { FeatureType } from '../enums/feature-type.enum';
import { CommonService } from 'src/app/services/common.service';

@Pipe({
  name: 'jobStatusAbbreviated',
})
export class JobStatusAbbreviatedPipe implements PipeTransform {
  constructor(public commonService: CommonService) {}

  transform(jobStatus: number, typeId: number): string {
    switch (jobStatus) {
      case JobStatus.Conversion_In_Progress:
        return 'WIP';
      case JobStatus.Ready_To_Compare:
        if (typeId === FeatureType.Call_Redaction) {
          return 'Done';
        } else {
          return 'RTC';
        }
      case JobStatus.Output_File_Pending:
        return 'OFP';
      default:
        return this.commonService.getJobStatusTooltip(jobStatus);
    }
  }
}
