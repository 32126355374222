import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { AppConstants } from '../app.constants';

import { IAppSettings } from '../models/app-settings.model';
import { ValidUserDetailsResponse } from '../models/valid-user-details-response.model';
import { GetAcceptRequest } from '../models/get-accept-request';
import { AppList } from '../core/enums/application-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiPath: string = ((<any>window)[AppConstants.AppSettings] as IAppSettings).common.userManagementApiPath;

  private customHttpClient: HttpClient;

  constructor(backend: HttpBackend, private http: HttpClient) {
    this.customHttpClient = new HttpClient(backend);
  }

  public IsValidUser(userIdOrEmail: string): Observable<ValidUserDetailsResponse> {
    return this.customHttpClient.get<ValidUserDetailsResponse>(
      `${this.apiPath}api/User/IsValidUser/${userIdOrEmail}?applicationId=${AppList.ACS}`
    );
  }

  public updateUserPrimaryFunctionalRole(teamId: number) {
    return this.http.put(`${this.apiPath}api/User/UpdateUserPrimaryFunctionalRole/${teamId}`, null);
  }

  public UpdateTermsAcceptance(request: GetAcceptRequest) {
    return this.http.put(
      `${this.apiPath}api/User/UpdateUserTermsAcceptance/${request.applicationIdentity}?actionSource=${request.actionSource}`,
      null
    );
  }

  public emailLoginNameValidator(control: FormControl): boolean {
    const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const alphaNumericPattern = /^[a-zA-Z]+[a-zA-Z0-9]*$/;
    const validPattern = /^[a-zA-Z]+$/;
    if (control.value != null && control.value !== '') {
      // begins with character
      if (!validPattern.test(control.value.charAt(0))) {
        return false;
      }
      // is valid email
      if (control.value.includes('@')) {
        if (!emailPattern.test(control.value)) {
          return false;
        }
        return true;
      }
      // has alpha-numeric characters only
      if (!alphaNumericPattern.test(control.value)) {
        return false;
      }
    }
    return true;
  }
}
