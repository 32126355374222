<kendo-formfield>
  <kendo-label [for]="id" [text]="label" *ngIf="label"><span *ngIf="required" class="asterisk"></span></kendo-label>
  <kendo-dropdownlist
    #dropDownList
    [id]="id"
    [formControl]="control"
    [required]="required"
    [size]="size"
    [rounded]="rounded"
    [fillMode]="fillMode"
    [defaultItem]="placeholder"
    [data]="data"
    [textField]="textField"
    [valueField]="valueField"
    [readonly]="readonly"
    [filterable]="filterable"
    [kendoDropDownFilter]="filterSettings"
    [valuePrimitive]="valuePrimitive"
    (valueChange)="onValueChange($event); valueChange.emit($event)">
    <ng-template kendoDropDownListNoDataTemplate>
      <h6>
        <kendo-icon name="warning" class="no-data-icon"></kendo-icon><br /><br />
        {{ noDataMessage }}
      </h6>
    </ng-template>
  </kendo-dropdownlist>
  <kendo-formhint>{{ hint }}</kendo-formhint>
  <kendo-formerror *ngIf="(control.touched || control.dirty) && control.invalid"> {{ control | errorMessage : errors }}</kendo-formerror>
</kendo-formfield>
