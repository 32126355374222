import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConstants } from '../app.constants';

import { IAppSettings } from '../models/app-settings.model';
import { GetSupportDocumentRequest } from '../models/get-support-document-request.model';
import { GetSupportDocumentResponse } from '../models/get-support-document-response.model';

@Injectable({
  providedIn: 'root',
})
export class SupportDocumentService {
  private apiPath: string = ((<any>window)[AppConstants.AppSettings] as IAppSettings).common.userManagementApiPath;

  constructor(private http: HttpClient) {}

  public getSupportDocument(request: GetSupportDocumentRequest): Observable<GetSupportDocumentResponse> {
    return this.http.post<GetSupportDocumentResponse>(`${this.apiPath}api/SupportDocument/Get`, request);
  }
}
