import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { AppConstants } from 'src/app/app.constants';

import { AddGlossaryRequest } from 'src/app/models/add-glossary-request.model';
import { FormFieldError } from 'src/app/models/form-field-error.model';

import { DataDogAction } from 'src/app/core/enums/datadog-action.enum';

import { CommonService } from 'src/app/services/common.service';

import { GlossaryService } from 'src/app/services/glossary.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-upload-glossary',
  templateUrl: './upload-glossary.component.html',
  styleUrls: ['./upload-glossary.component.css'],
})
export class UploadGlossaryComponent implements OnInit {
  @Input() fileType?: string;

  public fileTypeError = '';
  public appConstants = AppConstants;
  public readonly dataDogAction: typeof DataDogAction = DataDogAction;
  public selectedFile: File[] = [];
  public glossaryUploadForm!: FormGroup;
  public readonly glossaryNameControl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(150),
  ]);
  public readonly selectedFileControl: FormControl = new FormControl(0, [Validators.min(1), Validators.max(1)]);
  public readonly jobTypeControl: FormControl = new FormControl(null, [Validators.required, Validators.min(1)]);

  public isSubmitButtonDisabled = false;
  public isProgressBarShown = false;
  private featureTypes: number[] = [...AppConstants.FeatureType.map((item) => item.Id)];
  private selectedFeatureTypes!: number[];

  private _required: string = 'required';
  public readonly glossaryNameError: FormFieldError[] = [{ key: this._required, message: AppConstants.ErrorRequiredGlossaryName }];
  public readonly jobTypeError: FormFieldError[] = [{ key: this._required, message: AppConstants.ErrorRequiredFeatureType }];
  public readonly selectFileError: FormFieldError[] = [{ key: 'min', message: AppConstants.InvalidGlossaryFile }];

  constructor(
    private notificationService: NotificationService,
    private glossaryService: GlossaryService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<UploadGlossaryComponent>,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.glossaryUploadForm = this.formBuilder.group({
      glossaryNameControl: this.glossaryNameControl,
      selectedFileControl: this.selectedFileControl,
      jobTypeControl: this.jobTypeControl,
    });
  }

  public onSelect(event: any): void {
    this.selectedFile.length = 0;
    if (event.addedFiles.length === 0 && event.rejectedFiles.length !== 0) {
      const rejectedFile = event.rejectedFiles[0];
      if (rejectedFile.reason === 'type') {
        this.fileTypeError = AppConstants.ErrorInvalidFileTypeExcel;
      }
      this.selectedFileControl.setValue(0);
      this.selectedFileControl.markAsTouched();
      return;
    }
    this.fileTypeError = '';
    this.selectedFile = event.addedFiles;
    this.selectedFileControl.setValue(1);
  }

  public onRemove(): void {
    this.fileTypeError = '';
    this.selectedFile.length = 0;
    this.selectedFileControl.setValue(0);
    this.selectedFileControl.markAsTouched();
  }

  public onSubmit(): void {
    this.commonService.showSpinner.next(true);
    this.isProgressBarShown = true;
    this.isSubmitButtonDisabled = true;
    this.selectedFeatureTypes = this.jobTypeControl.value.filter((g: number) => g !== 100);
    const request: AddGlossaryRequest = {
      glossaryName: this.glossaryNameControl.value,
      featureTypes: this.selectedFeatureTypes,
    };
    const formData = new FormData();
    if (this.selectedFile[0]) {
      formData.append('file', this.selectedFile[0], this.selectedFile[0].name);
    }
    formData.append('data', JSON.stringify(request));
    this.glossaryService.uploadGlossary(formData).subscribe({
      next: () => {
        this.dialogRef.close(true);
        this.commonService.showSpinner.next(false);
        this.isProgressBarShown = false;
        this.notificationService.success('', AppConstants.SuccessCreateGlossary);
      },
      error: () => {
        this.commonService.showSpinner.next(false);
        this.isProgressBarShown = false;
        this.notificationService.error('', AppConstants.ErrorCreateGlossary);
      },
    });
  }

  public onKeyUp(allOption: any, jobTypeSelect: any) {
    const activeMatOption = jobTypeSelect.options.filter((x: any) => x._element.nativeElement.outerHTML.includes('mat-active'))[0];
    if (activeMatOption?.value === 100) {
      this.toggleAllSelection(allOption);
    } else {
      this.togglePerOne(allOption);
    }
  }

  public toggleAllSelection(allOption: any): void {
    if (allOption._selected) {
      this.jobTypeControl.patchValue(this.featureTypes);
      allOption._selected = true;
    } else {
      this.jobTypeControl.patchValue([]);
    }
  }

  public togglePerOne(allOption: any): void {
    if (allOption._selected) {
      allOption.deselect();
      return;
    }
    if (this.jobTypeControl.value.length == AppConstants.FeatureType.length) {
      allOption.select();
      this.jobTypeControl.patchValue(this.featureTypes);
      allOption._selected = true;
    }
  }
  public disabledGlossaryJobtypes(featureType: { Id: number; TypeName: string }) {
    return !(
      featureType.TypeName === this.appConstants.FinancialTableCheckTitle || featureType.TypeName === this.appConstants.SquareBracketCheck
    );
  }
}
