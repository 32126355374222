import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AppConstants } from '../app.constants';

import { BookmarkPageInfo } from '../models/bookmark-page-info.model';
import { BookmarkPageRequest } from '../models/bookmark-page-request.model';
import { FileDownloadDto } from '../models/file-download-dto.model';
import { GetPageDetailRequest } from '../models/get-page-detail-request.model';
import { GetPageDetailResponse } from '../models/get-page-detail-response.model';
import { JobCompareResponse } from '../models/job-compare-response.model';
import { ResetPageInfo } from '../models/reset-page-info.model';
import { ResetPageRequest } from '../models/reset-page-request.model';
import { ViewSourcePdfRequest } from '../models/view-source-pdf-request.model';

@Injectable({
  providedIn: 'root',
})
export class FileCompareService {
  private apiPath = (window as any)[AppConstants.AppSettings].common.apiPath;
  public resetPageSubject = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  public GetSourcePdf(
    jobIdentity: string,
    id: number,
    englishFileName: string,
    englishPageNumber: number,
    chineseFileName: string,
    chinesePageNumber: number,
    featuretypeId: number
  ): Observable<FileDownloadDto> {
    const viewSourcePdfRequest: ViewSourcePdfRequest = {
      jobIdentity,
      id,
      englishFileName,
      englishPageNumber,
      chineseFileName,
      chinesePageNumber,
      featuretypeId,
    };
    return this.http.post<FileDownloadDto>(`${this.apiPath}api/Compare`, viewSourcePdfRequest);
  }

  public SavePage(formData: FormData): Observable<Object> {
    return this.http.post(`${this.apiPath}api/Compare/SavePage`, formData);
  }

  public BookmarkPage(jobIdentity: string, bookmarkPages: BookmarkPageInfo[]): Observable<Object> {
    const bookmarkPageRequest: BookmarkPageRequest = { jobIdentity, bookmarkPages };
    return this.http.post(`${this.apiPath}api/Compare/BookmarkPage`, bookmarkPageRequest);
  }

  public GetPageDetails(request: GetPageDetailRequest): Observable<GetPageDetailResponse> {
    return this.http.post<GetPageDetailResponse>(`${this.apiPath}api/Compare/GetPageDetails`, request);
  }

  public GetJobCompareDetails(jobIdentity: string): Observable<JobCompareResponse> {
    return this.http.get<JobCompareResponse>(`${this.apiPath}api/Job/GetJobCompareDetails/${jobIdentity}`);
  }

  public ResetPageDetails(jobIdentity: string, resetPages: ResetPageInfo[]): Observable<Object> {
    const resetPageRequest: ResetPageRequest = { resetPages, jobIdentity };
    return this.http.post(`${this.apiPath}api/Compare/ResetPageDetails`, resetPageRequest);
  }

  public SaveIgnoredRegion(fileIdentity: number, ignoredRegion: string): Observable<Object> {
    return this.http.post(`${this.apiPath}api/Compare/SaveIgnoredRegion?id=${fileIdentity}&ignoredRegion=${ignoredRegion}`, null);
  }

  public formatFinCheckTotal(number: number, hasNonPercentageValues: boolean = true, locale = 'en-US'): string {
    const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 0, maximumFractionDigits: 10 });
    const formattedNumber = formatter.format(number);
    return hasNonPercentageValues ? formattedNumber : formattedNumber + '%';
  }
}
