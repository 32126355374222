import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { DialogRef } from '@progress/kendo-angular-dialog';

import { AppConstants } from 'src/app/app.constants';

import { AnnotationDetailsDto } from 'src/app/models/annotation-details-dto.model';
import { SelectedAnnotationType } from 'src/app/models/selected-annotation-type.model';

import { AnnotationType } from 'src/app/core/enums/annotation-type.enum';
import { FormFieldError } from 'src/app/models/form-field-error.model';

@Component({
  selector: 'app-lqa-edit-dialog',
  templateUrl: './add-edit-error-category-dialog.component.html',
})
export class AddEditErrorCategoryDialogComponent {
  public constants: typeof AppConstants = AppConstants;
  public selectedAnnotation!: SelectedAnnotationType;

  public isSubmitClicked: boolean = false;
  public isUpdateLqa: boolean = false;
  public annotationIdentity?: number;
  public oldLqaData!: string;
  public isOthersSelected: boolean = false;

  public errorCategoryData = AppConstants.ErrorCategoryData;

  public subErrorCategoryData: any[] = [];

  public severityData = AppConstants.SeverityData;

  public readonly errorCategorydefaultValue = { id: 0, name: 'Select' };
  public readonly subErrorCategorydefaultValue = { id: 0, name: 'Select' };
  public readonly severitydefaultValue = { id: null, name: 'Select' };

  public readonly errorCategoryControl: FormControl = new FormControl('', Validators.required);
  public readonly subErrorCategoryControl: FormControl = new FormControl({ value: '', disabled: true }, Validators.required);
  public readonly othersControl: FormControl = new FormControl({ value: null, disabled: true }, Validators.maxLength(500));
  public readonly severityControl: FormControl = new FormControl('');

  private readonly _required: string = 'required';

  public readonly othersErrors: FormFieldError[] = [
    { key: 'maxlength', message: AppConstants.MaxLengthValidationError(500) },
    { key: this._required, message: AppConstants.ErrorTextBoxRequired(AppConstants.OthersLabel) },
  ];

  public readonly errorCategoryErrors: FormFieldError[] = [
    { key: this._required, message: AppConstants.ErrorDropDownSelectRequired(AppConstants.ErrorCategoryLabel) },
  ];

  public readonly subErrorCategoryErrors: FormFieldError[] = [
    { key: this._required, message: AppConstants.ErrorDropDownSelectRequired(AppConstants.SubErrorCategoryLabel) },
  ];

  public readonly lqaForm: FormGroup = new FormGroup({
    category: this.errorCategoryControl,
    subCategory: this.subErrorCategoryControl,
    otherText: this.othersControl,
    severity: this.severityControl,
  });

  constructor(public dialogRef: DialogRef) {}

  public onSubmit(): void {
    this.isSubmitClicked = true;
    const annotationLqaRequest: AnnotationDetailsDto = {
      annotationId: this.selectedAnnotation.annotationId,
      annotationIdentity: this.annotationIdentity ? this.annotationIdentity : 0,
      annotationTypeId: +AnnotationType[this.selectedAnnotation.annotationType as any],
      category: this.errorCategoryControl.value,
      subCategory: this.subErrorCategoryControl.value,
      otherText: this.othersControl.value ? this.othersControl.value : null,
      severity: this.severityControl.value,
      dateTime: new Date().toISOString()
    };
    this.dialogRef.close({ text: AppConstants.SubmitLabel, data: annotationLqaRequest });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public errorCategoryChange(value: any) {
    this.subErrorCategoryControl.reset();
    this.othersControl.reset();
    this.othersControl.disable();
    this.isOthersSelected = false;
    if (value) {
      this.subErrorCategoryControl.enable();
    } else {
      this.subErrorCategoryControl.disable();
    }
    switch (value) {
      case 1:
        this.subErrorCategoryData = AppConstants.AccuracySubErrorCategoryData;
        break;
      case 2:
        this.subErrorCategoryData = AppConstants.FluencySubErrorCategoryData;
        break;
      case 3:
        this.subErrorCategoryData = AppConstants.TextStyleSubErrorCategoryData;
        break;
      case 4:
        this.subErrorCategoryData = AppConstants.FormatSubErrorCategoryData;
        break;
      case 5:
        this.subErrorCategoryData = AppConstants.OthersSubErrorCategoryData;
        break;
    }
  }

  public subErrorCategoryChange(value: any) {
    this.isOthersSelected = this.subErrorCategoryData.filter((x) => x.id === value)[0]?.name === 'Others';
    if (this.isOthersSelected) {
      this.othersControl.enable();
    } else {
      this.othersControl.reset();
      this.othersControl.disable();
    }
  }

  public processLqaEditDialog(lqaData: AnnotationDetailsDto) {
    this.oldLqaData = JSON.stringify({
      category: lqaData.category,
      subCategory: lqaData.subCategory,
      otherText: lqaData.otherText,
      severity: lqaData.severity,
    });
    this.isUpdateLqa = true;
    this.annotationIdentity = lqaData.annotationIdentity;
    this.errorCategoryControl.setValue(lqaData.category);
    this.errorCategoryChange(lqaData.category);
    this.subErrorCategoryControl.setValue(lqaData.subCategory);
    if (this.subErrorCategoryData.filter((x) => x.id === lqaData.subCategory)[0].name === 'Others') {
      this.isOthersSelected = true;
      this.othersControl.enable();
      this.othersControl.setValue(lqaData.otherText);
    }
    this.severityControl.setValue(lqaData.severity);
  }

  public submitButtonDisabled(): boolean {
    return this.lqaForm.invalid || this.isSubmitClicked || this.oldLqaData === JSON.stringify(this.lqaForm.getRawValue());
  }
}
