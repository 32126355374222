<div class="top-container">
  <app-dashboard-header></app-dashboard-header>

  <div class="parent-container">
    <div class="glossary-header-div">
      <div class="glossarylist-div">
        <label>{{ appConstants.GlossaryListTitle }}</label>
        <mat-form-field class="search">
          <input
            matInput
            [formControl]="glossarySearchControl"
            (keyup)="filterGlossaryData()"
            [placeholder]="appConstants.SearchPlaceholderTitle" />
        </mat-form-field>
      </div>
      <div class="btn-div">
        <button
          [attr.data-dd-action-name]="appConstants.JobListLabel"
          class="btn upload-glossary-btn back-btn"
          (click)="navigateToHomePage()">
          <p>{{ appConstants.JobListLabel }}</p>
          <p class="material-icons btnIcon">home</p>
        </button>
        <button [attr.data-dd-action-name]="appConstants.UploadGlossaryLabel" class="btn upload-glossary-btn" (click)="openDialog()">
          <p>{{ appConstants.UploadGlossaryLabel }}</p>
          <p class="material-icons btnIcon">cloud_upload</p>
        </button>
        <button
          [attr.data-dd-action-name]="'Glossary List Refresh'"
          mat-icon-button
          [matTooltip]="appConstants.RefreshGlossaryListTitle"
          class="refresh-btn"
          (click)="getGlossary()"
          onclick="this.blur()"
          aria-label="refresh">
          <mat-icon>autorenew</mat-icon>
        </button>
      </div>
    </div>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="GlossaryID">
        <mat-header-cell *matHeaderCellDef>{{ appConstants.GlossaryIdLabel }} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element?.glossaryId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>{{ appConstants.GlossaryNameLabel }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element?.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="FeatureType">
        <mat-header-cell *matHeaderCellDef>{{ appConstants.FeatureTypeLabel }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element?.featureType }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="FileName">
        <mat-header-cell *matHeaderCellDef> {{ appConstants.GlossaryFileNameLabel }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a
            [attr.data-dd-action-name]="glossaryDownload"
            (click)="openFile(element?.filePath, element?.fileName)"
            [matTooltip]="element?.fileName"
            class="cursor">
            <span class="cell_text">{{ element?.fileName }}</span>
            <span class="material-icons-outlined cell_icon"> cloud_download </span>
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef>{{ appConstants.StatusLabel }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [ngClass]="getGlossaryStatus(element?.status)" class="cell_text">{{ element?.glossaryStatus }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CreatedDate">
        <mat-header-cell *matHeaderCellDef>{{ appConstants.CreationDateLabel }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element?.createdDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell *matHeaderCellDef> {{ appConstants.ActionLabel }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            [attr.data-dd-action-name]="appConstants.DeleteGlossaryLabel"
            mat-icon-button
            (click)="deleteGlossary(element?.glossaryId)">
            <mat-icon matTooltip="{{ appConstants.DeleteGlossaryLabel }}">delete_forever</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <div class="no-data" *ngIf="isNoData">
      <p>{{ appConstants.NoDataFound }}</p>
    </div>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>

  <app-dashboard-footer></app-dashboard-footer>
</div>
