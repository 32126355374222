import { Injectable } from '@angular/core';

import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { User } from '@datadog/browser-core';

import { AppConstants } from '../app.constants';

import { IAppSettings } from '../models/app-settings.model';
import { UserInfo } from '../models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class DataDogService {
  appConstant: typeof AppConstants = AppConstants;

  public static initDataDogRum(settings: IAppSettings): void {
    datadogRum.init(DataDogService.getDataDogRumInitConfiguration(settings));
  }

  private static getDataDogRumInitConfiguration(settings: IAppSettings): RumInitConfiguration {
    return {
      applicationId: settings.dataDog.applicationId,
      clientToken: settings.dataDog.clientToken,
      site: settings.dataDog.site,
      service: settings.dataDog.service,
      env: settings.dataDog.environmentName,
      version: settings.common.applicationVersion,
      allowedTracingUrls: [settings.common.apiPath, settings.common.userManagementApiPath],
      sessionSampleRate: settings.dataDog.sessionSampleRate,
      sessionReplaySampleRate: settings.dataDog.sessionReplaySampleRate,
      trackUserInteractions: settings.dataDog.trackUserInteractions,
      trackResources: settings.dataDog.trackResources,
      trackLongTasks: settings.dataDog.trackLongTasks,
      defaultPrivacyLevel: settings.dataDog.defaultPrivacyLevel,
    };
  }

  public static intDataDogLogs(settings: IAppSettings): void {
    datadogLogs.init(DataDogService.getDataDogLogsInitConfiguration(settings));
  }

  private static getDataDogLogsInitConfiguration(settings: IAppSettings): LogsInitConfiguration {
    return {
      clientToken: settings.dataDog.clientToken,
      site: settings.dataDog.site,
      service: settings.dataDog.service,
      env: settings.dataDog.environmentName,
      version: settings.common.applicationVersion,
      forwardErrorsToLogs: settings.dataDog.forwardErrorsToLogs,
      forwardConsoleLogs: settings.dataDog.forwardConsoleLogs,
      forwardReports: settings.dataDog.forwardReports,
      sessionSampleRate: settings.dataDog.sessionSampleRate,
    };
  }

  public static setUser(user: UserInfo) {
    datadogRum.setUser(DataDogService.getDataDogUserConfiguration(user));
  }

  private static getDataDogUserConfiguration(user: UserInfo): User {
    return {
      id: user.userObjectId,
      email: user.emailAddress,
      name: user.displayName,
      loginName: user.loginName,
      userTypeId: user.userTypeId,
      appRoleNames: user.appRoleNames,
      companyName: user.companyName,
      vendorName: user.vendorName,
      isInternalUser: user.isInternalUser,
      isExternalUser: user.isExternalUser,
      isAdminUser: user.isAdmin,
      isClientUser: user.isClientUser,
      isVendorUser: user.isVendorUser,
      primaryFunctionalRoleIdentity: user.primaryFunctionalRoleIdentity,
      primaryFunctionalRoleName: user.primaryFunctionalRoleName,
    };
  }

  public static addAction(name: string, context?: object | undefined): void {
    datadogRum.addAction(name, context);
  }

  public static setUserProperty(key: string, value: any): void {
    datadogRum.setUserProperty(key, value);
  }

  public static removeUserProperty(key: any): void {
    datadogRum.removeUserProperty(key);
  }

  public static removeGlobalContextProperty(key: string): void {
    datadogRum.removeGlobalContextProperty(key);
  }

  public static removeDataDogContextProperties() {
    // remove data dog global context properties
    datadogRum.removeGlobalContextProperty('isEdit');
    datadogRum.removeUserProperty(AppConstants.JobId);
    datadogRum.removeUserProperty(AppConstants.GlossaryId);
    datadogRum.removeUserProperty('fileName');
    datadogRum.removeUserProperty('jobIdentity');
    datadogRum.removeUserProperty('englishFileId');
    datadogRum.removeUserProperty('chineseFileId');
    datadogRum.removeUserProperty('fileTypeId');
  }

  public static logDownloadFile(id: string, fileName: string, isGlossaryId: boolean = false) {
    datadogRum.setUserProperty(isGlossaryId ? AppConstants.GlossaryId : AppConstants.JobId, id);
    datadogRum.setUserProperty('fileName', fileName);
  }

  public static logComparePageDetails(jobId: number, fileId1: number, fileId2: number, typeId: number) {
    datadogRum.setUserProperty('jobIdentity', jobId);
    datadogRum.setUserProperty('englishFileId', fileId1);
    datadogRum.setUserProperty('chineseFileId', fileId2);
    datadogRum.setUserProperty('fileTypeId', typeId);
  }
}
