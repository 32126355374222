import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PopupSettings } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './kendo-dropdown-button.component.html',
})
export class KendoDropdownButtonComponent {
  @Input() label: string = '';
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Input() icon: string = '';
  @Input() toolTip: string = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() rounded: 'small' | 'medium' | 'large' | 'full' = 'medium';
  @Input() fillMode: 'solid' | 'flat' | 'outline' | 'clear' | 'link' = 'solid';
  @Input() themeColor: 'base' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' = 'base';
  @Input() disabled: boolean = false;
  @Input() data: any[] = [];
  @Input() popupSettings: PopupSettings = { align: 'right' };
  @Output() itemClicked = new EventEmitter<any>();
  public itemName: string = '';

  onItemClick(event: any) {
    this.itemName = event;
    this.itemClicked.emit(event);
  }
}
