import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';

import {
  AccountInfo,
  AuthError,
  AuthenticationResult,
  CacheLookupPolicy,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  SilentRequest,
} from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import dayjs from 'dayjs/esm';
import DayJSUtc from 'dayjs/esm/plugin/utc';
import tz from 'dayjs/esm/plugin/timezone';

import { AppConstants } from './app.constants';

import { IAppSettings } from './models/app-settings.model';

import { ActionType } from './core/enums/action-type.enum';
import { SignalRConnectionStatus } from './core/enums/signalr-connection-status.enum';

import { AppDataService } from './services/app-data.service';
import { DataDogService } from './services/data-dog.service';
import { DialogService as kendoDialogService } from '@progress/kendo-angular-dialog';
import { LogService } from './services/log.service';
import { SignalRService } from './services/signalr.service';
import { UserService } from 'src/app/services/user.service';

import { AlertDialogComponent as AlertDialog } from 'src/app/shared/alert-dialog/alert-dialog.component';
import { ValidUserDetailsResponse } from './models/valid-user-details-response.model';
import { CommonService } from './services/common.service';
import { ReadAcceptSectionComponent } from './components/read-accept-section/read-accept-section.component';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { DialogService } from './services/dialog.service';
import { AppList } from './core/enums/application-type.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private appSettings: IAppSettings = (window as any)[AppConstants.AppSettings];
  public applicationTitle = '';
  public appLoaded: boolean = false;
  public userProfileLoadedSubject = new BehaviorSubject<boolean>(false);
  private readonly onDestroy = new Subject<void>();
  private subscription!: Subscription;
  private signalrConnectionSubscription?: Subscription;
  public isLoginSuccess: boolean = false;
  public is2faSuccess: boolean = false;
  public isRefreshFrom2FAPage: boolean = false;
  public readAccept!: DialogRef;
  public isReadAcceptOpened: boolean = false;
  @ViewChild('dialogContainer', { read: ViewContainerRef })
  public containerRef: ViewContainerRef | undefined;

  constructor(
    private signalRService: SignalRService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    public appDataService: AppDataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private userService: UserService,
    private commonService: CommonService,
    private kendoDialogService: kendoDialogService
  ) {
    this.matIconRegistry.addSvgIcon('pdf-viewer', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/pdf-viewer.svg'));
    this.matIconRegistry.addSvgIcon('pdf-viewer-white', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/pdf-viewer-white.svg'));
  }

  async ngOnInit(): Promise<void> {
    dayjs.extend(DayJSUtc);
    dayjs.extend(tz);
    this.applicationTitle = this.appSettings?.common.applicationName;
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        if (val.state.root.firstChild?.queryParams['id']?.length > 0) {
          localStorage.setItem(AppConstants.User2FAverifyFromTera, 'true');
        }
      }
    });
    if (
      (localStorage.getItem(AppConstants.User2FAIsClientUser) == null ||
        sessionStorage.getItem(AppConstants.InactiveSession) == null ||
        localStorage.getItem(AppConstants.ReadAcceptClientUser) == null ||
        localStorage.getItem(AppConstants.ReadAcceptClientUser) == 'false') &&
      this.IsActiveAccount()
    ) {
      this.userService
        .IsValidUser(this.authService.instance.getActiveAccount()!.username)
        .subscribe(async (res: ValidUserDetailsResponse) => {
          if (localStorage.getItem(AppConstants.User2FAIsClientUser) == 'false' && res.isMfaEnabled) {
            this.commonService.logout();
          }
          sessionStorage.setItem(AppConstants.InactiveSession, 'yes');
          localStorage.setItem(AppConstants.User2FAIsClientUser, res.isMfaEnabled.toString());
          localStorage.setItem(AppConstants.ReadAcceptClientUser, res.termsAcceptance.toString());
          if (localStorage.getItem(AppConstants.ReadAcceptClientUser) == 'false') {
            this.openReadAcceptDialog();
          } else {
            await this.initializeApp();
          }
          if (res.isMfaEnabled) {
            this.logoutIfUser2FAverifyIsNull();
          }
        });
    } else if (localStorage.getItem(AppConstants.User2FAIsClientUser) == 'true' && this.IsActiveAccount()) {
      this.logoutIfUser2FAverifyIsNull();
      await this.initializeApp();
    } else if (localStorage.getItem(AppConstants.ReadAcceptClientUser) == 'true' || !this.isLoggedIn()) {
      await this.initializeApp();
    }
    if (this.getIsUserAccessRevoked()) {
      this.ShowInfoMessage(AppConstants.ErrorRevokeUserAccess);
    }

    this.subscribeSignalRMessages();
    this.appDataService.userProfileLoadedSubject.subscribe({
      next: (loaded: boolean) => {
        if (loaded && this.appSettings.dataDog.applicationId !== '') {
          DataDogService.setUser(this.appDataService?.user);
        }
      },
      error(err) {
        console.log('Error', err);
      },
    });
  }

  ngAfterViewInit(): void {
    this.signalrConnectionSubscription = this.signalRService.connectionStateSubject.subscribe(() => {
      this.joinSignalRGroup();
    });
  }

  async ngOnDestroy(): Promise<void> {
    await this.signalRService.stopConnection();
    this.onDestroy.next(undefined);
    this.onDestroy.complete();
    this.subscription.unsubscribe();

    if (this.appDataService.user?.userObjectId) {
      this.signalRService.leaveGroup(`user_msg_${this.appDataService.user?.userObjectId}`);
    }
    this.signalRService.hubConnection.off(AppConstants.SignalRMethods.broadcastMessage);
    if (this.signalrConnectionSubscription) {
      this.signalrConnectionSubscription.unsubscribe();
    }
  }

  private logoutIfUser2FAverifyIsNull() {
    if (localStorage.getItem(AppConstants.User2FAverify) == null || localStorage.getItem(AppConstants.User2FAverify) == 'true') {
      this.commonService.logout();
      this.isRefreshFrom2FAPage = true;
    }
  }

  private joinSignalRGroup() {
    if (this.signalRService.connectionState === SignalRConnectionStatus.Connected && this.appDataService.user?.userObjectId) {
      this.signalRService.joinGroup(`user_msg_${this.appDataService.user?.userObjectId}`);
    }
    if (this.signalRService.connectionState === SignalRConnectionStatus.Connected && this.appDataService.user?.companyIdentity) {
      this.signalRService.joinGroup(`company_msg_${this.appDataService.user?.companyIdentity}`);
    }
  }

  private subscribeSignalRMessages(): void {
    this.signalRService.hubConnection.on(AppConstants.SignalRMethods.broadcastMessage, (signalrData: any) => {
      if (signalrData.actionType === ActionType.RevokeUserAccess) {
        this.appLoaded = false;
        localStorage.setItem(AppConstants.ShowAccessRevokedAlert, AppConstants.ShowAccessRevokedAlert);
        this.ShowInfoMessage(signalrData.message);
      }
    });
    this.signalRService.hubConnection.on(AppConstants.SignalRMethods.companyUpdate, (signalrData: any) => {
      if (signalrData.actionType === ActionType.RevokeCompanyAccess && !this.appDataService.user?.isAdmin) {
        localStorage.setItem(AppConstants.ShowAccessRevokedAlert, AppConstants.ShowAccessRevokedAlert);
        this.ShowInfoMessage(AppConstants.ErrorRevokeUserAccess);
      }
    });
  }

  private async initializeApp(): Promise<void> {
    window.addEventListener('storage', (event) => {
      if (event.key == AppConstants.UserAccessRevoked) {
        this.commonService.logout();
        localStorage.removeItem(AppConstants.UserAccessRevoked);
      }
    });
    this.appDataService.userProfileLoaded = false;
    this.appDataService.userProfileLoadedSubject.next(false);
    const isClientUser2FAChecked =
      localStorage.getItem(AppConstants.User2FAIsClientUser) == 'true' ? localStorage.getItem(AppConstants.User2FAverify) == 'false' : true;
    if (this.IsActiveAccount() && localStorage.getItem(AppConstants.ReadAcceptClientUser) == 'true' && isClientUser2FAChecked) {
      this.appDataService.isAuthenticated = true;
      await this.getAppData();
    } else {
      this.appDataService.isAuthenticated = false;
    }
    //InProgress with MSAL.js V2
    this.broadcastService.inProgress$.pipe(takeUntil(this.onDestroy)).subscribe({
      next: (status: InteractionStatus) => {
        if (status == InteractionStatus.None && !this.isRefreshFrom2FAPage) {
          this.appLoaded = true;
        } else if (
          status == InteractionStatus.HandleRedirect ||
          status == InteractionStatus.Logout ||
          status == InteractionStatus.SsoSilent
        ) {
          this.appLoaded = false;
        }
        if (status == InteractionStatus.None) {
          this.isRefreshFrom2FAPage = false;
        }
      },
    });
    //After Authenticated with MSAL.js V2
    this.broadcastService.msalSubject$.pipe(takeUntil(this.onDestroy)).subscribe({
      next: async (result: EventMessage) => {
        if (result.eventType === EventType.LOGIN_SUCCESS || result.eventType === EventType.SSO_SILENT_SUCCESS) {
          const payload: AuthenticationResult = result.payload as AuthenticationResult;
          // Get new token instead of cache and Set Active account in  acquire token call.
          // this.authService.instance.setActiveAccount(payload.account);
          this.authService
            .acquireTokenSilent({
              account: payload.account,
              scopes: [this.appSettings.azure.ad.scope],
              forceRefresh: false,
              cacheLookupPolicy: CacheLookupPolicy.Skip,
            } as SilentRequest)
            .subscribe({
              next: (response) => {
                this.authService.instance.setActiveAccount(response.account!);
                this.appLoaded = true;
                this.appDataService.isAuthenticated = true;
                if (localStorage.getItem(AppConstants.User2FAIsClientUser) == 'true') {
                  localStorage.removeItem(AppConstants.User2FAverifyMailSent);
                  localStorage.setItem(AppConstants.User2FAverify, 'true');
                  this.isLoginSuccess = true;
                } else if (localStorage.getItem(AppConstants.ReadAcceptClientUser) != 'true') {
                  this.openReadAcceptDialog();
                } else {
                  this.setActiveAccount(response.account!);
                }
              },
              error: (error) => {
                LogService.Debug(error);
                if (error instanceof InteractionRequiredAuthError || error instanceof AuthError) {
                  if (payload.account) {
                    this.setActiveAccount(payload.account);
                  }
                }
              },
            });
        } else if (result.eventType === EventType.LOGIN_FAILURE || result.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          this.appDataService.isAuthenticated = false;
          await this.signalRService.stopConnection();
          this.appLoaded = true;
        } else if (result.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START || result.eventType === EventType.SSO_SILENT_START) {
          this.appLoaded = false;
        } else if (result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          this.appLoaded = true;
        } else if (result.eventType === EventType.RESTORE_FROM_BFCACHE) {
          if (!this.isLoggedIn()) {
            window.location.reload();
          }
        }
      },
    });
  }

  private IsActiveAccount(): boolean {
    return this.authService.instance.getActiveAccount() !== null;
  }
  public async onCheck2faSuccess(event: any) {
    this.is2faSuccess = event;
    if (this.is2faSuccess) {
      this.isLoginSuccess = false;
      if (localStorage.getItem(AppConstants.ReadAcceptClientUser) != 'true') {
        this.openReadAcceptDialog();
      } else {
        this.initializeApp();
      }
    }
  }

  private openReadAcceptDialog(fromAppData: boolean = false) {
    this.isReadAcceptOpened = true;
    this.readAccept = this.kendoDialogService.open({
      content: ReadAcceptSectionComponent,
      width: '870px',
      cssClass: 'read-accept-dialog',
      appendTo: this.containerRef,
      preventAction: () => {
        return true;
      },
    });
    this.readAccept.result.subscribe(async (result: any) => {
      if (result) {
        this.isReadAcceptOpened = false;
        localStorage.setItem(AppConstants.ReadAcceptClientUser, 'true');
        if (!fromAppData) {
          await this.initializeApp();
        }
      }
    });
  }

  private async setActiveAccount(account: AccountInfo) {
    this.authService.instance.setActiveAccount(account);
    this.appDataService.isAuthenticated = true;
    await this.getAppData();
    this.appLoaded = true;
  }

  public isLoggedIn(): boolean {
    return this.appDataService.isAuthenticated;
  }

  private async getAppData(): Promise<void> {
    if (this.isLoggedIn() && !this.isLoginSuccess) {
      this.appLoaded = true;
      await this.appDataService
        .LoadAppData()
        .then(async () => {
          LogService.Debug(this.appDataService.user?.userObjectId);
          if (
            this.appDataService.user?.termsAcceptance !== AppList.ACS &&
            this.appDataService.user?.termsAcceptance !== AppList['Tera Digital & ACS'] &&
            this.appDataService.user?.isClientUser
          ) {
            localStorage.removeItem(AppConstants.ReadAcceptClientUser);
            this.openReadAcceptDialog(true);
          }
          await this.signalRService.startConnection();
          this.appLoaded = true;
          this.appDataService.userProfileLoaded = true;
          this.appDataService.userProfileLoadedSubject.next(true);
          const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
          if (returnUrl) {
            this.router.navigateByUrl(returnUrl);
            return;
          }
          if (window.location.pathname == '/') {
            this.router.navigate(['/jobs']);
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            this.router.navigate([AppConstants.AppRoutes.error_forbidden]);
          }
        });
    }
  }

  public hasRouteGuard(): boolean {
    return this.router.config
      .filter((x) => !x.canActivate)
      .map((x) => x.path)
      .includes(this.router.url.split('/')[1]);
  }

  private getIsUserAccessRevoked() {
    return (
      localStorage.getItem(AppConstants.ShowAccessRevokedAlert) == AppConstants.ShowAccessRevokedAlert &&
      localStorage.getItem(AppConstants.UserAccessRevoked) == null
    );
  }

  private ShowInfoMessage(msg: string) {
    const dialogRef = this.dialogService.open(AlertDialog, AppConstants.UserRevokeDialogOptions(msg));
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.appLoaded = true;
        localStorage.setItem(AppConstants.UserAccessRevoked, AppConstants.UserAccessRevoked);
        localStorage.removeItem(AppConstants.ShowAccessRevokedAlert);
        this.authService
          .acquireTokenSilent({
            scopes: [this.appSettings.azure.ad.scope],
            forceRefresh: false,
            cacheLookupPolicy: CacheLookupPolicy.Skip,
          } as SilentRequest)
          .subscribe({
            next: (result) => {
              this.authService.instance.setActiveAccount(result.account);
              this.appLoaded = true;
            },
          });
        this.commonService.logout();
      }
    });
  }
}
