<div class="parent-container">
  <app-dashboard-header></app-dashboard-header>
  <div class="content">
    <br />
    <br />
    <p class="title">
      Privacy Notice
      <app-icon-button
        [attr.data-dd-action-name]="'Close On Privacy Notice'"
        class="close-btn"
        icon="clear"
        tooltip="Close"
        (clicked)="close()"></app-icon-button>
    </p>
    <h4>Privacy Notice</h4>
    <p>
      This Web site (“Web site”) is operated by TOPPAN Nexus Limited (“TOPPAN Nexus”). The terms “ACS”, “TOPPAN Nexus,” “we,” “us,” “our”
      and “ours” when used in this privacy notice (“Privacy Notice”) includes subsidiaries, divisions, branches, affiliates or companies
      under the control of TOPPAN Nexus. The terms “you,” “your” and “yours” when used in this Privacy Notice means any user of this Web
      site.
    </p>
    <p>
      IMPORTANT: BY SUBMITTING PERSONAL DATA TO US AND/OR BY USING OUR WEB SITE, YOU GIVE YOUR CONSENT THAT ALL PERSONAL DATA THAT YOU
      SUBMIT MAY BE PROCESSED BY US IN THE MANNER AND FOR THE PURPOSES DESCRIBED BELOW.
    </p>
    <h4>Scope of Privacy Notice</h4>
    <p>
      This Privacy Notice describes our current policies and practices with regard to personal data collected by us from you directly and/or
      through the Web site or Online Services (as defined below). The term “personal data” refers to personally identifiable information
      about you. This Privacy Notice applies only to operation of ACS that directly link to this notice when you click on the Privacy Notice
      link.
    </p>
    <h4>Online Service</h4>
    <p>
      ACS products and services are provided as part of a business relationship. If you have been invited to ACS, your profile has been
      lawfully provided to us. By accepting the invitation to ACS, you are agreeing to the use of your personal data in accordance with this
      Privacy Notice. If you request access to ACS, you are agreeing to the use of your personal data in accordance with this Privacy
      Notice. We may contact you regarding updates, modifications and additional service offerings. Your access to ACS will be retained and
      used for support, transition and maintenance, as well as to comply with reporting, audit and data retention requirements.
    </p>
    <h4>Notification of changes to this Notice</h4>
    <p>
      ACS is continually improving our methods of communication and adding new functionality and features to this Web site and to our
      existing services. Because of these ongoing changes, changes in the law and the changing nature of technology, our data practices may
      change and we reserve the right to make changes to this Privacy Notice at any time. You agree to be bound by the prevailing terms of
      this Privacy Notice as updated from time to time. We encourage you to check this page frequently and any time you submit personal
      information via the Web site to see any such updates or changes.
    </p>
    <h4>Collection, Use and Disclosure of your personal data</h4>
    <h4>TOPPAN Nexus as a Data Processor</h4>
    <p>
      TOPPAN Nexus may also process personal data relating to your use of ACS. Such data will be used to operate ACS, and provide analytics
      and reporting information to TOPPAN Nexus's clients and may be disclosed as required by law.
    </p>
    <p>
      TOPPAN Nexus does not control such data; rather it handles this type of data as a data processor only. ACS's clients are responsible
      for individual notification of purpose and for obtaining appropriate consent when they collect personal data. Personal data that is
      transferred to TOPPAN Nexus by its clients to be processed shall be deemed to have been collected with appropriate notification. ACS
      assumes no responsibility for obtaining or validating that appropriate consent has been obtained with respect to data provided by its
      client(s).
    </p>
    <h4>TOPPAN Nexus as a Data User</h4>
    <p>When you use our products and services or request information, we request personal data about you as follows:</p>
    <ol type="i">
      <li>Name;</li>
      <li>Job Title;</li>
      <li>Contact information, e.g. email address and telephone number(s);</li>
      <li>Country and city;</li>
      <li>Web browser type and version (automatically collected);</li>
      <li>Operating system (automatically collected);</li>
      <li>
        Text and translation – Process texts and documents uploaded by users to train and improve translation algorithms and neural network;
      </li>
      <li>Other usage information, e.g. IP address</li>
    </ol>
    <p class="space-before">
      in each case, in accordance with this Data Privacy Notice. Such personal data is required in order to use our products and services.
    </p>
    <p>We collect personal data from the following ways:</p>
    <ol type="i">
      <li>Contact information</li>
      <li>Billing information</li>
      <li>ACS collects information automatically, e.g. usage data</li>
    </ol>
    <p>
      We may also ask for demographic information to enable us to provide you with a personalized service. The information you provide is
      either manually or electronically stored in our databases. We may share your personal data under these limited circumstances shown
      below, and you acknowledge and agree that ACS may do so for these purposes:
    </p>
    <ul>
      <li>
        In order to provide you with the requested information on products or services, your personal data may be shared with other
        companies within our group of companies and third parties who act for us for further processing in accordance with the purposes for
        which the data was originally collected or for purposes to which you have consented. For example, sometimes a third party may have
        access to your personal data in order to support our information technology, to supplement, update, or correct the data or provide
        additional publicly available data, or to handle mailings on our behalf. TOPPAN Nexus requires that all third-party processors
        process your personal data with the same level of protection and in a manner consistent with the uses agreed upon in this Notice.
      </li>
      <li>
        We will keep your contact details on our database and we may use your personal data for financial, marketing purposes and market
        research, including but not limited to users' eligibility for product licenses and any billing documents in connection with users'
        purchases. If you do not wish to receive e-mails or post from us for these purposes, please let us know by following the
        “unsubscribe” procedure described herein.
      </li>
      <li>We may use your personal data to administer this Web site and help us improve our services or system</li>
      <li>We may use your personal data to help create new features, tools and products in this Web site.</li>
      <li>
        We may disclose the information in our databases and server logs to comply with a legal requirement, for the administration of
        justice, for the purposes of national security only to the extent that is strictly necessary and proportionate, to interact with
        anti-fraud databases, to protect your vital interests, to protect the security or integrity of our databases or this Web site, to
        take precautions against legal liability, or in the event of our sale, merger, reorganization, dissolution or similar event.
      </li>
    </ul>
    <h4>Retention</h4>
    <p>
      TOPPAN Nexus retains personal data only as long as necessary to fulfill the stated purposes or as legally required and thereafter
      securely destroys such information.
    </p>
    <p>
      When personal data is no longer necessary or relevant for the stated purpose or to fulfill a legal or business requirement, it shall
      be securely destroyed. TOPPAN Nexus will either physically or electronically erase the personal data.
    </p>
    <h4>Data Security</h4>
    <p>
      We will take every sensible precaution to protect the information we collect from you including physical, technical and organizational
      procedures to safeguard and secure the information from loss, misuse, unauthorized access or disclosure. Access to this information is
      protected both online and off-line.
    </p>
    <h4>User's Protection rights</h4>
    <p>Regarding the personal data we collect and use, you have the right to:</p>
    <ul>
      <li>Request your personal data is erased where it is no longer legally necessary for ACS to retain such data</li>
      <li>Require us to change or correct any of the personal data where it is no longer accurate or is outdated</li>
      <li>Require ACS to provide you, your personal data that we are storing and processing</li>
      <li>Withdraw your consent to the processing or transfer of your personal data at any time</li>
    </ul>
    <p>
      To exercise your rights to data rectification, erasure and/or access, please email
      <a target="_blank" href="mailto:acsnotification@toppannexus.com">acsnotification@toppannexus.com</a>.
    </p>
    <h4>Anonymous data collected through this Web site</h4>
    <p>
      In addition to the information we collect as described above, we use technology to collect anonymous information about the use of our
      Web site. For example, our Web server automatically logs which pages of our Web site our visitors view, their IP addresses and which
      Web browsers our visitors use. This technology does not identify you personally; it enables us to compile statistics about our
      visitors and their use of our Web site. Our Web site contains hyperlinks to other pages on our Web site. We may use technology to
      track how often these links are used and which pages on our Web site our visitors choose to view.
    </p>
    <h4>Cookies</h4>
    <p>
      In order to collect the anonymous data described herein, we may use temporary “cookies” that remain in the cookies file of your
      browser until the browser is closed. Cookies by themselves cannot be used to discover the identity of the user. A cookie is a small
      piece of information which is sent to your browser and stored on your computer's hard drive. Cookies do not damage your computer. You
      can set your browser to notify you when you receive a cookie. This enables you to decide if you want to accept it or not.
    </p>
    <h4>Data quality, access and corrections</h4>
    <p>
      If ACS collects data outside the delivery of Online Services to its client in accordance with a written agreement in place with the
      client, ACS will make reasonable efforts to keep personal data accurate, complete and up-to-date as is necessary to fulfill the
      purposes for which the information is to be used. Unless ACS is permitted or required by law to prohibit access, upon receipt of your
      written request and by providing enough information to permit us to identify your contact data, we will disclose to you the personal
      data we acquired and hold about you, for which we may make a small charge. Upon request, we will correct, amend or delete any personal
      data that is inaccurate and notify any third-party recipients of the necessary changes. You may update any information you have given
      to us by contacting us at the address given below. Requests to delete personal data are subject to any applicable legal and ethical
      reporting or document retention obligations imposed on us.
    </p>
    <p>
      ACS relies on its clients to supply ACS with accurate, complete and up-to-date information. ACS makes reasonable efforts to maintain
      the integrity of the data within ACS as necessary to fulfill the stated purpose for which the information is used. If a request for
      personal data is submitted to ACS by an individual, ACS, in its role as a Processor (as described above), will direct the individual
      to the client for access.
    </p>
    <h4>Links to other Web sites</h4>
    <p>
      This Web site may contain hyperlinks to Web sites that are not operated by us. These hyperlinks are provided for your reference and
      convenience only and do not imply any endorsement of the activities of these third-party Web sites or any association with their
      operators. We do not control these Web sites and are not responsible for their operation, data or privacy practices. We urge you to
      review any privacy notice posted on any site you visit before using the site or providing any personal data about yourself.
    </p>
    <h4>Server Location</h4>
    <p>
      TOPPAN Nexus has data servers located in Hong Kong as main hub and Singapore as secondary hub. If you are visiting this Web site from
      a country other than the country in which our servers are located, the various communications may result in the transfer of
      information across international boundaries. If you are accessing ACS, the content data will be stored in accordance with the terms
      and conditions outlined in an agreement. Any personal data will be protected in accordance with this Privacy Notice as well as with
      adequate protections in place in compliance with applicable laws and standards. By visiting this Web site and communicating
      electronically with us, you consent to the processing and transfer of your data as set out in this Privacy Notice.
    </p>
    <h4>Enforcement</h4>
    <p>
      We will regularly review how we are meeting privacy objectives. If you have questions, requests or complaints about our privacy
      practices, you may contact us by e-mailing
      <a target="_blank" href="mailto:acsnotification@toppannexus.com">acsnotification@toppannexus.com</a>.
    </p>
    <h4>Contact us; mail unsubscribe</h4>
    <p>
      If you no longer wish to receive e-mails or post from us for these purposes, please let us know by following the “unsubscribe”
      procedure described herein. We may use your personal information to administer ACS and help us improve our services.
    </p>
    <br />
    <p>All rights reserved to TOPPAN Nexus Limited and ACS.</p>
  </div>
  <app-dashboard-footer></app-dashboard-footer>
</div>
