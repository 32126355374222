export enum ActionType {
  AddJob = 0,
  UpdateJobStatus = 1,
  UpdateJobDetails = 2,
  UpdateBookmark = 3,
  UpdateAnnotation = 4,

  UpdateJobPrimaryFunctionalRole = 14,
  UpdatePageDetails = 15,

  RevokeCompanyAccess = 55,
  RevokeUserAccess = 100,
}
