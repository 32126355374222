<div class="header">
  <button [attr.data-dd-action-name]="'Close on About ACS'" mat-icon-button [mat-dialog-close]="true" onclick="this.blur()" aria-label="close dialog">
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content>
  <p class="dialog-message">
    ACS, developed and owned by TOPPAN Nexus Limited, is the algorithmic comparison system specializes in checking figures and specific
    financial terms in English and Chinese, especially for HKEx financial documents, such as annual reports, result announcements, circulars
    and IPO prospectuses.<br /><br />
    ACS aims at enhancing language accuracy and ensuring consistency for bilingual financial documents.
  </p>
</div>
