import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import { AppConstants } from 'src/app/app.constants';

import { CommonService } from 'src/app/services/common.service';
import { LogService } from 'src/app/services/log.service';
import { NotificationService } from 'src/app/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private commonService: CommonService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {
    return next.handle(this.addRequestIdHeader(request)).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  handleError(error: HttpErrorResponse) {
    LogService.Error(error);

    let errorMessage = '';
    if (error.status === 429) {
      this.notificationService.error('', AppConstants.ErrorTooManyRequests);
      this.commonService.showSpinner.next(false);
      return of(null);
    }
    if (error.status === 401) {
      errorMessage = AppConstants.ErrorSessionTimeout;
    } else if (error.status === 403) {
      return throwError(() => error);
    } else {
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = error.error.message;
      } else {
        // server-side error
        if (error.error instanceof Array && error.error.length > 0) {
          errorMessage = error.error.map((x) => x.message).join('; ');
        } else {
          errorMessage = error.message;
        }
      }
    }
    return throwError(() => new Error(errorMessage));
  }

  addRequestIdHeader(request: any) {
    const guid = uuidv4();
    return request.clone({
      setHeaders: {
        'Request-Id': guid.toString(),
      },
    });
  }
}
