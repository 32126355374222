<ejs-toolbar id="vertical-pdf-viewer-toolbar" class="toggle" #verticalToolbar (created)="enablePencil()">
  <e-items>
    <e-item prefixIcon="e-pv-highlight-icon" tooltipText="Highlight" (click)="enableHighlight()"></e-item>
    <e-item prefixIcon="e-pv-underline-icon" tooltipText="Underline" (click)="enableUnderline()"></e-item>
    <e-item prefixIcon="e-pv-strikethrough-icon" tooltipText="Strikethrough" (click)="enableStrikeThrough()"></e-item>
    <e-item type="Separator"></e-item>
    <e-item prefixIcon="e-annotation-edit" tooltipText="Callout" (click)="enableCallout()"></e-item>
    <e-item prefixIcon="e-pv-freetext-icon" tooltipText="Free Text" (click)="enableFreeText()"></e-item>
    <e-item type="Separator"></e-item>
    <e-item prefixIcon="e-pv-shape-line-icon" tooltipText="Straight Line" (click)="enableLine()"></e-item>
    <e-item prefixIcon="e-pv-shape-arrow-icon" tooltipText="Arrow" (click)="enableArrow()"></e-item>
    <e-item prefixIcon="e-pv-shape-rectangle-icon" tooltipText="Rectangle" (click)="enableRectangle()"></e-item>
    <e-item prefixIcon="e-pv-shape-circle-icon" tooltipText="Circle" (click)="enableCircle()"></e-item>
    <e-item type="Separator"></e-item>
    <e-item
      template="&lt;button class=&quot;e-btn&quot; id=&quot;{{ pdfViewer.element.id }}_pencil_btn&quot;&gt;&lt;/button&gt;"
      tooltipText="Pencil">
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item prefixIcon="e-trash" tooltipText="Delete Annotation" [disabled]="!isAnnotationSelected()" (click)="deleteSelectedAnnotation()">
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-toolbar id="horizontal-pdf-viewer-toolbar" #toolbar class="toggle" (created)="enableTextStyle()">
  <e-items>
    <e-item tooltipText="Undo" align="Right">
      <ng-template #template>
        <button
          ejs-button
          class="e-btn clear-all"
          cssClass="e-flat"
          iconCss="e-icons e-undo"
          id="undo"
          #undo
          [disabled]="enableDisableUndoButton() || isPageLocked"
          (click)="undoButton()"></button>
      </ng-template>
    </e-item>
    <e-item type="Separator" align="Right"></e-item>
    <e-item
      *ngIf="isFinancialCheck"
      template='&lt;button class="e-btn financial-check" id="positive-region"&gt;&lt;/button&gt;'
      tooltipText="Positive Region"
      align="Center"
      [disabled]="isPageLocked">
    </e-item>
    <e-item
      *ngIf="isFinancialCheck"
      template='&lt;button class="e-btn financial-check" id="negative-region"&gt;&lt;/button&gt;'
      tooltipText="Negative Region"
      align="Center"
      [disabled]="isPageLocked">
    </e-item>
    <e-item *ngIf="isFinancialCheck" align="Center" tooltipText="Clear All">
      <ng-template #template>
        <button
          ejs-button
          class="e-btn clear-all"
          cssClass="e-flat"
          iconCss="e-icons e-pv-delete"
          id="clear-all"
          #clearAll
          [disabled]="disableFinancialRegions()"
          (click)="deleteFinancialCheckAnnot()">
          Clear All
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator" align="Right"></e-item>
    <e-item
      template="&lt;button class=&quot;e-btn&quot; id=&quot;{{ pdfViewer.element.id }}_bold_btn&quot;&gt;&lt;/button&gt;"
      tooltipText="Bold"
      align="Right"
      [disabled]="!isFreeTextSelected() || isPageLocked">
    </e-item>
    <e-item
      template="&lt;button class=&quot;e-btn&quot; id=&quot;{{ pdfViewer.element.id }}_italic_btn&quot;&gt;&lt;/button&gt;"
      tooltipText="Italic"
      align="Right"
      [disabled]="!isFreeTextSelected() || isPageLocked">
    </e-item>
    <e-item
      template="&lt;button class=&quot;e-btn&quot; id=&quot;{{ pdfViewer.element.id }}_underline_btn&quot;&gt;&lt;/button&gt;"
      tooltipText="Underline"
      align="Right"
      [disabled]="!isFreeTextSelected() || isPageLocked">
    </e-item>
    <e-item
      template="&lt;button class=&quot;e-btn&quot; id=&quot;{{ pdfViewer.element.id }}_strikeout_btn&quot;&gt;&lt;/button&gt;"
      tooltipText="Strikethrough"
      align="Right"
      [disabled]="!isFreeTextSelected() || isPageLocked">
    </e-item>
    <e-item type="Separator" align="Right"></e-item>
    <e-item tooltipText="Color Picker" align="Right">
      <ng-template #template>
        <button
          #colorPicker
          ejs-dropdownbutton
          [items]="constants.annotationColors"
          [disabled]="(disableColorPicker && !isAnnotationSelectedForColor()) || isPageLocked"
          iconCss="e-icons e-paint-bucket"
          cssClass="e-flat"
          class="e-btn clear-all"
          (select)="onSelectColors($event)"
          (beforeItemRender)="itemBeforeEvent($event)"
          (click)="onColorChangeClick()"></button>
      </ng-template>
    </e-item>
    <e-item type="Separator" align="Right"></e-item>
    <e-item
      [disabled]="!isAnnotationSelected() || isPageLocked"
      prefixIcon="e-icons e-changes-track"
      tooltipText="Error Category and Severity"
      align="Right"
      (click)="openLqaDialog()">
    </e-item>
    <e-item type="Separator" align="Right"></e-item>
    <!--<e-item [disabled]="isMonolingualSearch" tooltipText="Import Page(s)" align="Right" (click)="getImportPages()">
      <ng-template #template>
        <button ejs-button iconCss="e-icons e-save-as" cssClass="color-button"></button>
        <span class="e-badge e-badge-red e-badge-overlap e-badge-notification e-badge-circle" *ngIf="referencedPagesCount > -1">{{ referencedPagesCount < 100 ? referencedPagesCount : '99+' }}</span>
      </ng-template>
    </e-item>
    <e-item type="Separator" align="Right"></e-item>-->
    <e-item
      prefixIcon="e-pv-comment-panel-icon"
      tooltipText="Comment Panel"
      align="Right"
      [disabled]="!documentLoaded || isPageLocked"
      (click)="toggleCommentPanel()">
    </e-item>
    <e-item
      *ngIf="!isFinancialCheck"
      prefixIcon="e-close"
      tooltipText="Save & Close"
      align="Right"
      [disabled]="disableCloseButton"
      (click)="CloseViewer()"></e-item>
  </e-items>
</ejs-toolbar>

<div kendoDialogContainer #dialogContainer></div>
