<form [formGroup]="startNewComparisonForm" autocomplete="off" (ngSubmit)="onSubmit()">
  <div class="custom-dialog">
    <h2 mat-dialog-title>{{ appConstants.StartNewComparisonJobDialogTitle }}</h2>

    <mat-dialog-content>
      <div class="material-textfield form-group">
        <input
          placeholder=" "
          name="jobName"
          formControlName="jobNameControl"
          class="job-name-field"
          [ngClass]="jobNameControl.touched && jobNameControl.invalid ? 'job-name-field-err' : ''"
          type="text"
          cdkFocusInitial />
        <label class="job-name-label" [ngClass]="jobNameControl.touched && jobNameControl.invalid ? 'job-name-label-err' : ''">
          {{ appConstants.JobNameFieldTitle }}
        </label>
        <mat-error *ngIf="jobNameControl.touched && jobNameControl.invalid">
          {{ jobNameControl | errorMessage : jobNameError }}
        </mat-error>
      </div>

      <div class="feature-type">
        <label>{{ appConstants.JobTypeNameLabel }}</label>
        <mat-select
          formControlName="jobTypeControl"
          disableOptionCentering
          panelClass="drop-down-panel"
          (selectionChange)="onSelectedJobTypeChanged()">
          <mat-option
            color="primary"
            *ngFor="
              let featureType of !appDataService.user.isExternalUser ? appConstants.FeatureType : appConstants.ExternalUserFeatureType
            "
            [value]="featureType.Id">
            {{ featureType.TypeName }}
          </mat-option>
        </mat-select>
      </div>

      <div class="compare-file-upload">
        <app-file-upload
          id="englishFile"
          [control]="englishFileControl"
          fileType=".pdf,.doc,.docx"
          (fileSelected)="englishFileSelected($event)"
          [dragDropLabel]="getDragAndDropLabel()"
          (fileRemoved)="englishFileRemoved()">
        </app-file-upload>

        <app-file-upload
          #chineseFileUpload
          id="chineseFile"
          [control]="chineseFileControl"
          fileType=".pdf,.doc,.docx"
          (fileSelected)="chineseFileSelected($event)"
          [dragDropLabel]="
            jobTypeControl.value === featureType.Square_Bracket_Check
              ? appConstants.UploadChineseFileLabelOptional
              : appConstants.UploadChineseFileLabel
          "
          (fileRemoved)="chineseFileRemoved()">
        </app-file-upload>
      </div>
      <div *ngIf="!appDataService.user.isExternalUser" class="feature-type">
        <label>{{ appConstants.GlossaryFileLabel }}</label>
        <mat-form-field class="mat-control-width mat-feature-type" floatLabel="always">
          <mat-select
            name="glossaryField"
            formControlName="selectedGlossaryControl"
            disableOptionCentering
            panelClass="drop-down-panel"
            class="glossary-select"
            (selectionChange)="onSelectedGlossaryChanged()">
            <mat-option class="glossary-select-search">
              <ngx-mat-select-search
                [formControl]="glossaryFilter"
                name="GlossaryField"
                [preventHomeEndKeyPropagation]="true"
                placeholderLabel="Search Glossary"
                [noEntriesFoundLabel]="appConstants.NoGlossaryFound">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngIf="(filteredGlossaryList | async) && (filteredGlossaryList | async)!.length > 0 && glossaryFilter.value?.length < 3"
              [value]="''">
              {{ appConstants.GlossaryDefaultOption }}
            </mat-option>
            <mat-option *ngFor="let glossary of filteredGlossaryList | async" [value]="glossary.glossaryId">
              {{ glossary.name }}
            </mat-option>
          </mat-select>
          <button
            *ngIf="hasAny(selectedGlossaryControl)"
            matSuffix
            mat-icon-button
            type="button"
            aria-label="Clear"
            (click)="clearControl(selectedGlossaryControl, $event)">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="selectedGlossaryControl.hasError('bothAreEmpty') || selectedGlossaryControl.hasError('selectedGlossaryEmpty')">
            {{ appConstants.ErrorRequiredGlossary }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="file-upload">
        <app-file-upload
          #glossaryFileUpload
          id="glossaryFile"
          [control]="glossaryFileControl"
          [dragDropLabel]="
            jobTypeControl.value === featureType.Glossary_Consistency_Check
              ? appConstants.ForGlossaryFeatureUploadGlossaryFileLabel
              : appConstants.UploadGlossaryFileLabel
          "
          [fileType]="appConstants.UploadGlossaryAllowedFileTypes"
          (fileSelected)="glossaryFileSelected($event)"
          (fileRemoved)="glossaryFileRemoved()">
        </app-file-upload>
      </div>
    </mat-dialog-content>

    <div mat-dialog-actions>
      <button
        [attr.data-dd-action-name]="dataDogAction.CreateJob"
        [disabled]="startNewComparisonForm.invalid || isSubmitButtonDisabled"
        type="submit"
        mat-raised-button>
        {{ appConstants.StartButtonLabel }}
      </button>
      <button [attr.data-dd-action-name]="dataDogAction.CreateJobCancel" mat-raised-button [mat-dialog-close]="false" [disabled]="isSubmitButtonDisabled">
        {{ appConstants.CancelButtonLabel }}
      </button>
    </div>
  </div>
</form>
