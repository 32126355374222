export class FeaturePermissions {
  acs_job: JobFeature = new JobFeature();
  acs_glossary: boolean = false;
  acs_usageReport: boolean = false;
}

export class JobFeature {
  view: boolean = false;
  create: boolean = false;
  close: boolean = false;
  compare: boolean = false;
}
