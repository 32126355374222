<kendo-formfield>
    <div class="wrap">
      <input
        #chkbox
        [id]="id"
        type="checkbox"
        class="mr-2"
        kendoCheckBox
        [size]="size"
        [rounded]="rounded"
        [formControl]="control"
        [required]="required"
        [checked]="control.value"
        (click)="onClick()" />
      <kendo-label class="ml-3" [for]="chkbox" [text]="label"><span *ngIf="required" class="asterisk"></span></kendo-label>
      <kendo-formhint>{{ hint }}</kendo-formhint>
      <kendo-formerror *ngIf="control.touched && control.invalid"> {{ control | errorMessage : errors }}</kendo-formerror>
    </div>
  </kendo-formfield>
