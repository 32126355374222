import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldError } from 'src/app/models/form-field-error.model';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-kendo-checkbox-field',
  templateUrl: './kendo-checkbox-field.component.html'
})
export class KendoCheckboxFieldComponent {
  @Input() id: string | undefined = undefined;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() rounded: 'small' | 'medium' | 'large' = 'medium';
  @Input() hint: string = '';
  @Input() errors: FormFieldError[] = [];
  @Input() control: FormControl = new FormControl(false);

  @Output() valueChange = new EventEmitter<any>();

  constructor(public commonService: CommonService) {}

  public onClick() {
    this.control.setValue(!this.control.value);
    this.valueChange.emit(this.control.value);
  }
}
