<mat-toolbar>
  <button
    mat-icon-button
    (click)="onPreviousClick($event)"
    [disabled]="isFirstPage() || disabled"
    onclick="this.blur()"
    aria-label="View previous page">
    <mat-icon matTooltip="Previous Page">navigate_before</mat-icon>
  </button>
  <mat-form-field class="paginator mat-control-width">
    <input
      matInput
      id="pageNumberCtrl"
      type="number"
      [disabled]="disabled"
      [value]="currentPageNumber"
      min="1"
      [max]="pageCount"
      [ngClass]="invalidStyle"
      [(ngModel)]="currentPageNumber"
      #tooltip="matTooltip"
      [matTooltip]="getToolTip()"
      (keyup.enter)="onEnter($event)"
      (keyup)="onChange($event, tooltip)"
      appNumbersOnly
      cdkFocusInitial />
    <span matSuffix>&nbsp;of {{ pageCount }}</span>
  </mat-form-field>
  <button
    mat-icon-button
    (click)="onNextClick($event)"
    [disabled]="isLastPage() || disabled"
    onclick="this.blur()"
    aria-label="View Next page">
    <mat-icon matTooltip="Next Page">navigate_next</mat-icon>
  </button>
  <mat-divider vertical></mat-divider>
</mat-toolbar>
