import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConstants } from '../app.constants';

import { IAppSettings } from '../models/app-settings.model';

import { AppList } from '../core/enums/application-type.enum';

@Injectable({
  providedIn: 'root',
})
export class EmailOtpService {
  private apiPath: string = ((<any>window)[AppConstants.AppSettings] as IAppSettings).common.userManagementApiPath;

  constructor(private http: HttpClient) {}

  public SendEmailOtp(applicationName: AppList): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiPath}api/User/SendEmailOtp/${applicationName}`);
  }

  public VerifyEmailOtp(accessCode: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiPath}api/User/VerifyEmailOtp/${accessCode}?applicationName=${+AppList.ACS}`);
  }
}
