<h1 mat-dialog-title>{{ constants.UsageReportLabel }}</h1>
<form [formGroup]="reportManagementForm" (ngSubmit)="onSubmit()" class="flex-column" autocomplete="off">
  <div mat-dialog-content>
    <mat-form-field class="w-50 pe-2 date-range-picker">
      <mat-label>{{ constants.DateRangeLabel }}</mat-label>
      <input
        type="text"
        required
        matInput
        ngxDaterangepickerMd
        [readonly]="true"
        formControlName="dateRange"
        [timePicker]="false"
        [showDropdowns]="true"
        [alwaysShowCalendars]="true"
        [ranges]="ranges"
        [showCancel]="true"
        [keepCalendarOpeningWithRange]="true"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [showRangeLabelOnInput]="false" />
    </mat-form-field>

    <mat-form-field class="w-50 ps-2" floatLabel="always">
      <mat-label>{{ constants.UserNameLabel }}</mat-label>
      <mat-select
        #userName
        formControlName="userNameControl"
        multiple
        disableOptionCentering
        (keyup.enter)="toggleSelectOne(allOptionUser, userNameControl, users)"
        (closed)="onClosed(allOptionUser, userNameControl, users)">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="userFilter"
            name="UserNameField"
            [preventHomeEndKeyPropagation]="true"
            placeholderLabel="Search Username"
            (keyup.enter)="onKeyUpType(allOptionUser, userName, getUsersFilteredList(), userNameControl)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          #allOptionUser
          [value]="100"
          [hidden]="(filteredUsersList | async)?.length === 0"
          (click)="toggleSelectAll(allOptionUser, userNameControl, getUsersFilteredList())">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <div class="no-entries-found" [hidden]="(filteredUsersList | async)?.length !== 0">
          {{ constants.NoUserFound }}
        </div>
        <mat-option
          *ngFor="let user of filteredUsersList | async"
          [value]="user"
          (click)="toggleSelectOne(allOptionUser, userNameControl, users)">
          {{ user.displayName }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="hasAny(userNameControl)"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="clearControl(userNameControl, allOptionUser, $event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="w-50 pe-2" floatLabel="always">
      <mat-label>{{ constants.AcsTeamLabel }}</mat-label>
      <mat-select
        #acsTeamName
        formControlName="acsTeamNameControl"
        multiple
        disableOptionCentering
        (keyup.enter)="toggleSelectOne(allOptionAcsTeam, acsTeamNameControl, acsTeamsData)"
        (closed)="onClosed(allOptionAcsTeam, acsTeamNameControl, acsTeamsData)">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="acsTeamFilter"
            name="AcsTeamNameField"
            [preventHomeEndKeyPropagation]="true"
            placeholderLabel="Search Team"
            (keyup.enter)="onKeyUpType(allOptionAcsTeam, acsTeamName, getAcsTeamsFilteredList(), acsTeamNameControl)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          #allOptionAcsTeam
          [value]="100"
          [hidden]="(filteredAcsTeamsList | async)?.length === 0"
          (click)="toggleSelectAll(allOptionAcsTeam, acsTeamNameControl, getAcsTeamsFilteredList())">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <div class="no-entries-found" [hidden]="(filteredAcsTeamsList | async)?.length !== 0">
          {{ constants.NoAcsTeamFound }}
        </div>
        <mat-option
          *ngFor="let acsTeam of filteredAcsTeamsList | async"
          [value]="acsTeam"
          (click)="toggleSelectOne(allOptionAcsTeam, acsTeamNameControl, acsTeamsData)">
          {{ acsTeam.teamName }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="hasAny(acsTeamNameControl)"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="clearControl(acsTeamNameControl, allOptionAcsTeam, $event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="w-50 ps-2" floatLabel="always">
      <mat-label>{{ constants.TeamNameLabel }}</mat-label>
      <mat-select
        #functionRole
        formControlName="functionalRolesControl"
        multiple
        disableOptionCentering
        (keyup.enter)="toggleSelectOne(allOptionTeam, functionalRolesControl, functionalRoles)"
        (closed)="onClosed(allOptionTeam, functionalRolesControl, functionalRoles)">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="functionalRolesFilter"
            name="UserRoleField"
            [preventHomeEndKeyPropagation]="true"
            placeholderLabel="Search Dashboard"
            (keyup.enter)="onKeyUpType(allOptionTeam, onKeyUpType, getTeamsFilteredList(), functionalRolesControl)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          #allOptionTeam
          [value]="100"
          [hidden]="(filteredfunctionalRolesList | async)?.length === 0"
          (click)="toggleSelectAll(allOptionTeam, functionalRolesControl, getTeamsFilteredList())">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <div class="no-entries-found" [hidden]="(filteredfunctionalRolesList | async)?.length !== 0">
          {{ constants.NoUserRoleFound }}
        </div>
        <mat-option
          *ngFor="let functionalrole of filteredfunctionalRolesList | async"
          [value]="functionalrole"
          (click)="toggleSelectOne(allOptionTeam, functionalRolesControl, functionalRoles)">
          {{ functionalrole.name }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="hasAny(functionalRolesControl)"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="clearControl(functionalRolesControl, allOptionTeam, $event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="w-50 pe-2" floatLabel="always">
      <mat-label> Type </mat-label>
      <mat-select
        #jobType
        formControlName="jobTypeControl"
        disableOptionCentering
        multiple
        (keyup.Space)="onKeyUpType(multiSelect, jobType, constants.FeatureType, jobTypeControl, 'Id')"
        (keyup.enter)="onKeyUpType(multiSelect, jobType, constants.FeatureType, jobTypeControl, 'Id')">
        <mat-option #multiSelect [value]="100" (click)="toggleSelectAll(multiSelect, jobTypeControl, constants.FeatureType, 'Id')">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <mat-option
          *ngFor="let featureType of constants.FeatureType"
          [value]="featureType.Id"
          (click)="toggleSelectOne(multiSelect, jobTypeControl, constants.FeatureType, 'Id')">
          {{ featureType.TypeName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-50 ps-2" floatLabel="always">
      <mat-label> {{ constants.SelectGlossaryLabel }} </mat-label>
      <mat-select
        #glossary
        formControlName="glossaryControl"
        disableOptionCentering
        multiple
        (keyup.Space)="onKeyUpType(multiSelectGlossary, glossary, glossaryList, glossaryControl, 'id')"
        (keyup.enter)="onKeyUpType(multiSelectGlossary, glossary, glossaryList, glossaryControl, 'id')">
        <mat-option #multiSelectGlossary [value]="100" (click)="toggleSelectAll(multiSelectGlossary, glossaryControl, glossaryList, 'id')">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <mat-option
          *ngFor="let glossary of glossaryList"
          [value]="glossary.id"
          (click)="toggleSelectOne(multiSelectGlossary, glossaryControl, glossaryList, 'id')">
          {{ glossary.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-50 pe-2" floatLabel="always">
      <mat-label> {{ constants.SelectAnnotationLabel }} </mat-label>
      <mat-select
        #annotation
        formControlName="annotationControl"
        disableOptionCentering
        multiple
        (keyup.Space)="onKeyUpType(multiSelectAnnotation, annotation, annotationList, annotationControl, 'id')"
        (keyup.enter)="onKeyUpType(multiSelectAnnotation, annotation, annotationList, annotationControl, 'id')">
        <mat-option
          #multiSelectAnnotation
          [value]="100"
          (click)="toggleSelectAll(multiSelectAnnotation, annotationControl, annotationList, 'id')">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <mat-option
          *ngFor="let annotation of annotationList"
          [value]="annotation.id"
          (click)="toggleSelectOne(multiSelectAnnotation, annotationControl, annotationList, 'id')">
          {{ annotation.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-50 ps-2" floatLabel="always">
      <mat-label> {{ constants.SelectAnnotationSideLabel }} </mat-label>
      <mat-select
        #annotationSide
        formControlName="annotationSideControl"
        disableOptionCentering
        multiple
        (keyup.Space)="onKeyUpType(multiSelectAnnotationSide, annotationSide, annotationSideList, annotationSideControl, 'id')"
        (keyup.enter)="onKeyUpType(multiSelectAnnotationSide, annotationSide, annotationSideList, annotationSideControl, 'id')">
        <mat-option
          #multiSelectAnnotationSide
          [value]="100"
          (click)="toggleSelectAll(multiSelectAnnotationSide, annotationSideControl, annotationSideList, 'id')">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <mat-option
          *ngFor="let annotationSide of annotationSideList"
          [value]="annotationSide.id"
          (click)="toggleSelectOne(multiSelectAnnotationSide, annotationSideControl, annotationSideList)">
          {{ annotationSide.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-50 pe-2" floatLabel="always">
      <mat-label>{{ constants.SelectCompanyNameLabel }}</mat-label>
      <mat-select
        #companyName
        formControlName="companyNameControl"
        multiple
        disableOptionCentering
        (closed)="onClosed(allOptionCompany, companyNameControl, companies)">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="companyNameFilter"
            name="companyNameField"
            [preventHomeEndKeyPropagation]="true"
            placeholderLabel="Search Company Name"
            (keyup.enter)="onKeyUpType(allOptionCompany, companyName, companies, companyNameControl)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          #allOptionCompany
          [value]="100"
          [hidden]="(filteredCompanyList | async)?.length === 0"
          (click)="toggleSelectAll(allOptionCompany, companyNameControl, getCompanyFilteredList())">
          {{ constants.SelectAllLabel }}
        </mat-option>
        <div class="no-entries-found" [hidden]="(filteredCompanyList | async)?.length !== 0">
          {{ constants.NoCompanyNameFound }}
        </div>
        <mat-option
          *ngFor="let company of filteredCompanyList | async"
          [value]="company"
          (click)="toggleSelectOne(allOptionCompany, companyNameControl, companies)">
          {{ company.companyName }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="hasAny(companyNameControl)"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="clearControl(companyNameControl, allOptionCompany, $event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="pb-4">
    <button
      [attr.data-dd-action-name]="constants.OkButtonLabel"
      mat-raised-button
      type="submit"
      [disabled]="reportManagementForm.invalid || isSubmitButtonDisabled">
      {{ constants.OkButtonLabel }}
    </button>
    <button [attr.data-dd-action-name]="constants.CancelButtonLabel" mat-raised-button [mat-dialog-close]="false" [disabled]="isSubmitButtonDisabled">
      {{ constants.CancelButtonLabel }}
    </button>
  </div>
</form>
