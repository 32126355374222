import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[appNumbersOnly]'
})
export class NumericDirective {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Clear',
    'Copy',
    'Paste',
  ];

  inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === 'a' || e.code === 'KeyA') && (e.ctrlKey === true || e.metaKey === true)) || // Allow: Ctrl+A  OR  Cmd+A (Mac)
      ((e.key === 'c' || e.code === 'KeyC') && (e.ctrlKey === true || e.metaKey === true)) || // Allow: Ctrl+C  OR  Cmd+C (Mac)
      ((e.key === 'v' || e.code === 'KeyV') && (e.ctrlKey === true || e.metaKey === true)) || // Allow: Ctrl+V  OR  Cmd+V (Mac)
      ((e.key === 'x' || e.code === 'KeyX') && (e.ctrlKey === true || e.metaKey === true)) || // Allow: Ctrl+X  OR  Cmd+X (Mac)
      (/^\d+$/.test(e.key)) // Ensure that it is a number and stop the keypress
    ) {
      // let it happen, don't do anything
      return;
    }

    e.preventDefault();
  }
}
