import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConstants } from '../app.constants';

import { IAppSettings } from '../models/app-settings.model';

import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class FileStoreService {
  private apiPath: string = ((window as any)[AppConstants.AppSettings] as IAppSettings).common.apiPath;

  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  public downloadFile(blobName: string, fileName: string): void {
    if (this.isFileExcel(blobName) && !this.isFileExcel(fileName)) {
      fileName = this.addExtensionToFileName(blobName, fileName);
    }
    const params = new HttpParams().set('blobName', blobName);
    this.http.get(`${this.apiPath}api/FileStore`, { params, responseType: 'text' }).subscribe({
      next: (data) => {
        this.downloadFileFn(data, fileName);
      },
      error: (err) => {
        this.errorHandler(err);
      },
    });
  }

  public downloadTempFile(blobName: string, fileName: string): void {
    this.downloadTempFileFn(blobName).subscribe({
      next: (data) => {
        this.downloadFileFn(data, fileName);
      },
      error: (err) => {
        this.errorHandler(err);
      },
    });
  }

  private errorHandler(err: any): void {
    if (err === AppConstants.ErrorSessionTimeout || err?.status === 401) {
      this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
    } else {
      this.notificationService.error('', AppConstants.ErrorFileDownload);
    }
  }

  public downloadFileFn(data: any, fileName: string): void {
    const downloadedFile = new Blob([this.base64ToArrayBuffer(data)], { type: 'application/octet-stream' });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = fileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  public downloadTempFileFn(blobName: string): Observable<any> {
    const params = new HttpParams().set('blobName', blobName);
    return this.http.get(`${this.apiPath}api/FileStore/temp`, { params, responseType: 'text' });
  }

  private base64ToArrayBuffer(base64: any): ArrayBuffer {
    var binaryString = window.atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private addExtensionToFileName(blobName: string, fileName: string) {
    const extension = blobName.split('.').pop();
    return fileName + '.' + extension;
  }

  private isFileExcel(name: string) {
    return name.includes('.xls') || name.includes('.xlsx');
  }
}
