import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { PdfViewerComponent } from '@syncfusion/ej2-angular-pdfviewer';

import { AppConstants } from '../app.constants';

import { AnnotationDetailsResponse } from '../models/annotation-details-response.model';
import { CalloutFreetextBounds } from '../models/callout-freetext-bounds.model';
import { FinancialCheckType } from '../models/financial-check-region.model';
import { GetImportPagesResponse } from '../models/get-import-pages-response.model';
import { PdfAnnotationSizeModel } from '../models/pdf-annotation-size.model';
import { PdfPageSizeModel } from '../models/pdf-page-size-model';
import { PdfPointModel } from '../models/pdf-point.model';
import { SaveAnnotationDetailsRequest } from '../models/save-annotation-details-request.model';

@Injectable({
  providedIn: 'root',
})
export class PdfViewerService {
  private apiPath = (window as any)[AppConstants.AppSettings].common.apiPath;

  public exportPDFCompleted = new Subject<string>();

  constructor(private http: HttpClient) {}

  public showCommentPanel(pdfViewer: PdfViewerComponent) {
    setTimeout(() => {
      if (pdfViewer.annotationCollection.length > 0) {
        pdfViewer.enableCommentPanel = true;
        pdfViewer.isCommandPanelOpen = true;
        pdfViewer.annotationModule.showCommentsPanel();
      }
    }, 500);
  }

  public showCommentsPanel(pdfViewer: PdfViewerComponent): void {
    pdfViewer.enableCommentPanel = true;
    pdfViewer.isCommandPanelOpen = true;
    pdfViewer.annotationModule.showCommentsPanel();
  }

  public hideCommentsPanel(pdfViewer: PdfViewerComponent): void {
    pdfViewer.isCommandPanelOpen = false;
    pdfViewer.enableCommentPanel = false;
  }

  public convertStringToNumber(input: string): number {
    // Use parseFloat to convert the string to a number
    const parsedNumber = Number(input);

    // Check if the conversion is successful and the result is a valid number
    if (!isNaN(parsedNumber)) {
      return parsedNumber;
    } else {
      // Handle invalid data, for example, return null or a default value
      return 0;
    }
  }

  public isValidNumber(input: string): boolean {
    return input.length > 0 && !isNaN(Number(input));
  }

  public isFinancialCheckRegion(type: FinancialCheckType): boolean {
    return type === FinancialCheckType.Positive || type === FinancialCheckType.Negative;
  }

  public saveAnnotationDetails(request: SaveAnnotationDetailsRequest): Observable<Object> {
    return this.http.post(`${this.apiPath}api/PdfViewer/SaveAnnotationDetails`, request);
  }

  public getAnnotationLqa(pageIdentity: number): Observable<AnnotationDetailsResponse> {
    return this.http.get<AnnotationDetailsResponse>(`${this.apiPath}api/PdfViewer/GetAnnotations?pageIdentity=${pageIdentity}`);
  }

  public exportAnnotationPages(request: any, pageIdentity: any, jobIdentity: string): Observable<any> {
    return this.http.post(`${this.apiPath}api/PdfViewer/ExportAnnotations`, request, {
      headers: new HttpHeaders({ pageIdentity: pageIdentity, jobIdentity: jobIdentity }),
    });
  }

  public getImportPages(jobIdentity: string, fileIdentity: number, pageIdentity: number): Observable<GetImportPagesResponse> {
    return this.http.get<GetImportPagesResponse>(
      `${this.apiPath}api/PdfViewer/GetReferencePageDetails?fileIdentity=${fileIdentity}&pageIdentity=${pageIdentity}`,
      {
        headers: new HttpHeaders({ jobIdentity, timeout: '900000' }),
      }
    );
  }

  public processFreeTextAnnotation(pdfViewer: PdfViewerComponent, freetextDetails: CalloutFreetextBounds, pageSize?: PdfPageSizeModel) {
    const freeTextAnnotation = pdfViewer.annotationCollection.filter((x) => x.annotationId === freetextDetails.annotationId)[0];
    if (!freeTextAnnotation || !freeTextAnnotation.customData || freeTextAnnotation.customData.dependsOn === '') {
      return;
    }
    const lineAnnotation = pdfViewer.annotationCollection.filter(
      (x) => x.shapeAnnotationType === 'Line' && x.customData && x.customData.dependsOn === freetextDetails.annotationId
    )[0];
    if (lineAnnotation) {
      lineAnnotation.vertexPoints = [
        {
          x: lineAnnotation.vertexPoints[0].x,
          y: lineAnnotation.vertexPoints[0].y,
        },
        {
          x: Math.round(freetextDetails.left + freetextDetails.width / 2),
          y: Math.round(freetextDetails.top),
        },
      ];
      pdfViewer.annotation.editAnnotation(lineAnnotation);
    }
  }

  public processLineAnnotation(pdfViewer: PdfViewerComponent, annotationId: string, pageSize: PdfPageSizeModel, isResize: boolean = false) {
    const lineAnnotation = pdfViewer.annotationCollection.filter((x) => x.annotationId === annotationId)[0];
    if (!lineAnnotation || !lineAnnotation.customData || lineAnnotation.customData.dependsOn === '') {
      return;
    }
    const freeTextAnnotation = pdfViewer.annotationCollection.filter(
      (x) => x.shapeAnnotationType === 'FreeText' && x.customData && x.customData.dependsOn === annotationId
    )[0];
    if (freeTextAnnotation) {
      const vertexPoints: PdfPointModel | undefined = this.getAdjustedLineEndPoints(
        lineAnnotation.vertexPoints,
        { Width: freeTextAnnotation.bounds.width, Height: freeTextAnnotation.bounds.height },
        pageSize
      );
      if (vertexPoints) {
        freeTextAnnotation.bounds.x = vertexPoints.x;
        freeTextAnnotation.bounds.y = vertexPoints.y;
        freeTextAnnotation.bounds.left = vertexPoints.x;
        freeTextAnnotation.bounds.top = vertexPoints.y;
        pdfViewer.annotation.editAnnotation(freeTextAnnotation);
      }
      // update annotation
      lineAnnotation.isCommentLock = true;
      lineAnnotation.customData = { dependsOn: freeTextAnnotation.annotationId }; // getting removed when moved sometimes.
      if (isResize) {
        const lineEndPoints = lineAnnotation.vertexPoints?.at(-1);
        lineEndPoints.x = Math.round(freeTextAnnotation.bounds.left + 70);
        lineEndPoints.y = Math.round(freeTextAnnotation.bounds.top);
      }
      pdfViewer.annotation.editAnnotation(lineAnnotation);
    }
  }

  public getAdjustedLineEndPoints(
    vertexPoints: PdfPointModel[],
    freeTextSize: PdfAnnotationSizeModel,
    pageSize: PdfPageSizeModel
  ): PdfPointModel | undefined {
    if (vertexPoints.length > 0) {
      const firstItem = vertexPoints[0];
      const lastItem = vertexPoints.at(-1);

      if (!lastItem) {
        return undefined;
      }

      const lineEndPoints: PdfPointModel = {
        x: lastItem.x,
        y: firstItem.y > lastItem.y ? lastItem.y - freeTextSize.Height + 5 : lastItem.y,
      };

      if (lineEndPoints.x - freeTextSize.Width > 0) {
        lineEndPoints.x -= freeTextSize.Width / 2;
      }
      if (lineEndPoints.x + freeTextSize.Width > pageSize.Width) {
        lineEndPoints.x = pageSize.Width - freeTextSize.Width - 5;
      }
      if (lineEndPoints.y + freeTextSize.Height > pageSize.Height) {
        lineEndPoints.y = pageSize.Height - freeTextSize.Height - 5;
      }
      if (lineEndPoints.x < 0) {
        lineEndPoints.x = 0;
      }
      return lineEndPoints;
    }
    return undefined;
  }

  public changeCommentIcon(annotationId: string): void {
    const commentDiv = document.getElementById(annotationId);
    if (commentDiv) {
      const span = commentDiv.getElementsByClassName('e-pv-freetext-icon')[0];
      if (span) {
        span.setAttribute('class', 'e-icons e-annotation-edit');
      }
      const pencilIcon = commentDiv.getElementsByClassName('e-pv-inkannotation-icon')[0];
      if (pencilIcon) {
        pencilIcon.setAttribute('class', 'e-icons e-edit');
      }
    }
  }

  public removeCommentDiv(annotationId: string) {
    const commentDiv = document.getElementById(annotationId);
    if (commentDiv) {
      commentDiv.hidden = true;
    }
  }
}
