import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, EventEmitter, Output } from '@angular/core';

import { MsalService } from '@azure/msal-angular';

import { AppConstants } from 'src/app/app.constants';

import { FormFieldError } from 'src/app/models/form-field-error.model';

import { AppList } from 'src/app/core/enums/application-type.enum';

import { EmailOtpService } from 'src/app/services/email-otp.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-email-otp-verification',
  templateUrl: './email-otp-verification.component.html',
  styleUrls: ['./email-otp-verification.component.css'],
})
export class EmailOtpVerificationComponent {
  public readonly constants: typeof AppConstants = AppConstants;

  public readonly accessCodeControl: FormControl = new FormControl('', Validators.required);
  public readonly emailOtpVerificationForm: FormGroup = this.formBuilder.group({
    accessCodeControl: this.accessCodeControl,
  });
  @Output() verifyAccessCode = new EventEmitter<boolean>();

  public readonly accessCodeCtrlErrors: FormFieldError[] = [
    { key: 'required', message: AppConstants.OtpRequiredValue },
    { key: 'invalidCode', message: AppConstants.OtpInvalid },
  ];

  constructor(
    private emailOtpService: EmailOtpService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem(AppConstants.User2FAverify) == null) {
     this.commonService.logout();
    }
    if (localStorage.getItem(AppConstants.User2FAverify) == 'true' && localStorage.getItem(AppConstants.User2FAverifyMailSent) != 'yes') {
      this.sendEmailOtp();
    }
  }

  private sendEmailOtp(): void {
    this.emailOtpService.SendEmailOtp(+AppList['ACS']).subscribe({
      next: (isSuccess: boolean) => {
        if (isSuccess) {
          localStorage.setItem(AppConstants.User2FAverifyMailSent, 'yes');
          this.notificationService.success('', AppConstants.EmailSentSuccessMessage);
        } else {
          this.notificationService.error('', AppConstants.ErrorInSeningEmail);
        }
      },
      error: (error: Error | null) => {
        if (error) {
          this.notificationService.error('', AppConstants.ErrorInSeningEmail);
        }
      },
    });
  }

  public verifyEmailOtp() {
    this.emailOtpService.VerifyEmailOtp(this.accessCodeControl.value).subscribe({
      next: (res: boolean) => {
        if (res) {
          localStorage.setItem(AppConstants.User2FAverify, 'false');
          this.verifyAccessCode.emit(res);
          this.notificationService.success('', AppConstants.EmailVerificationSuccessMessage);
        } else {
          this.accessCodeControl.setErrors({ invalidCode: true });
        }
      },
      error: (error: Error | null) => {
        if (error) {
          this.accessCodeControl.setErrors({ invalidCode: true });
        }
      },
    });
  }
}
