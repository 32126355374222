export enum DataDogAction {
  CreateJob = 'Create Job',
  CreateJobCancel = 'Cancel Job Creation',
  CreateGlossary = 'Create Glossary',
  CreateGlossaryCancel = 'Cancel Glossary Creation',

  File1Download = 'File 1 download',
  File2Download = 'File 2 download',
  GlossaryDownload = 'Glossary File Download',
}
