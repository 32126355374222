<app-progress></app-progress>
<app-login *ngIf="!isLoggedIn() && !hasRouteGuard()" [hidden]="!appLoaded"></app-login>
<app-email-otp-verification *ngIf="isLoggedIn() && isLoginSuccess" [hidden]="!appLoaded" (verifyAccessCode)="onCheck2faSuccess($event)">
</app-email-otp-verification>
<router-outlet *ngIf="(isLoggedIn() && appDataService.userProfileLoaded) || hasRouteGuard()" [hidden]="!appLoaded"></router-outlet>
<div class="app-loading" [hidden]="appLoaded">
  <object class="spinner" type="image/svg+xml" data="assets/loading.svg">
    <h2 id="fallback">loading ...</h2>
  </object>
</div>
<div kendoDialogContainer #dialogContainer></div>
