<div class="container">
  <div class="row justify-content-center">
    <h1 class="col-10 text-center">403</h1>
    <h3 class="col-10 text-center">Forbidden</h3>
    <h6 class="col-10 text-center">Access to this resource on the server denied</h6>   
  </div>
  <div class="row justify-content-center mt-2">
    <button  (click)="backtologin()" class="btn btn-primary btn-backtologin col-md-2" onclick="this.blur()" aria-label="Back to login">Back to login</button>
  </div>
</div>
