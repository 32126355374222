import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { datadogLogs } from '@datadog/browser-logs';
import { AppConstants } from '../app.constants';
import { DataDogService } from './data-dog.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public isAddScreen: boolean = false;

  public open<T>(componentOrTemplateRef: ComponentType<T>, options?: any): MatDialogRef<T> {
    this.dialog.closeAll(); // close any existing dialogs
    let config!: MatDialogConfig<any>;
    if (options) {
      if (options.autoFocus === undefined) {
        options.autoFocus = true;
      }
      config = {
        width: options.width,
        data: options,
        autoFocus: options.autoFocus,
        disableClose: options.disableClose ?? false,
      };
    }

    return this.dialog.open(componentOrTemplateRef, config);
  }

  public confirmed(dialogRef: MatDialogRef<any>): Observable<any> {
    return dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }

  public close(dialogRef: MatDialogRef<any>) {
    if (this.isAddScreen) {
      datadogLogs.logger.info(AppConstants.AddJobText);
      DataDogService.addAction(AppConstants.CancelAddJobText);
    }
    dialogRef.close(false);
  }

  public CloseOnEscapeKey(dialogRef: MatDialogRef<any>) {
    dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        dialogRef.close(false);
      }
    });
  }

  public CloseOnBackdropClick(dialogRef: MatDialogRef<any>) {
    dialogRef.backdropClick().subscribe(() => {
      if (!dialogRef.disableClose) {
        dialogRef.close(false);
      }
    });
  }
}
