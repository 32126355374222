import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FormFieldError } from 'src/app/models/form-field-error.model';

@Pipe({
  name: 'errorMessage',
  pure: false,
})
export class ErrorMessagePipe implements PipeTransform {
  transform(control: AbstractControl, errors: FormFieldError[]) {
    for (const error of errors) {
      if (control.hasError(error.key)) {
        return error.message;
      }
    }
    return null;
  }
}
