import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';

@Pipe({
  name: 'invalidDataErrorMessage',
})
export class InvalidDataErrorMessagePipe implements PipeTransform {
  transform(value: string): string {
    return AppConstants.ErrorInvalidData(value);
  }
}
