/// <reference types="@angular/localize" />

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import {
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { AppConstants } from './app/app.constants';

import { AppModule } from './app/app.module';

import { IAppSettings } from './app/models/app-settings.model';

import { environment } from './environments/environment';
import { DataDogService } from './app/services/data-dog.service';
import { LogService } from './app/services/log.service';

if (environment.production) {
  enableProdMode();
}

let isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
window
  .fetch('api/config')
  .then((res) => (res.status !== 401 ? res.json() : null))
  .then((settings: IAppSettings) => {
    (<any>window)[AppConstants.AppSettings] = settings;
    const msalConfig = {
      auth: {
        clientId: settings.azure.ad.clientId,
        authority: settings.azure.ad.authority,
        validateAuthority: settings.azure.ad.validateAuthority,
        redirectUri: settings.azure.ad.redirectUri,
        postLogoutRedirectUri: settings.azure.ad.postLogoutRedirectUri,
        navigateToLoginRequestUrl: settings.azure.ad.navigateToLoginRequestUrl,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
      },
      system: {
        loggerOptions: {
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
        loadFrameTimeout: 30000,
      },
    };

    // enableProdMode
    if (settings.common.environmentName.toLowerCase() !== 'local') {
      enableProdMode();
    }
    LogService.setEnableConsoleLogging(settings.common.environmentName.toLowerCase() === 'local');
    LogService.setEnableDataDogLogging(settings.dataDog.applicationId !== '');
    /********************************************
     // Custom Properties for Datadog  - start
     *********************************************/
    if (settings.dataDog.applicationId !== '') {
      DataDogService.initDataDogRum(settings);

      //Initialize DD logs
      DataDogService.intDataDogLogs(settings);
    }
    /********************************************
     // Custom Properties for Datadog  - end
     *********************************************/

    function MSALInstanceFactory(): IPublicClientApplication {
      return new PublicClientApplication(msalConfig);
    }

    function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
      const protectedResourceMap = new Map<string, Array<string>>();
      protectedResourceMap.set(AppConstants.MicrosoftGraph, ['user.read']);
      protectedResourceMap.set(settings.common.apiPath, [settings.azure.ad.scope]);
      protectedResourceMap.set(settings.common.userManagementApiPath, [settings.azure.ad.scope]);
      return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
      };
    }

    function MSALGuardConfigFactory(): MsalGuardConfiguration {
      return {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user.read', 'openid', 'profile', settings.azure.ad.scope],
        },
        loginFailedRoute: '/login-failed',
      };
    }

    platformBrowserDynamic([
      {
        provide: HTTP_INTERCEPTORS,
        useValue: MsalInterceptor,
        multi: true,
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory,
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory,
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err: any) => console.log(err));
  });
