<div class="header">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <span class="spacer"></span>
  <mat-icon
    *ngIf="data.hideOkButton"
    [attr.data-dd-action-name]="'Close On Dialog'"
    aria-label="close dialog"
    [mat-dialog-close]="false"
    tabindex="-1">
    close
  </mat-icon>
</div>
<div mat-dialog-content class="{{ data.contentClass }}">
  <mat-icon class="{{ data.iconClass }}" *ngIf="data.icon">{{ data.icon }}</mat-icon>
  <p class="dialog-message">{{ data.message }}</p>
</div>
<div mat-dialog-actions *ngIf="!data.hideOkButton">
  <button [attr.data-dd-action-name]="data.confirmText" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ data.confirmText }}
  </button>
</div>
<div class="dialog-content" *ngIf="data.loadSpinner">
  <div class="spinner"></div>
</div>
