import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConstants } from '../app.constants';

import { IAppSettings } from '../models/app-settings.model';
import { Glossary } from '../models/glossary.model';

@Injectable({
  providedIn: 'root'
})
export class GlossaryService {
  private apiPath: string = ((window as any)[AppConstants.AppSettings] as IAppSettings).common.apiPath;

  glossaryListPageIndex!: number;
  glossaryListPageSize!: number;

  constructor(private http: HttpClient) { }

  public getGlossary(): Observable<Glossary[]> {
    return this.http.get<Glossary[]>(`${this.apiPath}api/Glossary`);
  }

  public uploadGlossary(glossaryRequest: FormData): Observable<Object> {
    return this.http.post(`${this.apiPath}api/Glossary`, glossaryRequest);
  }

  public deleteGlossary(glossaryId: number): Observable<Object> {
    const params = new HttpParams().set('glossaryId', glossaryId.toString());
    return this.http.put(`${this.apiPath}api/Glossary`, null, { params });
  }

  public GetDashBoradListPageIndex(): { pageIndex: number; pageSize: number; } {
    return ({
      pageIndex: this.glossaryListPageIndex ? this.glossaryListPageIndex : 0,
      pageSize: this.glossaryListPageSize ? this.glossaryListPageSize : 10,
    });
  }
}
