import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { FeatureType } from 'src/app/core/enums/feature-type.enum';

// if the feature type is call figure or review glossary file selection is optional.
// if the feature type is call redaction, glossary file selection is not needed. should be disabled
// if the feature type is glossary consistency, file selection is mandatory.
// either drop down or drop zone file upload should be selected
export function UploadGlossaryValidator(
  control1: AbstractControl, control2: AbstractControl,
  featureTypeControl: AbstractControl): ValidatorFn {
  return (): ValidationErrors | null => {
    if (control1 && control2) {
      if (featureTypeControl.value === FeatureType.Glossary_Consistency_Check &&
        (control1.value == null || control1.value === '') &&
        (control2.value == null || control2.value === 0)) { // both has empty value
        control1.markAsTouched();
        control1.setErrors({ bothAreEmpty: true });
        return { bothAreEmpty: true };
      }
      if (featureTypeControl.value === FeatureType.Chinese_Characters_Conversion_Check &&
        (control1.value == null || control1.value === '')) {
        control1.markAsTouched();
        control1.setErrors({ selectedGlossaryEmpty: true });
        return { selectedGlossaryEmpty: true };
      }
    }
    control1.setErrors(null);
    return null;
  };
}
