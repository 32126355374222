<div class="header">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content>
  <form class="dialog-container" [formGroup]="goToPageForm">
    <mat-form-field>
      <mat-label>File 1 Page Number</mat-label>
      <input
        matInput
        type="number"
        [formControl]="englishPageControl"
        min="1"
        [max]="data.englishPageCount"
        (keyup)="onChange(chinesePageControl, $any($event.target).value)"
        (change)="onChange(chinesePageControl, $any($event.target).value)"
        required
        appNumbersOnly
        cdkFocusInitial />
      <mat-error *ngIf="englishPageControl.invalid">{{ englishPageControl | errorMessage : pageControlErrors }}</mat-error>
    </mat-form-field>
    <br />
    <mat-form-field>
      <mat-label>File 2 Page Number</mat-label>
      <input
        matInput
        type="number"
        [formControl]="chinesePageControl"
        min="1"
        [max]="data.chinesePageCount"
        (keyup)="onChange(englishPageControl, $any($event.target).value)"
        (change)="onChange(englishPageControl, $any($event.target).value)"
        required
        appNumbersOnly />
      <mat-error *ngIf="chinesePageControl.invalid">{{ chinesePageControl | errorMessage : pageControlErrors }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="onSubmit()" [disabled]="goToPageForm.invalid">{{ data.confirmText }}</button>
  <button mat-raised-button style="margin-right: 10px" [mat-dialog-close]="false">{{ data.cancelText }}</button>
</div>
