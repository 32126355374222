export class FinancialCheckRegion {
  public annotationId!: string;
  public type!: FinancialCheckType;
  public numbers: FinancialCheckNumber[] = [];
}

export class FinancialCheckNumber {
  public value: number = 0;
  public isPercentage: boolean = false;
}

export enum FinancialCheckType {
  Positive = 'positive',
  Negative = 'negative',
}
