import { Component } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { IAppSettings } from 'src/app/models/app-settings.model';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent {
  public readonly constants: typeof AppConstants = AppConstants;
  private appSettings: IAppSettings = (window as any)[AppConstants.AppSettings];
  public contactUsPhone = '';

  ngOnInit(): void {
    this.contactUsPhone = this.appSettings.common.contactUsPhone;
  }
}
