<mat-form-field class="full-width-field no-label upload-dropzone" floatLabel="always">
  <ngx-dropzone
    class="ngx-dropzone"
    [disabled]="disabled"
    #fileInput
    [id]="id"
    [ngClass]="(control.invalid && control.touched) || fileTypeError !== '' ? 'dropzone-error' : ''"
    [accept]="fileType"
    [multiple]="false"
    (change)="onSelect($event)">
    <ngx-dropzone-label class="ngx-dropzone-label" [innerHtml]="dragDropLabel"></ngx-dropzone-label>
    <ngx-dropzone-preview class="ngx-dropzone-preview" *ngFor="let f of selectedFiles" [removable]="true" (removed)="onRemove()">
      <ngx-dropzone-label class="ngx-dropzone-label">
        <div [ngClass]="fileIconClass"></div>
        <p>{{ f.name }}</p>
      </ngx-dropzone-label>
    </ngx-dropzone-preview>
    <div class="fileTypeError" *ngIf="fileTypeError !== ''">{{ fileTypeError }}</div>
  </ngx-dropzone>
  <textarea [formControl]="control" matInput hidden></textarea>
  <mat-error *ngIf="control.invalid">
    {{ control | errorMessage : selectFileError() }}
  </mat-error>
</mat-form-field>
