import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastrService: ToastrService) { }

  public success(title: string, message: string, options: any = { timeOut: 5000, enableHtml: true }): void {
    this.toastrService.success(message, title, options);
  }

  public info(title: string, message: string, options: any = {}): void {
    this.toastrService.info(message, title, options);
  }

  public warning(title: string, message: string, options: any = { timeOut: 10000 }): void {
    this.toastrService.warning(message, title, options);
  }

  public error(title: string, message: string, options: any = { timeOut: 10000 }): void {
    this.toastrService.error(message, title, options);
  }

}
