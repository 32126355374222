export enum AnnotationType {
  Highlight = 1,
  Underline = 2,
  Strikethrough = 3,
  Callout = 4,
  FreeText = 5,
  Straightline = 6,
  Arrow = 7,
  Rectangle = 8,
  Circle = 9,
  Ink = 10,
}
