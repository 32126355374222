import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConstants } from '../app.constants';

import { JobDto } from '../models/job-dto.model';

import { AppDataService } from './app-data.service';
import { FileDownloadDto } from '../models/file-download-dto.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private apiPath = (window as any)[AppConstants.AppSettings].common.apiPath;
  public isToggleOn = false;

  private roleChangedSource = new Subject<number>();
  roleChanged$ = this.roleChangedSource.asObservable();

  public dashboardListPageIndex: number = 0;
  public dashboardListPageSize: number = 10;

  constructor(private http: HttpClient, public appDataService: AppDataService) {}

  public roleChanged(roleId: number): void {
    this.roleChangedSource.next(roleId);
  }

  public GetAllJobs(includeArchivedJobs: boolean): Observable<JobDto[]> {
    return this.http.get<JobDto[]>(`${this.apiPath}api/Job/GetAllJobs?includeArchivedJobs=${includeArchivedJobs}`);
  }

  public AddNewJob(request: FormData): Observable<Object> {
    return this.http.post(`${this.apiPath}api/Job`, request, { headers: new HttpHeaders({ timeout: '900000' }) });
  }

  public CloseJob(jobIdentity: string): Observable<Object> {
    return this.http.put(`${this.apiPath}api/Job/CloseJob/${jobIdentity}`, null);
  }

  public GetBookmarkedPagesReport(jobIdentity: string): Observable<FileDownloadDto> {
    return this.http.get<FileDownloadDto>(`${this.apiPath}api/Job/GetBookmarkedPagesReport/${jobIdentity}`);
  }

  public GetAnnotatedPages(jobIdentity: string, languageId: number): Observable<FileDownloadDto> {
    const requestOptions: object = {
      headers: new HttpHeaders({ timeout: '900000' }),
    };
    return this.http.get<FileDownloadDto>(`${this.apiPath}api/PdfViewer/DownloadAnnotatedPdf/${jobIdentity}/${languageId}`, requestOptions);
  }

  public GetAnnotationSummaryReport(jobIdentity: string): Observable<FileDownloadDto> {
    const requestOptions: object = {
      headers: new HttpHeaders({ timeout: '900000' }),
    };
    return this.http.get<FileDownloadDto>(`${this.apiPath}api/PdfViewer/GetAnnotationSummaryReport/${jobIdentity}`, requestOptions);
  }

  public GetLQAReport(jobIdentity: string): Observable<FileDownloadDto> {
    return this.http.get<FileDownloadDto>(`${this.apiPath}api/Job/GetLQAReport/${jobIdentity}`);
  }
}
