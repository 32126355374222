import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TextBoxComponent } from '@progress/kendo-angular-inputs';

import { AppConstants } from 'src/app/app.constants';

import { FormFieldError } from 'src/app/models/form-field-error.model';

@Component({
  selector: 'app-text-field',
  templateUrl: './kendo-text-field.component.html',
  styleUrls: ['./kendo-text-field.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class KendoTextFieldComponent implements AfterViewInit {
  @ViewChild('textBox', { static: false }) public textBox!: TextBoxComponent;

  @Input() id: string | undefined = undefined;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() required: boolean = false;
  @Input() showSuccessIcon: 'initial' | boolean = false;
  @Input() showErrorIcon: 'initial' | boolean = false;
  @Input() showClearButton: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() rounded: 'small' | 'medium' | 'large' | 'full' = 'medium';
  @Input() fillMode: 'solid' | 'flat' | 'outline' = 'solid';
  @Input() readonly: boolean = false;
  @Input() selectOnFocus: boolean = false;
  @Input() hint: string = '';
  @Input() type?: 'text' | 'password' = 'text';
  @Input() textAlignment: 'left' | 'center' | 'right' = 'left';
  @Input() width?: string = undefined;
  @Input() focus: boolean = false;
  @Input() public maxlength: number | any;
  @Input() public minlength: number | any;
  @Input() errors: FormFieldError[] = [];
  @Input() loading: boolean = false;
  @Input() emailLoader: boolean = false;
  @Input() showInvalidError: boolean = true;

  @Output() valueChange = new EventEmitter<Event>();
  @Output() onblur = new EventEmitter<Event>();

  private viewPassword: boolean = false;
  public readonly constants: typeof AppConstants = AppConstants;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.type === 'password') {
      this.textBox.input.nativeElement.type = this.type;
    }
    if (this.focus) {
      setTimeout(() => this.textBox.focus());
    }
  }

  public onValueChange($event: any) {
    if (this.control.value && this.control.value.trim() == '') {
      this.control.setErrors({ invalidData: true });
    }
    this.valueChange.emit($event);
  }

  public onShowPassword() {
    if (!this.viewPassword) {
      this.textBox.input.nativeElement.type = 'text';
      this.viewPassword = true;
    } else {
      this.textBox.input.nativeElement.type = 'password';
      this.viewPassword = false;
    }
  }

  public onBlur() {
    setTimeout(() => {
      if (this.emailLoader && this.loading) {
        this.hint = AppConstants.StillLoadingData;
        this.textBox.focus();
      }
    });
  }

  public onKeydownEvent(event: KeyboardEvent): void {
    setTimeout(() => {
      if (this.emailLoader && event.code === 'Tab' && (this.loading || this.control.invalid)) {
        this.hint = AppConstants.StillLoadingData;
        event.preventDefault();
        this.textBox.focus();
      }
    });
  }

  public afterValueChanged(event:any) {
    console.log(event);
  }
}
