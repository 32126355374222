import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-privacy-notice',
  templateUrl: './data-privacy-notice.component.html',
  styleUrls: ['./data-privacy-notice.component.css'],
})
export class DataPrivacyNoticeComponent {
  constructor(private route: Router) {
    window.scroll(0, 0);
  }

  public close(): void {
    this.route.navigate(['/jobs']);
  }
}
