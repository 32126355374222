import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import tz from 'dayjs/esm/plugin/timezone';

import { AppConstants } from 'src/app/app.constants';

import { AcsCompanyDetail } from 'src/app/models/acs-company-details.model';
import { AcsTeam } from '../../models/acs-team.model';
import { FunctionalRole } from 'src/app/models/functional-role.model';
import { ReportRequest } from 'src/app/models/report-request.model';
import { UserDto } from 'src/app/models/user-dto.model';

import { CommonService } from 'src/app/services/common.service';
import { DataDogService } from 'src/app/services/data-dog.service';
import { FileStoreService } from 'src/app/services/file-store.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReportService } from 'src/app/services/report.service';

dayjs.extend(utc);
dayjs.extend(tz);
@Component({
  selector: 'app-usage-report',
  templateUrl: './usage-report.component.html',
  styleUrls: ['./usage-report.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UsageReportComponent implements OnInit, OnDestroy {
  public readonly constants: typeof AppConstants = AppConstants;

  public readonly jobTypeControl: FormControl = new FormControl([]);
  public readonly glossaryControl: FormControl = new FormControl([]);
  public readonly annotationControl: FormControl = new FormControl([]);
  public readonly annotationSideControl: FormControl = new FormControl([]);

  private selectedFeatureTypes!: number[];
  private selectedAnnotationSides!: number[];
  public isSubmitButtonDisabled = false;
  public isProgressBarShown = false;

  public ranges: any = this.getDateRangeValues();

  public glossaryList = [
    { id: true, value: 'Yes' },
    { id: false, value: 'No' },
  ];
  public annotationList = [
    { id: true, value: 'Yes' },
    { id: false, value: 'No' },
  ];
  public annotationSideList = [
    { id: 1, value: 'File 1' },
    { id: 2, value: 'File 2' },
  ];

  public users: UserDto[] = [];
  public readonly userFilter: FormControl = new FormControl();
  public readonly userNameControl: FormControl = new FormControl([]);
  public filteredUsersList: ReplaySubject<UserDto[]> = new ReplaySubject<UserDto[]>();

  public functionalRoles: FunctionalRole[] = [];
  public readonly functionalRolesFilter: FormControl = new FormControl();
  public readonly functionalRolesControl: FormControl = new FormControl([]);
  public filteredfunctionalRolesList: ReplaySubject<FunctionalRole[]> = new ReplaySubject<FunctionalRole[]>();

  public acsTeamsData: AcsTeam[] = [];
  public readonly acsTeamFilter: FormControl = new FormControl();
  public readonly acsTeamNameControl: FormControl = new FormControl([]);
  public filteredAcsTeamsList: ReplaySubject<AcsTeam[]> = new ReplaySubject<AcsTeam[]>();

  public companies: AcsCompanyDetail[] = [];
  public readonly companyNameFilter: FormControl = new FormControl();
  public readonly companyNameControl: FormControl = new FormControl([]);
  public filteredCompanyList: ReplaySubject<AcsCompanyDetail[]> = new ReplaySubject<AcsCompanyDetail[]>();

  public readonly reportManagementForm: FormGroup = this.formBuilder.group({
    dateRange: new FormControl(
      {
        startDate: dayjs().subtract(1, 'day'),
        endDate: dayjs(),
      },
      Validators.required
    ),
    jobTypeControl: this.jobTypeControl,
    glossaryControl: this.glossaryControl,
    userNameControl: this.userNameControl,
    acsTeamNameControl: this.acsTeamNameControl,
    functionalRolesControl: this.functionalRolesControl,
    annotationControl: this.annotationControl,
    annotationSideControl: this.annotationSideControl,
    companyNameControl: this.companyNameControl,
  });

  protected onDestroy = new Subject<void>();

  public minDate = dayjs().subtract(3, 'year');
  public maxDate = dayjs();

  constructor(
    private notificationService: NotificationService,
    private reportService: ReportService,
    private fileStoreService: FileStoreService,
    private dialogRef: MatDialogRef<UsageReportComponent>,
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isProgressBarShown = true;
    await this.reportService.loadReportMasterData().then(
      () => {
        this.users = this.reportService.users;
        this.functionalRoles = this.reportService.functionalRoles;
        this.companies = this.reportService.companies;
        this.acsTeamsData = this.reportService.acsTeams;
        this.filteredUsersList.next(this.users);
        this.filteredfunctionalRolesList.next(this.functionalRoles);
        this.filteredAcsTeamsList.next(this.acsTeamsData);
        this.filteredCompanyList.next(this.companies);
        this.isProgressBarShown = false;
      },
      () => {
        this.isProgressBarShown = false;
        this.notificationService.error('', this.constants.ErrorFetcingTccUsersTeams);
      }
    );
  }

  ngAfterViewInit(): void {
    this.userFilter.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterUserList();
    });
    this.functionalRolesFilter.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterTeamList();
    });
    this.acsTeamFilter.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterAcsTeamList();
    });
    this.companyNameFilter.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterCompanyList();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public onSubmit(): void {
    this.commonService.showSpinner.next(true);
    this.isProgressBarShown = true;
    this.isSubmitButtonDisabled = true;

    this.selectedFeatureTypes = this.jobTypeControl.value.filter((g: number) => g !== 100);
    this.selectedAnnotationSides = this.annotationSideControl.value.filter((g: number) => g !== 100);

    const dateRangeControl: AbstractControl = this.reportManagementForm.controls['dateRange'];
    if (!(dateRangeControl.value != null && dateRangeControl.value.startDate != null && dateRangeControl.value.endDate != null)) {
      this.notificationService.error('', AppConstants.ErrorCreateReport);
      return;
    }
    const dateFrom: string = dateRangeControl.value.startDate.format(AppConstants.DateFormat);
    const dateTo: string = dateRangeControl.value.endDate.format(AppConstants.DateFormat);

    const reportRequest: ReportRequest = {
      dateFrom: dayjs(dateFrom).utc().format(AppConstants.DateFormat),
      dateTo: dayjs(dateTo).utc().format(AppConstants.DateFormat),
    };

    if (this.jobTypeControl.value) {
      reportRequest.featureTypeIds = this.selectedFeatureTypes;
      reportRequest.featureTypeFilter = AppConstants.FeatureType.filter((s) => this.selectedFeatureTypes.includes(s.Id))
        .map((x) => x.TypeName)
        .join('; ');
    }
    if (this.glossaryControl.value) {
      reportRequest.isGlossary = this.glossaryControl.value;
      reportRequest.glossaryFilter = this.getIsGlossaryAddedStatus(this.glossaryControl.value);
    }
    if (this.userNameControl.value) {
      reportRequest.userObjectIds = this.userNameControl.value.map((x: { userObjectId: string }) => x.userObjectId);
      reportRequest.userNameFilter = this.userNameControl.value.map((x: { displayName: string }) => x.displayName).join('; ');
    }
    if (this.functionalRolesControl.value) {
      reportRequest.functionalRoleIdentities = this.functionalRolesControl.value.map(
        (x: { functionalRoleIdentity: number }) => x.functionalRoleIdentity
      );
      reportRequest.teamNameFilter = this.functionalRolesControl.value.map((x: { name: string }) => x.name).join('; ');
    }
    if (this.acsTeamNameControl.value) {
      reportRequest.acsTeamIds = this.acsTeamNameControl.value.map((x: { teamIdentity: number }) => x.teamIdentity);
      reportRequest.acsTeamNameFilter = this.acsTeamNameControl.value.map((x: { teamName: string }) => x.teamName).join('; ');
    }
    if (this.annotationControl.value) {
      reportRequest.isAnnotation = this.annotationControl.value;
      reportRequest.annotationFilter = this.getIsGlossaryAddedStatus(this.annotationControl.value);
    }
    if (this.annotationSideControl.value) {
      reportRequest.annotationSideIds = this.selectedAnnotationSides;
      reportRequest.annotationSideFilter = this.annotationSideList
        .filter((s) => this.selectedAnnotationSides.includes(s.id))
        .map((x) => x.value)
        .join('; ');
    }
    if (this.companyNameControl.value) {
      reportRequest.companyIds = this.companyNameControl.value.map((x: { companyIdentity: number }) => x.companyIdentity);
      reportRequest.companyNameFilter = this.companyNameControl.value.map((x: { companyName: string }) => x.companyName).join('; ');
    }

    this.reportService.GenerateReport(reportRequest).subscribe({
      next: (data: any) => {
        const fileName = this.getFileName(AppConstants.UsageReportLabel);
        this.fileStoreService.downloadFileFn(data, fileName);
        DataDogService.logDownloadFile(data, fileName);
        this.dialogRef.close();
        this.isProgressBarShown = false;
        this.notificationService.success('', AppConstants.SuccessCreateReport);
        this.commonService.showSpinner.next(false);
      },
      error: (error: string) => {
        this.isProgressBarShown = false;
        if (error.includes(AppConstants.AccessDeniedLabel)) {
          this.notificationService.error('', AppConstants.ErrorAccessDenied);
        } else {
          this.notificationService.error('', AppConstants.ErrorCreateReport);
        }
        this.commonService.showSpinner.next(false);
      },
    });
  }

  private getIsGlossaryAddedStatus(isGlossary: boolean[]): string {
    if (isGlossary.length > 1) {
      return 'Yes; No';
    }
    if (isGlossary.includes(true)) {
      return 'Yes';
    }
    if (isGlossary.includes(false)) {
      return 'No';
    }
    return '';
  }

  public getUsersFilteredList(): UserDto[] {
    if (!this.userFilter.value) {
      return this.users;
    }
    return this.users.filter((x) => x.displayName.toLowerCase().includes(this.userFilter.value.toLowerCase()));
  }

  public getTeamsFilteredList(): FunctionalRole[] {
    if (!this.functionalRolesFilter.value) {
      return this.functionalRoles;
    }
    return this.functionalRoles.filter((x) => x.name.toLowerCase().includes(this.functionalRolesFilter.value.toLowerCase()));
  }

  public getCompanyFilteredList(): AcsCompanyDetail[] {
    if (!this.companyNameFilter.value) {
      return this.companies;
    }
    return this.companies.filter((x) => x.companyName.toLowerCase().includes(this.companyNameFilter.value.toLowerCase()));
  }

  public getAcsTeamsFilteredList(): AcsTeam[] {
    if (!this.acsTeamFilter.value) {
      return this.acsTeamsData;
    }
    return this.acsTeamsData.filter((x) => x.teamName.toLowerCase().includes(this.acsTeamFilter.value.toLowerCase()));
  }

  private filterUserList(): void {
    if (!this.users) {
      return;
    }
    let search = this.userFilter.value;
    if (!search) {
      this.filteredUsersList.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUsersList.next(this.users.filter((r) => r.displayName.toLowerCase().indexOf(search) > -1));
  }

  private filterTeamList(): void {
    if (!this.functionalRoles) {
      return;
    }
    let search = this.functionalRolesFilter.value;
    if (!search) {
      this.filteredfunctionalRolesList.next(this.functionalRoles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredfunctionalRolesList.next(this.functionalRoles.filter((r) => r.name.toLowerCase().indexOf(search) > -1));
  }

  private filterAcsTeamList(): void {
    if (!this.acsTeamsData) {
      return;
    }
    let search = this.acsTeamFilter.value;
    if (!search) {
      this.filteredAcsTeamsList.next(this.acsTeamsData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredAcsTeamsList.next(this.acsTeamsData.filter((r) => r.teamName.toLowerCase().indexOf(search) > -1));
  }

  private filterCompanyList(): void {
    if (!this.companies) {
      return;
    }
    let search = this.companyNameFilter.value;
    if (!search) {
      this.filteredCompanyList.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCompanyList.next(this.companies.filter((r) => r.companyName.toLowerCase().indexOf(search) > -1));
  }

  private getFileName = (name: string) => {
    const formattedFromDate = dayjs().format(AppConstants.DateFormatForFileName);
    return `${name}_${formattedFromDate}.xlsx`;
  };

  public onKeyUpType(allOption: any, typeSelect: any, list: any[], control: AbstractControl, id?: string) {
    const activeMatOption = typeSelect.options.filter((x: any) => x._element.nativeElement.outerHTML.includes('mat-mdc-option-active'))[0];
    if (activeMatOption?.value === 100) {
      this.toggleSelectAll(allOption, control, list, id);
    } else {
      this.toggleSelectOne(allOption, control, list, id);
    }
  }

  public toggleSelectOne(allOption: any, control: AbstractControl, list: any, id?: string): void {
    if (control.value.length === list.length) {
      allOption.select();
      id ? control.patchValue(list.map((item: any) => item[id])) : control.patchValue(list);
      allOption._selected = true;
    } else {
      allOption.deselect();
    }
  }

  public toggleSelectAll(allOption: any, control: AbstractControl, list: any, id?: string): void {
    if (allOption._selected) {
      id ? control.patchValue(list.map((item: any) => item[id])) : control.patchValue(list);
      allOption._selected = true;
    } else {
      control.patchValue([]);
    }
  }

  public onClosed(allOption: any, control: AbstractControl, list: any) {
    if (control.value.length === list.length) {
      allOption.select();
      allOption._selected = true;
    } else {
      allOption.deselect();
    }
  }

  private getDateRangeValues(): any {
    return {
      'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
      'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
      'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
      'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
      'Last 6 Month': [dayjs().subtract(6, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
      'Last 1 Year': [dayjs().subtract(12, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    };
  }

  public hasAny(control: AbstractControl): boolean {
    return control.value && control.value.length > 0;
  }

  public clearControl(control: AbstractControl, allOption: any, e: MouseEvent): void {
    control.setValue([]);
    allOption.deselect();
    e.stopPropagation();
  }
}
