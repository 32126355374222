import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

import { AppConstants } from 'src/app/app.constants';

import { FormFieldError } from 'src/app/models/form-field-error.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  @Input() disabled!: boolean;
  @Input() id!: string;
  @Input() control!: FormControl;
  @Input() fileType!: string;
  @Input() dragDropLabel?: string;
  @Input() uploadedFileName?: string;
  @Output() fileSelected = new EventEmitter();
  @Output() fileRemoved = new EventEmitter();

  public readonly appConstants: typeof AppConstants = AppConstants;

  public selectedFiles!: File[];
  public fileTypeError = '';
  public fileIconClass = '';

  public selectFileError(): FormFieldError[] {
    return [
      {
        key: 'min',
        message:
          this.fileType === AppConstants.UploadGlossaryAllowedFileTypes ? AppConstants.InvalidGlossaryFile : AppConstants.InvalidFilePdfDoc,
      },
    ];
  }

  ngOnInit(): void {
    this.selectedFiles = [];
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    this.selectedFiles.length = 0;
    if (event.addedFiles.length === 0 && event.rejectedFiles.length !== 0) {
      const rejectedFile = event.rejectedFiles[0];
      if (rejectedFile.reason === 'type') {
        if (this.fileType === AppConstants.UploadGlossaryAllowedFileTypes) {
          this.fileTypeError = AppConstants.ErrorInvalidFileTypeExcel;
        } else {
          this.fileTypeError = AppConstants.ErrorInvalidFileType;
        }
      }
      this.control.setValue(0);
      this.control.markAsTouched();
      return;
    }
    this.fileTypeError = '';
    const uploadedFile = event.addedFiles[0];
    this.control.setValue(1);
    const fileType = uploadedFile.name.split('.').pop()!.toLowerCase();
    this.fileIconClass = fileType;
    this.selectedFiles.push(uploadedFile);
    this.fileSelected.next(uploadedFile);
  }

  public onRemove() {
    this.fileTypeError = '';
    this.control.markAsTouched();
    this.removeFile();
  }

  public removeFile() {
    this.selectedFiles.length = 0;
    this.control.setValue(0);
    this.fileRemoved.emit();
  }
}
