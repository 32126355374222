<kendo-formfield id="text-box-field">
  <kendo-label [for]="id" [text]="label" *ngIf="label"><span *ngIf="required" class="asterisk"></span></kendo-label>
  <app-icon-button class="eye" icon="eye" (click)="onShowPassword()" *ngIf="type === 'password'"></app-icon-button>
  <kendo-textbox
    #textBox
    [id]="id"
    [formControl]="control"
    [required]="required"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [size]="size"
    [rounded]="rounded"
    [fillMode]="fillMode"
    [placeholder]="placeholder"
    [showSuccessIcon]="showSuccessIcon"
    [showErrorIcon]="showErrorIcon"
    [clearButton]="showClearButton"
    [readonly]="readonly"
    [selectOnFocus]="selectOnFocus"
    [style.width]="width"
    [ngClass]="{ 'center-align': textAlignment === 'center', 'right-align': textAlignment === 'right' }"
    (valueChange)="onValueChange($event)"
    (afterValueChanged)="afterValueChanged($event)"
    (blur)="onBlur(); onblur.emit($event)"
    (keydown)="onKeydownEvent($event)">
    <ng-template kendoTextBoxSuffixTemplate>
      <kendo-icon name="loading" [themeColor]="'primary'" class="loader" *ngIf="loading"></kendo-icon>
    </ng-template>
  </kendo-textbox>
  <kendo-formhint *ngIf="hint">{{ hint }}</kendo-formhint>
  <kendo-formerror *ngIf="(control.touched || control.dirty) && control.invalid">
    <span *ngIf="control.errors?.['invalidData'] && showInvalidError">{{ label | invalidDataErrorMessage }}</span>
    {{ control | errorMessage : errors }}
  </kendo-formerror>
</kendo-formfield>
