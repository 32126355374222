import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent {
  @Input() currentPageNumber!: number;
  @Input() pageCount!: number;
  @Input() disabled!: boolean;
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() navigate = new EventEmitter();

  public readonly appConstants: typeof AppConstants = AppConstants;

  public invalidStyle = '';

  public onPreviousClick($event: any): void {
    $event.stopPropagation();
    this.previous.emit();
  }

  public onNextClick($event: any): void {
    $event.stopPropagation();
    this.next.emit();
  }

  public onEnter($event: any): void {
    $event.stopPropagation();
    if (this.currentPageNumber !== null && this.currentPageNumber > 0 && this.currentPageNumber <= this.pageCount) {
      $event.target.blur(); // to leave focus from the text box
      this.navigate.next(this.currentPageNumber);
    }
  }

  public onChange($event: any, pageNumberTooltip: MatTooltip): void {
    $event.stopPropagation();
    if (this.currentPageNumber === null ||
      this.currentPageNumber > this.pageCount || this.currentPageNumber === 0) {
      this.invalidStyle = 'invalid';
      pageNumberTooltip.show();
    } else {
      this.invalidStyle = '';
      pageNumberTooltip.hide();
    }
  }

  public getToolTip(): string {
    return this.invalidStyle === '' ? '' : AppConstants.ErrorNotValidPageNumber;
  }

  public isFirstPage() {
    return this.currentPageNumber === 1;
  }

  public isLastPage() {
    return this.currentPageNumber === this.pageCount;
  }
}
