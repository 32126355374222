import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { filter, Observable, of, switchMap } from 'rxjs';

import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, SsoSilentRequest } from '@azure/msal-browser';
import { AppConstants } from 'src/app/app.constants';
import { ValidUserDetailsResponse } from 'src/app/models/valid-user-details-response.model';
import { UserService } from 'src/app/services/user.service';

@Injectable({ providedIn: 'root' })
export class CanActivateGuard implements CanActivate {
  constructor(
    private msalService: MsalService,
    private router: Router,
    private userService: UserService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap(async () => {
        if (this.msalService.instance.getAllAccounts().length > 0) {
          if (route.queryParams['id']?.length > 0) {
            if (route.queryParams['id'] != this.msalService.instance.getActiveAccount()?.username) {
              localStorage.clear();

              await this.ssoSilent(route, state);
            }

            this.router.navigate([route.routeConfig!.path]);
          }
          return true;
        } else {
          this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
          this.ssoSilent(route, state);
        }
        return false;
      })
    );
  }
  ssoSilent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<void>((resolve) => {
      this.msalService.ssoSilent(this.getssorequest(route)).subscribe((result) => {
        this.msalService.instance.setActiveAccount(result.account);
        this.router.navigate([route.queryParams['id']?.length > 0 ? route.routeConfig!.path : state.url]);
        if (localStorage.getItem(AppConstants.ReadAcceptClientUser) != 'true') {
          this.userService.IsValidUser(route.queryParams['id']).subscribe((res: ValidUserDetailsResponse) => {
            localStorage.setItem(AppConstants.ReadAcceptClientUser, res.termsAcceptance.toString());
          });
        }
        resolve();
      });
    });
  }
  getssorequest(route: ActivatedRouteSnapshot) {
    return {
      ...this.msalGuardConfig.authRequest,
      loginHint: this.getLoginHint(route),
    } as SsoSilentRequest;
  }
  getLoginHint(route: ActivatedRouteSnapshot) {
    return route.queryParams['id'].length > 0
      ? decodeURIComponent(route.queryParams['id'])
      : localStorage.getItem(AppConstants.AcsUserEmailKeyName);
  }
}
