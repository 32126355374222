export enum AnnotationColor {
  'Light purple' = '#eda5de',
  Tan = '#d2b48c',
  Mauve = '#b784a7',
  Peach = '#ffdaba',
  Brown = '#88401b',
  'Light yellow' = '#ffff80',
  Red = '#eb4760',
  'PdfViewer Red' = '#ff0000',
}
