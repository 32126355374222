<span kendoTooltip [title]="toolTip">
  <button
    #btn
    kendoButton
    class="app-button"
    [type]="type"
    [size]="size"
    [rounded]="rounded"
    [disabled]="disabled"
    [fillMode]="fillMode"
    [themeColor]="themeColor"
    [imageUrl]="imageUrl"
    [icon]="icon"
    (click)="clicked.emit($event); $event.stopPropagation()">
    <kendo-svg-icon *ngIf="svgIcon" [icon]="svgIcon"></kendo-svg-icon>
    {{ label }}
  </button>
</span>
