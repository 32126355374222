<div class="header">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content>
  <p class="dialog-message">{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [attr.data-dd-action-name]="data.confirmText" [mat-dialog-close]="true" cdkFocusInitial>
    {{ data.confirmText }}
  </button>
  <button mat-raised-button [attr.data-dd-action-name]="data.cancelText" style="margin-right: 10px" [mat-dialog-close]="false">
    {{ data.cancelText }}
  </button>
</div>
