import { Component, ViewEncapsulation } from '@angular/core';

import { DialogRef } from '@progress/kendo-angular-dialog';

import { AppConstants } from 'src/app/app.constants';

import { ActionSource } from 'src/app/core/enums/action-source.enum';
import { AppList } from 'src/app/core/enums/application-type.enum';
import { DocumentLanguageType } from 'src/app/core/enums/document-language-type.enum';
import { DocumentType } from 'src/app/core/enums/document-type.enum';

import { GetAcceptRequest } from 'src/app/models/get-accept-request';
import { GetSupportDocumentRequest } from 'src/app/models/get-support-document-request.model';
import { GetSupportDocumentResponse } from 'src/app/models/get-support-document-response.model';

import { CommonService } from 'src/app/services/common.service';

import { SupportDocumentService } from 'src/app/services/support-document.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-read-accept-section',
  templateUrl: './read-accept-section.component.html',
  styleUrls: ['./read-accept-section.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ReadAcceptSectionComponent {
  public data?: GetSupportDocumentResponse;
  public isAccepted: boolean = false;

  public readonly constants: typeof AppConstants = AppConstants;

  constructor(
    private userService: UserService,
    private supportDocumentService: SupportDocumentService,
    private dialog: DialogRef,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getSupportDocument();
  }

  public getSupportDocument() {
    const request: GetSupportDocumentRequest = {
      applicationIdentity: AppList.ACS,
      documentType: DocumentType.TermsAndConditionsAndDataPrivacyNotice,
      languageType: DocumentLanguageType.English,
    };
    this.supportDocumentService.getSupportDocument(request).subscribe((response) => {
      this.data = response;
    });
  }

  public onSelectChange(event: boolean): void {
    this.isAccepted = event;
  }

  public onAcceptClick(): void {
    this.commonService.showSpinner.next(true);
    const request: GetAcceptRequest = {
      applicationIdentity: AppList.ACS,
      actionSource: ActionSource.TermsAcceptance,
    };
    this.userService.UpdateTermsAcceptance(request).subscribe(() => {
      this.commonService.showSpinner.next(false);
      this.dialog.close();
    });
  }
}
