<kendo-dialog-titlebar (close)="onClose()"> Import Page(s) </kendo-dialog-titlebar>
<div *ngIf="listViewData.length === 0" class="no-record">No Pages Found</div>
<kendo-listview
  [data]="listViewData"
  [height]="600"
  containerClass="row"
  itemClass="{{ isEditClicked ? 'col-lg-6' : 'col-lg-4 preview-margin-class' }} margin-class">
  <ng-template kendoListViewHeaderTemplate>
    <app-text-field
      class="import-text-field"
      [control]="selectedPagesControl"
      [errors]="selectedPagesErrors"
      [label]="'Selected Pages'"
      [readonly]="!isEditClicked"
      [showClearButton]="true"
      (valueChange)="onValueChange($event)">
    </app-text-field>
    <app-button
      *ngIf="!isEditClicked"
      class="edit-button"
      [label]="'Edit'"
      [size]="'large'"
      [themeColor]="'primary'"
      (clicked)="onEdit()"></app-button>
    <span class="import-action-button" *ngIf="isEditClicked">
      <app-button
        [label]="constants.UpdateLabel"
        [size]="'large'"
        [themeColor]="'primary'"
        [disabled]="selectedPagesControl.invalid || !selectedPagesControl.dirty"
        (clicked)="onSubmit()"></app-button>
      <app-button [label]="constants.CancelLabel" [size]="'large'" [themeColor]="'base'" (clicked)="onCancel()"></app-button>
    </span>
  </ng-template>
  <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isLast="isLast">
    <kendo-card [ngClass]="isLast ? 'last-image' : ''" width="auto" (click)="onImageClick(dataItem, $event, !isEditClicked)">
      <img
        kendoCardMedia
        [src]="'data:image/png;base64,' + dataItem.content"
        alt="{{ dataItem.pdfFilePath }}"
        [ngClass]="isEditClicked ? 'import-pages' : 'preview-import-pages'" />
      <strong class="d-block text-center">Page {{ dataItem.pageSequence }}</strong>
      <input
        *ngIf="isEditClicked"
        #checkBox
        class="image-checkbox import-page-{{ dataItem.pageSequence }}"
        type="checkbox"
        rounded="none"
        kendoCheckBox />
    </kendo-card>
  </ng-template>
</kendo-listview>
