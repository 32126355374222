import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css'],
})
export class TermsAndConditionsComponent {
  constructor(private route: Router) {
    window.scroll(0, 0);
  }

  public close(): void {
    this.route.navigate(['/jobs']);
  }
}
