import { Component, Input } from '@angular/core';
import { MagnificationService, FormDesignerService, FormFieldsService } from '@syncfusion/ej2-angular-pdfviewer';

@Component({
  selector: 'app-acs-pdf-viewer',
  templateUrl: './acs-pdf-viewer.component.html',
  styleUrls: ['./acs-pdf-viewer.component.css'],
  providers: [MagnificationService, FormDesignerService, FormFieldsService],
})
export class AcsPdfViewerComponent {
  @Input() loadPdf!: any;

  public pdfViewer!: any;
  public resource: string = location.protocol + '//' + location.host + '/assets/ej2-pdfviewer-lib';
  public zoommode = 'Default';
  public toolbarSettings = {
    showTooltip: false,
    toolbarItems: [] ,
  };

  ngOnInit(): void {
  }
}
