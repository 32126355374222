import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';

import { Glossary } from 'src/app/models/glossary.model';

import { DataDogAction } from 'src/app/core/enums/datadog-action.enum';
import { GlossaryStatus } from 'src/app/core/enums/glossary-status.enum';
import { SignalRConnectionStatus } from '../../core/enums/signalr-connection-status.enum';

import { CommonService } from 'src/app/services/common.service';
import { DataDogService } from 'src/app/services/data-dog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FileStoreService } from 'src/app/services/file-store.service';
import { GlossaryService } from 'src/app/services/glossary.service';
import { LogService } from 'src/app/services/log.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SignalRService } from '../../services/signalr.service';

import { ConfirmDialogComponent as ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog.component';
import { UploadGlossaryComponent } from './upload-glossary/upload-glossary.component';

@Component({
  selector: 'app-glossary-management',
  templateUrl: './glossary-management.component.html',
  styleUrls: ['./glossary-management.component.css'],
})
export class GlossaryManagementComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public appConstants: typeof AppConstants = AppConstants;

  public isNoData = false;
  public readonly glossarySearchControl = new FormControl();
  public displayedColumns: string[] = ['GlossaryID', 'Name', 'FeatureType', 'FileName', 'Status', 'CreatedDate', 'Action'];
  public dataSource = new MatTableDataSource<Glossary>();
  private paginatorValue!: { pageIndex: number; pageSize: number };
  private paginatorIndexSize!: number;
  private signalrConnectionSubscription?: Subscription;
  public readonly glossaryDownload: string = DataDogAction.GlossaryDownload;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private fileStoreService: FileStoreService,
    private glossaryService: GlossaryService,
    private notificationService: NotificationService,
    private signalRService: SignalRService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.getGlossary();
  }

  ngAfterViewInit(): void {
    this.signalrConnectionSubscription = this.signalRService.connectionStateSubject.subscribe(() => {
      this.joinSignalRGroup();
    });
    this.subscribeSignalRMessages();
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.signalRService.leaveGroup(`glossarylist`);
    this.signalRService.hubConnection.off('BroadcastMessage');
    if (this.signalrConnectionSubscription) {
      this.signalrConnectionSubscription.unsubscribe();
    }
  }

  public openDialog(): void {
    const dialogRef = this.dialogService.open(UploadGlossaryComponent, {
      width: '515px',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getGlossary();
      }
    });
  }

  public getGlossary() {
    this.glossarySearchControl.setValue('');
    this.commonService.showSpinner.next(true);
    this.glossaryService.getGlossary().subscribe({
      next: (data) => {
        data.forEach((value) => {
          value.glossaryStatus = GlossaryStatus[value.status];
        });
        this.dataSource = new MatTableDataSource(data);
        this.filterTableData();
        this.dataSource.sort = this.sort;
        this.commonService.showSpinner.next(false);
      },
      error: () => {
        this.notificationService.error('', AppConstants.ErrorFetchingGlossary);
        this.filterTableData();
        this.commonService.showSpinner.next(false);
      },
    });
  }

  public filterGlossaryData() {
    this.paginatorIndexSize = this.dataSource.paginator!.pageSize;
    let searchText = this.glossarySearchControl.value;
    if (searchText) {
      searchText = searchText.trim().toLowerCase();
      this.dataSource.filter = searchText;
      this.dataSource.filterPredicate = function (data, searchText): boolean {
        return (
          data.glossaryId.toString().includes(searchText) ||
          data.name.toLowerCase().includes(searchText) ||
          data.featureType.toLowerCase().includes(searchText) ||
          data.fileName.toLowerCase().includes(searchText) ||
          data.createdDate.toLowerCase().includes(searchText)
        );
      };
    } else {
      this.dataSource = new MatTableDataSource(this.dataSource.data);
    }
    return this.filterTableData();
  }

  private filterTableData() {
    this.isNoData = this.dataSource.filteredData.length === 0;
    this.dataSource.paginator = this.paginator;
    this.paginatorValue = this.glossaryService.GetDashBoradListPageIndex();
    this.dataSource.paginator.pageIndex = this.paginatorValue.pageIndex;
    this.dataSource.paginator.pageSize = this.paginatorValue.pageSize;
    this.dataSource.paginator.page.next({
      length: this.paginator.getNumberOfPages(),
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    });
    this.glossaryService.glossaryListPageIndex = 0;
    return this.dataSource;
  }

  public openFile(blobName: string, fileName: string) {
    DataDogService.logDownloadFile(blobName, fileName, true);
    this.fileStoreService.downloadFile(blobName, fileName);
  }

  public navigateToHomePage() {
    return this.router.navigate(['/jobs']);
  }

  public getGlossaryStatus(glossaryStatus: number): string {
    return GlossaryStatus[glossaryStatus];
  }

  public deleteGlossary(glossaryId: number) {
    this.glossaryService.glossaryListPageIndex = this.paginator.pageIndex;
    this.glossaryService.glossaryListPageSize = this.paginator.pageSize;
    const dialogRef = this.dialogService.open(ConfirmDialog, AppConstants.DeleteGlossaryConfirmDialogOptions);
    this.dialogService.confirmed(dialogRef).subscribe((confirmed) => {
      if (confirmed) {
        this.commonService.showSpinner.next(true);
        this.glossaryService.deleteGlossary(glossaryId).subscribe({
          next: () => {
            this.getGlossary();
            this.commonService.showSpinner.next(false);
            this.notificationService.success('', AppConstants.SuccessDeleteGlossary);
          },
          error: () => {
            this.commonService.showSpinner.next(false);
            this.notificationService.error('', AppConstants.ErrorDeleteGlossary);
          },
        });
      }
    });
  }

  private joinSignalRGroup() {
    if (this.signalRService.connectionState === SignalRConnectionStatus.Connected) {
      this.signalRService.joinGroup(`glossarylist`);
    }
  }

  private subscribeSignalRMessages(): void {
    this.signalRService.hubConnection.on('BroadcastMessage', (signalrData: Glossary) => {
      LogService.Debug(signalrData);
      let glossaryData = this.dataSource.data;
      const index = glossaryData.findIndex((x) => x.glossaryId === signalrData.glossaryId);
      if (index > -1) {
        // glossary already exists
        if (signalrData.status === GlossaryStatus.InActive) {
          glossaryData.splice(index, 1);
          return;
        } else {
          glossaryData[index].status = signalrData.status;
        }
      } else {
        if (signalrData.status === GlossaryStatus.InActive) {
          return;
        }
        // glossary does not exists
        if (signalrData.status) {
          signalrData.glossaryStatus = GlossaryStatus[signalrData.status];
        }
        glossaryData.push(signalrData);
        glossaryData.sort((a, b) => b.glossaryId - a.glossaryId);
      }
      this.dataSource.data = [...glossaryData];
      this.filterTableData();
    });
  }
}
