import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { SVGIcon } from '@progress/kendo-svg-icons';

import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-button',
  templateUrl: './kendo-button.component.html',
  styleUrls: ['./kendo-button.component.css'],
})
export class KendoButtonComponent implements AfterViewInit {
  @ViewChild('btn', { static: true }) btn!: ElementRef;

  @Input() label: string = 'Button';
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Input() icon: string = '';
  @Input() svgIcon?: SVGIcon;
  @Input() imageUrl: string = '';
  @Input() toolTip: string = '';
  @Input() iconSuffix: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() rounded: 'small' | 'medium' | 'large' | 'full' = 'medium';
  @Input() fillMode: 'solid' | 'flat' | 'outline' | 'clear' | 'link' = 'solid';
  @Input() themeColor: 'base' | 'primary' | 'secondary' | 'tertiary' | 'info' | 'success' | 'warning' | 'error' | 'dark' | 'light' | 'inverse' = 'base';
  @Input() disabled: boolean = false;
  @Output() clicked = new EventEmitter<Event>();

  constructor(private commonService: CommonService) { }

  ngAfterViewInit(): void {
    if ((this.icon || this.imageUrl || this.svgIcon) && this.iconSuffix) {
      this.commonService.waitForElement(this.btn, 'span.k-icon, .k-svg-icon').then((spanElement: any) => {
        if (spanElement) {
          spanElement.style.order = '2';
        }
      });
    }
  }
}
