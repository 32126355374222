import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent {

  public selectedRole!: number;

  public setRoleId(roleId: number): void {
    this.selectedRole = roleId;
  }

}
