<h1 mat-dialog-title>{{ appConstants.UploadGlossaryTitle }}</h1>
<form [formGroup]="glossaryUploadForm" (ngSubmit)="onSubmit()" class="flex-column" autocomplete="off">
  <div mat-dialog-content>
    <mat-form-field class="mat-glossary-control-width" floatLabel="always">
      <mat-label>{{ appConstants.GlossaryNameLabel }}</mat-label>
      <input matInput formControlName="glossaryNameControl" required maxlength="150" cdkFocusInitial />
      <mat-error *ngIf="glossaryNameControl.invalid">{{ glossaryNameControl | errorMessage : glossaryNameError }}</mat-error>
    </mat-form-field>
    <mat-form-field class="mat-glossary-control-width" floatLabel="always">
      <mat-label> {{ appConstants.JobTypeNameLabel }} </mat-label>
      <mat-select
        #jobType
        formControlName="jobTypeControl"
        panelClass="glossary-dropdown-panel"
        required
        disableOptionCentering
        multiple
        (keyup.Space)="onKeyUp(multiSelect, jobType)"
        (keyup.enter)="onKeyUp(multiSelect, jobType)">
        <mat-option #multiSelect [value]="100" (click)="toggleAllSelection(multiSelect)"> Select all </mat-option>
        <ng-container *ngFor="let featureType of appConstants.FeatureType">
          <mat-option
            color="primary"
            [value]="featureType.Id"
            (click)="togglePerOne(multiSelect)"
            *ngIf="disabledGlossaryJobtypes(featureType)">
            {{ featureType.TypeName }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="jobTypeControl.invalid">{{ jobTypeControl | errorMessage : jobTypeError }}</mat-error>
    </mat-form-field>
    <mat-form-field class="mat-glossary-control-width no-label upload-dropzone" floatLabel="always">
      <ngx-dropzone
        class="ngxDropzone"
        [ngClass]="selectedFileControl.touched && selectedFileControl.invalid ? 'dropzone-error' : ''"
        [accept]="appConstants.UploadGlossaryAllowedFileTypes"
        [multiple]="false"
        (change)="onSelect($event)">
        <ngx-dropzone-label
          class="ngxDropzoneLabel"
          [innerHtml]="appConstants.ForGlossaryFeatureUploadGlossaryFileLabel"></ngx-dropzone-label>
        <ngx-dropzone-preview class="ngxDropzonepreview" *ngFor="let f of selectedFile" [removable]="true" (removed)="onRemove()">
          <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
        </ngx-dropzone-preview>
        <div class="fileTypeError" *ngIf="fileTypeError !== ''">{{ fileTypeError }}</div>
      </ngx-dropzone>
      <textarea formControlName="selectedFileControl" matInput hidden></textarea>
      <mat-error *ngIf="selectedFileControl.invalid"> {{ selectedFileControl | errorMessage : selectFileError }}</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button
      [attr.data-dd-action-name]="dataDogAction.CreateGlossary"
      mat-raised-button
      type="submit"
      [disabled]="glossaryUploadForm.invalid || isSubmitButtonDisabled || selectedFileControl.invalid">
      {{ appConstants.OkButtonLabel }}
    </button>
    <button [attr.data-dd-action-name]="dataDogAction.CreateGlossaryCancel" mat-raised-button [mat-dialog-close]="false" [disabled]="isSubmitButtonDisabled">
      {{ appConstants.CancelButtonLabel }}
    </button>
  </div>
</form>
