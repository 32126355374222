<div class="footer-container">
  <footer>
    <div class="leftColumn">
      <mat-icon>copyright</mat-icon>
      <p>{{ appConstants.CopyRightsLabel }}</p>
    </div>
    <div class="rightColumn">
      <a [attr.data-dd-action-name]="appConstants.AcsContactUs" class="about-acs" [href]="" (click)="showContactUs()">{{
        appConstants.AcsContactUs
      }}</a>
      <a [attr.data-dd-action-name]="appConstants.AcsTermsAndConditions" class="about-acs" [href]="" (click)="showTermsAndConditions()">{{
        appConstants.AcsTermsAndConditions
      }}</a>
      <a [attr.data-dd-action-name]="appConstants.AcsDataPrivacyNotice" class="about-acs" [href]="" (click)="showDataPrivacyNotice()">{{
        appConstants.AcsDataPrivacyNotice
      }}</a>
      <a [attr.data-dd-action-name]="appConstants.AboutAcsTitle" class="about-acs" (click)="openAboutAcs()">{{
        appConstants.AboutAcsTitle | uppercase
      }}</a>
      <a
        [attr.data-dd-action-name]="appConstants.ToppanNexusLinkLabel"
        class="about-acs"
        [href]="appConstants.ToppanNexusWebSiteLink"
        target="_blank"
        >{{ appConstants.ToppanNexusLinkLabel }}</a
      >
      <p class="version">Version {{ applicationVersion }}</p>
    </div>
  </footer>
</div>
<div kendoDialogContainer #dialogContainer></div>
