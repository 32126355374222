import { Injectable } from "@angular/core"
import { Subject } from "rxjs";

import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";

import { AppConstants } from "../app.constants";

import { IAppSettings } from "../models/app-settings.model";

import { SignalRConnectionStatus } from "../core/enums/signalr-connection-status.enum";

import { NotificationService } from "./notification.service";
import { LogService } from "./log.service";

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  private apiPath: string = ((window as any)[AppConstants.AppSettings] as IAppSettings).common.apiPath;

  public hubConnection!: signalR.HubConnection;
  private stopRequested: boolean = false;

  public connectionState = SignalRConnectionStatus.Disconnected;
  public connectionStateSubject = new Subject<SignalRConnectionStatus>();

  constructor(private notificationService: NotificationService) {
    this.initiateConnection();
  }

  public initiateConnection = () => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.apiPath}hub`)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          // If we've been reconnecting for less than 300 seconds so far,
          // wait 10 seconds before the next reconnect attempt.
          // If we've been reconnecting for more than 300 seconds so far, stop reconnecting.
          if (retryContext.elapsedMilliseconds < 30000) {
            return 1000;
          } else if (retryContext.elapsedMilliseconds < 60000) {
            return 2000;
          }
          return retryContext.elapsedMilliseconds < 300000 ? 5000 : null;
        }
      })
      .build();
    this.hubConnection.serverTimeoutInMilliseconds = 600000;// 10 minutes
    this.hubConnection.onclose(() => {
      LogService.Debug('on close');
      this.setConnectionState(SignalRConnectionStatus.Disconnected);
      if (!this.stopRequested) {
        this.notificationService.error('', AppConstants.ErrorConnectingServer);
      }
      this.stopRequested = false;
    });
    this.hubConnection.onreconnected(() => {
      LogService.Debug('on reconnected');
      this.setConnectionState(SignalRConnectionStatus.Connected);
    });
  }

  public wait = (ms: number) => {
    LogService.Debug('waiting ...');
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  public async startConnection(): Promise<void> {
    LogService.Debug('startConnection...');
    if (this.hubConnection) {
      if (this.hubConnection.state !== HubConnectionState.Disconnected) {
        LogService.Debug('Still not in Disconnected state. Waiting for a second and retry ...');
        await this.wait(1000).then(() => this.startConnection());
        return;
      }

      this.hubConnection
        .start()
        .then(() => {
          this.wait(2000).then(() => this.setConnectionState(SignalRConnectionStatus.Connected));
        })
        .catch(err => {
          LogService.Debug("Error while starting connection: " + err);
          this.setConnectionState(SignalRConnectionStatus.Error);
          this.connectionStateSubject.error(err);
          this.wait(5000).then(() => this.startConnection());
        });
    } else {
      this.initiateConnection();
      this.startConnection();
    }
  }

  public async stopConnection(): Promise<void> {
    LogService.Debug('stopConnection...');
    if (this.hubConnection) {
      this.stopRequested = true;
      this.setConnectionState(SignalRConnectionStatus.Disconnecting);
      await this.hubConnection.stop();
    }
  }

  private setConnectionState(connectionState: SignalRConnectionStatus): void {
    this.connectionState = connectionState;
    this.connectionStateSubject.next(connectionState);
  }

  public joinGroup(groupId: string): void {
    LogService.Debug(`joinGroup(${groupId})`);
    if (this.hubConnection) {
      this.hubConnection.invoke('Subscribe', groupId);
    }
  }

  public leaveGroup(groupId: string): void {
    LogService.Debug(`leaveGroup(${groupId})`);
    if (this.hubConnection) {
      this.hubConnection.invoke('Unsubscribe', groupId);
    }
  }

}
