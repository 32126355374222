import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent {
  constructor(private router: Router, private authService: MsalService, private commonService: CommonService) { }

  public backtologin() {
    if (this.authService.instance.getActiveAccount() !== null) {
      this.commonService.logout();
    } else {
      this.router.navigate([(window.location.href === '/')]);
    }
  }

}
