import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';

import { CanActivateGuard } from './core/guard/can-activate.guard';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataPrivacyNoticeComponent } from './components/data-privacy-notice/data-privacy-notice.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FileCompareComponent } from './components/file-compare/file-compare.component';
import { GlossaryManagementComponent } from './components/glossary-management/glossary-management.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  { path: 'jobs', component: DashboardComponent, canActivate: [CanActivateGuard] },
  { path: 'jobs:id',  component: DashboardComponent, canActivate: [CanActivateGuard] },
  { path: 'file-compare', component: FileCompareComponent, canActivate: [CanActivateGuard, MsalGuard] },
  { path: 'glossaries', component: GlossaryManagementComponent, canActivate: [CanActivateGuard, MsalGuard] },
  { path: 'forbidden', component: ErrorPageComponent },
  { path: 'data-privacy-notice', component: DataPrivacyNoticeComponent, canActivate: [CanActivateGuard] },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent, canActivate: [CanActivateGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false, // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
