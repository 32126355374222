import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css'],
})
@Injectable()
export class ProgressComponent implements OnInit, OnDestroy {
  private showSpinnerSubscription?: Subscription;
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.showSpinnerSubscription = this.commonService.showSpinner.subscribe({
      next: (value:boolean) => {
        this.visible = value;
        this.showOverlay=value;
      },
    });
  }
  ngOnDestroy(): void {
    if (this.showSpinnerSubscription) {
      this.showSpinnerSubscription.unsubscribe();
    }
  }
  @Input() visible: boolean = false;
  @Input() showOverlay: boolean = false;
}
