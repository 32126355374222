<section class="vh-100 bck-image">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="login-card">
        <div class="card shadow">
          <div class="card-body py-5 px-5 text-center">
            <div class="logo d-flex justify-content-center">
              <img src="./assets/logo.png" alt="Application Logo" class="application-logo mb-3" />
            </div>
            <img src="./assets/toppan-nexus-logo_265x75.png" alt="Company Logo" class="company-logo" />
            <div class="row">
              <p><b>Email OTP Verification</b></p>
              <p>OTP has been sent to registered Email</p>
            </div>
            <form [formGroup]="emailOtpVerificationForm" (ngSubmit)="verifyEmailOtp()">
              <div class="form-outline mb-4">
                <input
                  type="text"
                  id="email-adddress"
                  class="form-control user-name-field"
                  formControlName="accessCodeControl"
                  autofocus
                  [ngClass]="accessCodeControl.touched && accessCodeControl.invalid ? 'user-name-field-err' : ''" />
                <label
                  for="userName"
                  class="form-label"
                  [ngClass]="accessCodeControl.touched && accessCodeControl.invalid ? 'user-name-label-err' : ''"
                  for="email-adddress">
                  Enter the OTP
                </label>
                <mat-error *ngIf="accessCodeControl.touched && accessCodeControl.invalid">
                  {{ accessCodeControl | errorMessage : accessCodeCtrlErrors }}
                </mat-error>
              </div>
              <div class="d-grid gap-2 mb-3 continue-button">
                <button class="btn btn-primary" type="submit" [disabled]="accessCodeControl.invalid">Verify</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
