import { Injectable } from '@angular/core';

import { datadogLogs } from '@datadog/browser-logs'

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private static _enableConsoleLogging: boolean = false;
  public static setEnableConsoleLogging(value: boolean): void {
    this._enableConsoleLogging = value;
  }

  private static _enableDataDogLogging: boolean = false;
  public static setEnableDataDogLogging(value: boolean): void {
    this._enableDataDogLogging = value;
  }

  public static Debug(message: any): void {
    const logMessage = JSON.stringify(message);
    if (LogService._enableConsoleLogging) {
      console.log(`[DEBUG] ${logMessage}`);
    }
    if (LogService._enableDataDogLogging) {
      datadogLogs.logger.debug(logMessage);
    }
  }

  public static Error(error: Error): void {
    if (LogService._enableDataDogLogging) {
      datadogLogs.logger.error('', error);
    }
  }
}
