import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { AppConstants } from 'src/app/app.constants';

import { IAppSettings } from 'src/app/models/app-settings.model';

import { AppDataService } from 'src/app/services/app-data.service';
import { DialogService } from 'src/app/services/dialog.service';

import { AboutAppComponent } from '../../about-app/about-app.component';
import { ContactUsComponent } from '../../contact-us/contact-us.component';

import { DialogRef } from '@progress/kendo-angular-dialog';
import { DialogService as kendoDialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-dashboard-footer',
  templateUrl: './dashboard-footer.component.html',
  styleUrls: ['./dashboard-footer.component.css'],
})
export class DashboardFooterComponent implements OnInit {
  @ViewChild('dialogContainer', { read: ViewContainerRef }) private dialogContainer!: ViewContainerRef;

  private appSettings: IAppSettings = (window as any)[AppConstants.AppSettings];
  public appConstants: typeof AppConstants = AppConstants;
  public applicationVersion = '';
  public dialog!: DialogRef;
  public contactUsPhone = '';

  constructor(
    private dialogService: DialogService,
    public appDataService: AppDataService,
    private route: Router,
    private kendoDialogService: kendoDialogService
  ) {}

  ngOnInit(): void {
    this.applicationVersion = this.appSettings.common.applicationVersion;
  }

  public openAboutAcs(): void {
    const aboutAcsDescriptionDialogOptions = {
      title: this.appConstants.AboutAcsTitle,
      width: '406px',
    };
    this.dialogService.open(AboutAppComponent, aboutAcsDescriptionDialogOptions);
  }

  public showDataPrivacyNotice(): void {
    this.route.navigate(['/data-privacy-notice']);
  }

  public showTermsAndConditions() {
    this.route.navigate(['/terms-and-conditions']);
  }

  public showContactUs(): void {
    this.dialog = this.kendoDialogService.open({
      title: this.appConstants.AcsContactUs,
      content: ContactUsComponent,
      width: '500px',
      appendTo: this.dialogContainer,
    });
  }
}
