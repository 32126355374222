import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DropDownFilterSettings, DropDownListComponent } from '@progress/kendo-angular-dropdowns';

import { FormFieldError } from 'src/app/models/form-field-error.model';

@Component({
  selector: 'app-single-select-field',
  templateUrl: './kendo-single-select-field.component.html',
})
export class KendoSingleSelectFieldComponent implements AfterViewInit {
  @ViewChild('dropDownList') dropDownList!: DropDownListComponent;

  @Input() id!: string;
  @Input() label: string = '';
  @Input() placeholder!: any;
  @Input() control!: FormControl;
  @Input() textField!: string;
  @Input() valueField!: string;
  @Input() filterable: boolean = true;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() data: any = [];
  @Input() noDataMessage: string = 'No data found';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() rounded: 'small' | 'medium' | 'large' | 'full' = 'medium';
  @Input() fillMode: 'solid' | 'flat' | 'outline' = 'solid';
  @Input() hint: string = '';
  @Input() errors: any[] = [];
  @Input() valuePrimitive: boolean = true;

  @Output() valueChange = new EventEmitter<Event>();

  private _selectedItem: any = null;
  public get SelectedItem(): any {
    return this._selectedItem;
  }
  public set SelectedItem(value) {
    this._selectedItem = value;
  }

  constructor() { }

  public ngAfterViewInit(): void {
    this.dropDownList.filterChange.subscribe({
      next: () => {
        if (this.dropDownList.data.length === 0) {
          this.dropDownList.defaultItem = '';
        } else {
          this.dropDownList.defaultItem = this.placeholder;
        }
      }
    });
  }

  public onValueChange(event: any) {
    const item = this.data.filter((x: { [x: string]: number }) => x[this.valueField] == event)[0];
    if (item || item !== undefined) {
      this._selectedItem = item;
      this.control.setValue(item[this.valueField]);
      return;
    }
    this._selectedItem = null;
    // invalid item selected
    if (this.required) {
      this.control.setErrors({ required: true });
    }
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
}
