<p><b>If you need further assistance with ACS, get in touch with our team.</b></p>

<p><b>ACS Application Support Team</b></p>
<div class="contact-us">
  <kendo-svg-icon class="contact-us-icon contact-email-phone" [icon]="constants.emailIcon"></kendo-svg-icon>
  <p class="contact-email-phone"><a href="mailto:teraappsupport@toppannexus.com">teraappsupport&#64;toppannexus.com</a></p>
</div>
<div class="contact-us">
  <kendo-svg-icon class="contact-us-icon contact-email-phone" [icon]="constants.phoneIcon"></kendo-svg-icon>
  <p class="contact-email-phone">{{ contactUsPhone }}</p>
</div>

<br />

<p><b>ACS Sales Support Team</b></p>
<div class="contact-us">
  <kendo-svg-icon class="contact-us-icon contact-email-phone" [icon]="constants.emailIcon"></kendo-svg-icon>
  <p class="contact-email-phone"><a href="mailto:terasalessupport@toppannexus.com">terasalessupport&#64;toppannexus.com</a></p>
</div>
<div class="contact-us">
  <kendo-svg-icon class="contact-us-icon contact-email-phone" [icon]="constants.phoneIcon"></kendo-svg-icon>
  <p class="contact-email-phone">{{ contactUsPhone }}</p>
</div>
