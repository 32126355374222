import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MsalService } from '@azure/msal-angular';

import { AppConstants } from 'src/app/app.constants';

import { FunctionalRole } from 'src/app/models/functional-role.model';

import { AppDataService } from 'src/app/services/app-data.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: false }) trigger!: MatMenuTrigger;

  public readonly appConstants: typeof AppConstants = AppConstants;

  public selectedRole!: number;
  public readonly roleCtrl: FormControl = new FormControl();
  public readonly roleFilter: FormControl = new FormControl();
  public filteredRoles: ReplaySubject<FunctionalRole[]> = new ReplaySubject<FunctionalRole[]>();
  protected onDestroy = new Subject<void>();
  public userLocation?: string;
  private functionalRoles!: FunctionalRole[];
  public locations!: any[];

  constructor(
    public appDataService: AppDataService,
    private dashboardService: DashboardService,
    public commonService: CommonService,
    private userService: UserService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.selectedRole = this.appDataService.user.primaryFunctionalRoleIdentity!;
    if (this.appDataService.user.functionalRoles != null) { // this will check both undefined and null
      const roleDetails = this.appDataService.user.functionalRoles.filter(x => x.functionalRoleIdentity === this.selectedRole)[0];
      if (roleDetails) {
        this.userLocation = roleDetails.locationName;
        this.roleCtrl.setValue(roleDetails.functionalRoleIdentity);
        this.functionalRoles = this.appDataService.user.functionalRoles;
        this.dashboardService.roleChanged(this.selectedRole);
      }
    }

    if (this.functionalRoles != null) { // this will check both undefined and null
      this.filteredRoles.next(this.functionalRoles.slice());
    }

    this.roleFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterRoles();
      });
  }

  protected filterRoles() {
    if (!this.functionalRoles) {
      return;
    }
    let search = this.roleFilter.value;
    if (!search) {
      this.filteredRoles.next(this.functionalRoles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredRoles.next(
      this.functionalRoles.filter(r => r.name.toLowerCase().indexOf(search) > -1)
    );
  }

  public changeUserRole(value: number): void {
    this.selectedRole = value;
    const roleDetail = this.functionalRoles.filter(x => x.functionalRoleIdentity === value)[0];
    if (roleDetail) {
      this.userLocation = roleDetail.locationName;
      this.trigger.closeMenu();

      this.userService.updateUserPrimaryFunctionalRole(this.selectedRole).subscribe({
        next: () => {
          this.dashboardService.roleChanged(this.selectedRole);
        },
        error: (error: Error | null) => {
          if (error) {
            this.notificationService.error('', error.message);
          }
        },
      });
    }
  }
}
