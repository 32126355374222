import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppConstants } from "../app.constants";

import { AcsTeam } from "../models/acs-team.model";
import { FunctionalRole } from 'src/app/models/functional-role.model';
import { IAppSettings } from "../models/app-settings.model";
import { ReportData } from '../models/report-data.model';
import { ReportRequest } from "../models/report-request.model";
import { UserDto } from 'src/app/models/user-dto.model';
import { AcsCompanyDetail } from "../models/acs-company-details.model";

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  private apiPath: string = ((window as any)[AppConstants.AppSettings] as IAppSettings).common.apiPath;

  public users: UserDto[] = [];
  public functionalRoles: FunctionalRole[] = [];
  public acsTeams: AcsTeam[] = [];
  public companies: AcsCompanyDetail[] = [];

  constructor(private http: HttpClient) { }

  public GenerateReport(reportRequest: ReportRequest): Observable<string> {
    return this.http.post(`${this.apiPath}api/Report`, reportRequest, { headers: new HttpHeaders({ timeout: '900000' }), responseType: 'text' });
  }

  public loadReportMasterData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.GetReportMasterData().subscribe(tccData => {
        this.users = [...tccData.users];
        this.functionalRoles = [...tccData.functionalRoles];
        this.acsTeams = [...tccData.acsTeams];
        this.companies = [...tccData.acsCompanies];
        resolve()
      });
    })
  }

  public GetReportMasterData(): Observable<ReportData> {
    return this.http.get<ReportData>(`${this.apiPath}api/Report/GetReportData`);
  }

}
