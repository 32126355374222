<div class="parent-container">
  <app-dashboard-header></app-dashboard-header>
  <div class="content">
    <br /><br />
    <p class="title">
      Terms and Conditions
      <app-icon-button
        [attr.data-dd-action-name]="'Close On Terms and Conditions'"
        class="close-btn"
        icon="clear"
        tooltip="Close"
        (clicked)="close()"></app-icon-button>
    </p>
    <h4>1. Definitions</h4>
    <p>
      1.1. “Customer” or “User” refers to any person or entity using, subscribing to or placing orders with ACS for products or services (as
      defined below).
    </p>
    <p>1.2. “Product” or “Services” refers to any products or services provided by ACS to the Customers.</p>
    <p>1.3. “ACS” refers to the ACS translation management system provided by TOPPAN Nexus Limited.</p>
    <p>In this Terms and Conditions, reference to the plural shall include the singular and vice versa.</p>
    <h4>2. Conditions of Use / Obligations of Customer / Your use of service</h4>
    <p>
      2.1. Customers are solely responsible for all deliverables that you upload, post, publish or display or email or otherwise submit for
      any services. ACS reserves the right to investigate and take appropriate legal action against anyone who, in ACS's sole discretion,
      violates this provision, including without limitation, removing offending content, suspending or terminating the account of such
      violators and reporting you to law enforcement authorities.
    </p>
    <p>2.2. Customers must not:</p>
    <ol type="i">
      <li>modify copies of any materials from ACS;</li>
      <li>use any illustrations, photographs, video or audio sequences or any graphics or text from ACS for any reason;</li>
      <li>delete or alter any copyright, trademark or other propriety rights notices from copies of materials from ACS; or</li>
      <li>
        access or use for any purpose any part of ACS or any services or materials available through ACS other than for proper execution of
        the service.
      </li>
    </ol>
    <p>2.3. User represents and warrants that they shall not use ACS:</p>
    <ol type="i">
      <li>
        in connection with or for the purpose of operating critical infrastructure such as electrical power stations, military or defense
        equipment, medical appliances or other equipment whose failure or impairment would result in unforeseeable economical or physical
        damages;
      </li>
      <li>
        for any illegal activities, including development of any applications infringing any third-party rights or any other applicable laws
        or regulations;
      </li>
      <li>to perform benchmark or other capacity testing of ACS's technical infrastructure;</li>
      <li>for spamming or any other unsolicited advertising;</li>
      <li>to develop, market or train an algorithmic comparison system;</li>
      <li>to solicit personal information from anyone;</li>
      <li>
        to create a similar product or service whose primary purpose is to provide services of checking figures, including but not limited
        to bilingual/multilingual dictionaries;
      </li>
      <li>
        to create multiple user accounts on ACS - in such circumstances all of the User accounts may be suspended and/or blocked from ACS
        and User work could be invalidated as a result;
      </li>
      <li>
        to transmit any data to ACS which may not be transmitted to or processed by ACS due to data protection laws, contractual or
        statutory confidentiality obligations, export restrictions or other statutory provisions or third-party rights.
      </li>
    </ol>
    <p>
      2.4. Customer is obligated to observe all legal requirements for the collection, processing and use of data which is transmitted to
      and processed by ACS for Customer in connection with the provision of its services under this Terms and Conditions. In particular,
      Customer shall immediately agree with ACS on a data processing agreement (“Data Privacy Notice”) (which shall be provided by ACS) if
      Customer intends to transmit personal data to ACS using the Products. Customer guarantees not to collect, process or use any personal
      data in connection with the Products without the express consent of the data subject or sufficient other legal authorization. ACS will
      reasonably cooperate with Customer in order to assist Customer in implementing such required legal authorizations.
    </p>
    <p>
      2.5. Customer is obligated to keep the access credentials provided by ACS secure and undertakes not to disclose them to any third
      parties unless required for the contractually intended and permitted use of the Products. Customer is not entitled to repackage or
      resell access credentials or its access to the Products to any third parties unless expressly agreed upon otherwise.
    </p>
    <p>
      2.6. In the event that Customer uses third-party applications to access the Products, Customer shall comply with the acceptable use
      policies set out by the vendor of the third-party application if applicable.
    </p>
    <p>
      2.7. Customer is only permitted to use the Products in compliance with applicable laws. This also includes export control laws and
      regulations.
    </p>
    <h4>3. General</h4>
    <p>
      3.1. These Terms and Conditions constitute the entire agreement between you and ACS and govern your use of ACS and submission of
      services, superseding any prior agreements between Customer and ACS with respect thereto.
    </p>
    <p>
      3.2. <span>Governing Law.</span> The Terms and Conditions are governed by and construed in accordance with the laws of the Hong Kong
      Special Administrative Region of the People's Republic of China (“Hong Kong”) and you agree to submit to the exclusive jurisdiction of
      the Hong Kong courts
    </p>
    <p>
      3.3. <span>Analytics.</span> Upon anonymizing content by removing all references to numeric values, proper names, addresses, and
      locations and incorporating such anonymized content with or into similar information derived or obtained from other Customers of ACS,
      Customer hereby grants to ACS a non-exclusive, fully paid, world-wide and irrevocable license to use aggregated content exclusively
      for enhancing features and functionality of the services.
    </p>
    <p>
      3.4. <span>Restricted Parties.</span> ACS reserves the right to prohibit services to any company or individual from a sanctioned or
      embargoed country or restrict access or use of services to any restricted party based on any published government list.
    </p>
    <p>
      3.5. <span>Assignment.</span> This Terms and Conditions is binding upon and for the benefit of the parties and their respective
      successors and assigns. It is agreed and understood that without obtaining prior written consent (i) ACS may assign its rights,
      interests and obligations in this Terms and Conditions thereto to any parent, subsidiary or affiliate of ACS, or to a successor of
      ACS's assets or stock, and (ii) Customer may assign its rights, interests and obligations in this Terms and Conditions to any parent,
      subsidiary or affiliate of Customer.
    </p>
    <p>
      3.6. <span>Notices.</span> Wherever provision is made in this Terms and Conditions for the giving, service or delivery of any notice,
      such notice shall be in writing and shall be given using a method providing for proof of delivery.
    </p>
    <p>
      3.7. <span>No Accounting or Legal Services.</span> Customer acknowledges and agrees that the services are principally information
      management services and that none of the services constitute or are intended to constitute the rendering of professional accounting or
      legal advice services.
    </p>
    <p>
      3.8. <span>Entire Agreement.</span> This Terms and Conditions, together with any applicable statement of work, constitutes the entire
      agreement between the Parties and supersedes all previous agreements, promises, proposals, representations, understanding and
      negotiations, whether written or oral, between the Parties regarding the subject matter herein. ACS rejects the inclusion of any
      different or additional terms proposed by Customer, unless expressly agreed to in writing.
    </p>
    <h4>4. Your password and account security</h4>
    <p>
      4.1. You agree not to access or use ACS in any manner that could damage, disable, overburden, or impair any ACS accounts, computer
      systems or networks. You agree not to attempt to gain unauthorized access to any parts of the ACS or any ACS accounts, computer
      systems or networks. You agree not to interfere or attempt to interfere with the proper working of ACS or any ACS accounts, computer
      systems or networks. You agree not to use any robot, spider, scraper or other automated means to access ACS or any ACS accounts,
      computer systems or networks without ACS's express written permission.
    </p>
    <p>
      4.2. If any of the Products require you to open an account, you must complete the registration process by providing us with current,
      complete and accurate information as required by the applicable registration form. You may also be required to choose a password and a
      username. Access to and use of password protected or secure areas of the ACS are restricted to authorized users only. You agree not to
      share your password(s), account information, or access to ACS with any other person. You are responsible for maintaining the
      confidentiality of password(s) and account information, and you are responsible for all activities that occur through the use of your
      password(s) or account(s) or as a result of your access to ACS. You agree to notify ACS immediately of any use of your password(s) or
      account(s) that you did not authorize or that is not authorized by these Terms and Conditions.
    </p>
    <h4>5. Intellectual Property Rights</h4>
    <p>
      5.1. You acknowledge and agree that the Services or any portion thereof may contain content or features that are protected by
      copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorized by ACS, you agree not
      to create, copy, distribute, frame, lease, loan, modify, rent, scrape or sell derivative works based on ACS, in whole or in part. Any
      use of ACS other than as specifically authorized herein is strictly prohibited. The technology and underlying software distributed or
      provided in connection with any Services are the property of ACS, provided by TOPPAN Nexus Limited. You agree not to copy, modify,
      create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign,
      sublicense or otherwise transfer any right in the ACS. Any rights not expressly granted herein are reserved by ACS.
    </p>
    <p>
      5.2. The Terms and Conditions strictly forbid you to use, represent, reproduce, whether wholly or in part, any of ACS's trade names,
      trademarks, logos, domain names, and other distinctive brand features, and consequently, any such use, representation or reproduction
      by any means or in any form whatsoever of all or part, whether or not qualitatively or quantitatively substantial, without the prior
      written authorization by ACS shall constitute inter alia an infringement of such intellectual property rights. In particular and
      without limitation, ACS prohibits the use of the ACS logo for commercial purposes without ACS prior and written consent.
    </p>
    <p>
      5.3. Subject to the obtaining of ACS prior written authorization to use any of its trade names, trademarks, service marks, logos,
      domain names, and other distinctive brand features, you undertake, when using the same, to comply strictly with the terms of such
      authorization and any applicable provisions of this Terms and Conditions.
    </p>
    <p>
      5.4. You undertake not to remove and to keep intact any proprietary rights notices (including copyright and trademark notices) which
      may be affixed to or appear within the Services.
    </p>
    <p>
      5.5. ACS shall obtain no right, title or interest from you (or your licensors) under the Terms. Unless you have agreed otherwise in
      writing with ACS, you agree that you are responsible for protecting and enforcing those rights and that ACS has no obligation to do so
      on your behalf.
    </p>
    <p>
      5.6. It is ACS's policy to respond to notices of alleged infringement of copyright, trademark or any other intellectual property
      rights arising under all applicable intellectual property laws and to terminate the accounts of repeated infringers.
    </p>
    <h4>6. Termination of Use</h4>
    <p>
      6.1. The legal agreement is concluded for an indefinite period of time. Each Party may terminate the agreement at any time by giving
      notice, which termination shall take effect at the end of the current billing period.
    </p>
    <p>
      6.2. If the parties agree to a fixed term, the term shall be automatically extended on expiry for a further fixed term of the same
      duration, unless the agreement is duly terminated prior to expiry by a party giving notice.
    </p>
    <p>
      6.3. ACS shall be entitled to terminate the legal agreement with you at any time and with immediate effect, upon occurrence of any of
      the following events, as it may deem appropriate:
    </p>
    <ol type="i">
      <li>
        any material breach by you of any provision of the Terms and Conditions and/or generally any actions incompatible with the rules of
        the functioning and use of the services, or showing your intention not to, or inability to, comply with the provisions of the Terms
        and Conditions; or
      </li>
      <li>
        if it becomes contrary to any law or regulation for ACS to maintain its legal agreement with you and/or to maintain the provision of
        the services or if the termination of the legal agreement between you and ACS is required by law; or
      </li>
      <li>
        termination of ACS's relationship with any partner with whom ACS offered the Services to you or termination of the provision of
        certain services by such partner.
      </li>
    </ol>
    <h4>7. Limitation of liability</h4>
    <p>
      NEITHER ACS NOR TOPPAN NEXUS NOR CUSTOMER SHALL BE LIABLE TO THE OTHER PARTY OR ANY OTHER THIRD PARTY UNDER ANY THEORY OF RECOVERY,
      WHETHER BASED IN CONTRACT, IN TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), UNDER WARRANTY, OR OTHERWISE, FOR ANY PUNITIVE,
      INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL LOSS INCLUDING: DAMAGE TO OR LOSS OF PROPERTY OR EQUIPMENT; LOSS OF REVENUE; LOSS OF
      CUSTOMER'S DATA; OR LOSS OF USE OF CUSTOMER'S MATERIAL, EQUIPMENT OR SYSTEMS. CUSTOMER EXPRESSLY AGREES THAT UNLESS OTHERWISE STATED
      HEREIN, THE REMEDIES PROVIDED IN THIS AGREEMENT ARE EXCLUSIVE AND THAT UNDER NO CIRCUMSTANCES SHALL THE TOTAL AGGREGATE LIABILITY OF
      ACS UNDER ANY THEORY OF RECOVERY, WHETHER BASED IN CONTRACT, IN TORT, UNDER WARRANTY, OR OTHERWISE, EXCEED THE TOTAL PRICE PAID OR
      PAYABLE TO ACS UNDER THE APPLICABLE CONTRACT OR AGREEMENT FOR THE 12-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE
      LIABILITY. THE PROVISIONS OF THIS PARAGRAPH SHALL NOT APPLY TO A PARTY'S BREACH OF THE OWNERSHIP PROVISIONS CONTAINED IN THIS
      AGREEMENT NOR TO A PARTY'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT. CUSTOMER SHALL, AT ITS OWN EXPENSE, INDEMNIFY, DEFEND, AND HOLD
      HARMLESS ACS FROM AND AGAINST ANY AND ALL ACTIONS, PROCEEDINGS, DAMAGES, LIABILITIES, CLAIMS, LOSSES, AND EXPENSES (INCLUDING
      REASONABLE ATTORNEY'S FEES) ARISING FROM CLAIMS OF WHICH DATA/PROPERTY PROVIDED BY CUSTOMER HEREUNDER INFRINGE THE PATENT, COPYRIGHT,
      TRADEMARK RIGHTS, OR CONSTITUTE THE MISAPPROPRIATION OF TRADE SECRETS, OF ANY THIRD PARTY. THE OBLIGATIONS UNDER THIS CLAUSE WILL
      SURVIVE THE CANCELLATION, EXPIRATION, TERMINATION, OR COMPLETION OF THIS AGREEMENT AND/OR THE SERVICES PROVIDED.
    </p>
    <h4>8. Remuneration</h4>
    <p>
      8.1. If remuneration has been agreed upon, Customer shall pay the base remuneration as well as usage-based remuneration as stipulated
      upon conclusion of the relevant agreement.
    </p>
    <p>
      8.2. All amounts shall be paid in full in the currency indicated on the invoice. The Customer shall bear all bank and transfer charges
      as well as any currency conversion costs (if any).
    </p>
    <p>
      8.3. Unless otherwise legally required, all invoices shall be made available to the Customer exclusively in a digital format (e.g. as
      PDF files sent by e-mail to the invoice e-mail address indicated by the Customer or available for download by the Customer on the
      website in the Customer account).
    </p>
    <p>8.4. For Products with a fixed base remuneration, the remuneration is due at the beginning of each billing period.</p>
    <p>
      8.5. ACS reserves the right to block the access to the Products if the Customer has not paid any due fee after having been sent a
      written notification by ACS (a notification sent by email will suffice). If the Customer pays all outstanding amounts, ACS will
      without any delay, but at the latest within three (3) working days after the receipt of the entire payment by ACS, restore the access
      of the Customer. Depending on the selected payment method, the following will apply additionally:
    </p>
    <p>
      8.5.1. If payments are not made in accordance with the relevant invoice, ACS will inform the Customer of the impending blocking after
      the due date and will request payment from the Customer. In case the payment is not made within a term of three (3) subsequent working
      days, ACS will be entitled to block the access of the Customer. After the blocking, ACS will send another payment request to the
      Customer in order to restore access.
    </p>
    <p>
      8.5.2. In case ACS is not able to debit the due payment for payments made by credit card or direct debit, ACS will ask the Customer to
      update the agreed payment method or to provide a new payment method and will inform the Customer of the impending blocking. In case
      the debit continues to fail after the notification, ACS is entitled to block access to the Products. After the blocking, ACS will send
      another request to the Customer to provide a new payment method in order to restore access.
    </p>
    <h4>9. Compensation</h4>
    <p>
      9.1. You agree to indemnify and hold harmless ACS, its officers, directors, employees and agents from and against any and all claims,
      liabilities, damages, losses or expenses, including reasonable attorneys' fees and costs, due to or arising out of content that you
      share, your violation of these Terms and Conditions or any additional rules, guidelines or terms of use posted for a specific area of
      the site or content provided on or through the site, or your violation or infringement of any third party rights, including
      intellectual property rights.
    </p>
    <h4>10. Warranty</h4>
    <p>
      YOUR USE OF THE PLATFORM IS AT YOUR SOLE RISK. THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. ACS EXPRESSLY
      DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON- INFRINGEMENT. ACS MAKES NO WARRANTY THAT (I) THE PLATFORM OR ANY
      ASSIGNMENT WILL MEET YOUR REQUIREMENTS, (II) THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE QUALITY OF
      ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE PLATFORM WILL MEET YOUR EXPECTATIONS, (IV) THAT THE
      INFORMATION, CONTENT, OR MATERIALS INCLUDED ON THE PLATFORM WILL BE AS REPRESENTED BY ACS OR ITS CLIENTS OR AGENTS, (V) THAT AN
      ASSIGNMENT OR ANY OF THE PLATFORM IS LAWFUL, OR (VI) ANY IMPLIED WARRANTY ARISING FROM COURSE OF DEALING OR USAGE OF TRADE.
    </p>
    <h4>11. Personal Information</h4>
    <p>
      ACS will be processing your personal information the details of which appear in the Data Privacy Notice. Your consent will be obtained
      to authorize ACS to process your personal information.
    </p>
  </div>
  <app-dashboard-footer></app-dashboard-footer>
</div>
