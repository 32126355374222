import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { SVGIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-icon-button',
  templateUrl: './kendo-icon-button.component.html',
  styleUrls: ['./kendo-icon-button.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class KendoIconButtonComponent {

  @Input() icon: string = '';
  @Input() imageUrl: string = '';
  @Input() tooltip: string = '';
  @Input() stopPropagation: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() rounded: 'small' | 'medium' | 'large' | 'full' = 'full';
  @Input() fillMode: 'solid' | 'flat' | 'outline' | 'clear' | 'link' = 'clear';
  @Input() themeColor: 'base' | 'primary' | 'secondary' | 'tertiary' | 'info' | 'success' | 'warning' | 'error' | 'dark' | 'light' | 'inverse' = 'base';
  @Input() disabled: boolean = false;
  @Input() svgIcon?: SVGIcon;

  @Output() clicked = new EventEmitter<Event>();

  onClick(event: any) {
    this.clicked.emit(event);
    if (this.stopPropagation) {
      event.stopPropagation();
    }
  }

}
