import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { AppConstants } from '../app.constants';

import { AppData } from '../models/app-data.model';
import { IAppSettings } from '../models/app-settings.model';
import { JobFeature, FeaturePermissions } from '../models/feature-permissions.model';
import { UserInfo } from '../models/user-info.model';
import { UserRole } from '../models/user-role.model';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  private apiPath: string = ((<any>window)[AppConstants.AppSettings] as IAppSettings).common.apiPath;

  public userProfileLoaded: boolean = false;
  public user: UserInfo = new UserInfo();
  public featurePermissions: FeaturePermissions = new FeaturePermissions();
  public isAuthenticated: boolean = false;
  public userProfileLoadedSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  public async LoadAppData(): Promise<void> {
    const appData: AppData | undefined = await this.GetAppData();
    if (appData!.user && appData!.user.userRole) {
      this.featurePermissions = this.loadFeaturePermissions(appData!);
    }
    this.user = appData!.user;
    this.user.isAdmin = appData!.user?.userRole && appData!.user.userRole?.some((x: UserRole) => x.roleName === AppConstants.AcsAdmin);
  }

  private loadFeaturePermissions(appData: AppData): FeaturePermissions {
    const roleFeatures: any = appData.user?.userRole.map((item: any) => JSON.parse(item.roleFeatures.toString()));
    const objRoleFeatures: any = Object.assign(new FeaturePermissions(), ...roleFeatures);
    const featurePermissions: FeaturePermissions = Object.assign(new FeaturePermissions(), objRoleFeatures);
    featurePermissions.acs_job = Object.assign(new JobFeature(), objRoleFeatures.acs_job);
    return featurePermissions;
  }

  private async GetAppData(): Promise<AppData | undefined> {
    return await firstValueFrom(this.http.get<AppData>(`${this.apiPath}api/AppData`));
  }
}
