<form [formGroup]="lqaForm" class="form">
  <div kendoUploadDropZone="uploadDropZone">
    <div class="form-group row">
      <!--Error Category-->
      <div class="col-lg-12 col-md-12">
        <app-single-select-field
          #errorCategoryRef
          [id]="'errorCategory'"
          [data]="errorCategoryData"
          [filterable]="false"
          [label]="constants.ErrorCategoryLabel"
          [required]="true"
          [control]="errorCategoryControl"
          [placeholder]="errorCategorydefaultValue"
          [errors]="errorCategoryErrors"
          valueField="id"
          textField="name"
          (valueChange)="errorCategoryChange($event)">
        </app-single-select-field>
      </div>
    </div>
    <div class="form-group row">
      <!--Sub Error Category-->
      <div class="col-lg-12 col-md-12">
        <app-single-select-field
          #subErrorCategoryRef
          [id]="'subErrorCategory'"
          [data]="subErrorCategoryData"
          [filterable]="false"
          [label]="constants.SubErrorCategoryLabel"
          [required]="true"
          [control]="subErrorCategoryControl"
          [placeholder]="subErrorCategorydefaultValue"
          [errors]="subErrorCategoryErrors"
          valueField="id"
          textField="name"
          (valueChange)="subErrorCategoryChange($event)">
        </app-single-select-field>
      </div>
    </div>
    <div class="form-group row">
      <!--Others-->
      <div class="col-lg-12 col-md-12">
          <app-text-field [control]="othersControl" [label]="constants.OthersLabel" [required]="isOthersSelected" [errors]="othersErrors" [showInvalidError]="true"></app-text-field>
      </div>
    </div>
    <div class="form-group row">
      <!--Severity-->
      <div class="col-lg-12 col-md-12">
        <app-single-select-field
          #severityRef
          [id]="'severity'"
          [data]="severityData"
          [filterable]="false"
          [label]="constants.SeverityLabel"
          [control]="severityControl"
          [placeholder]="severitydefaultValue"
          valueField="id"
          textField="name">
        </app-single-select-field>
      </div>
    </div>

    <div class="btns-flex-container action-buttons mt-3">
      <app-button
        [label]="isUpdateLqa ? constants.UpdateLabel : constants.SubmitLabel"
        [size]="'large'"
        [themeColor]="'primary'"
        [disabled]="submitButtonDisabled()"
        (clicked)="onSubmit()"></app-button>
      <app-button
        [label]="constants.CancelLabel"
        [size]="'large'"
        [themeColor]="'base'"
        [disabled]="isSubmitClicked"
        (clicked)="onClose()"></app-button>
    </div>
  </div>
</form>
